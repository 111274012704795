import HTTPService from "@/services/http_service.js";

export default class AgendamentoService extends HTTPService {
  resource = "agendamento";

  static build() {
    return new AgendamentoService();
  }

  desmarcarAgendamento(responseFunctions, idAgendamento) {
    this.request(
      responseFunctions,
      "POST",
      `${this.resource}/${idAgendamento}/desmarcar`
    );
  }

  async agendarConsulta(data) {
    return await this.post(this.resource, { ...data });
  }

  async agendarConsultaPrimeiroSlotLivre({
    petId,
    unidadeId,
    medicoId,
    tipoProcedimentoId,
    procedimentoId,
    requisicaoId,
  }) {
    return this.post("agendamento/proximo-slot-livre", {
      petId,
      unidadeId,
      medicoId,
      tipoProcedimentoId,
      procedimentoId,
      requisicaoId,
    });
  }

  async agendarTeleConsulta(data) {
    return await this.post("requisicao/teleconsulta", { ...data });
  }

  async deletarAtendimento(data) {
    return await this.deleteJson(`${this.resource}/medico/excluir`, data);
  }

  async deletarAnamnese(data) {
    return await this.deleteJson(`anamnese/requisicao/excluir`, data);
  }

  async atenderRequisicao(data) {
    return await this.postJson(`${this.resource}/medico/requisicao`, data);
  }
}
