<template>
  <div>
    <div class="p-lg-3">
      <div class="row justify-content-between">
        <h3 class="mt-0 mb-2 font-weight-bold text-left col-8">Meus Pets</h3>
        <v-row justify="end">
          <v-dialog
            persistent
            fullscreen
            v-model="dialogCadastrar"
            max-width="500px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="#1daf80" dark class="mb-2" v-bind="attrs" v-on="on">
                Novo Pet
              </v-btn>
            </template>
            <modal-cadastro
              v-if="dialogCadastrar"
              @close="closeDialogCadastrar"
              @response="atualizarPetPosCadastro"
            />
          </v-dialog>
          <!-- <v-btn color="#3E682A" @click="redirectTo('agendar_consulta')"
            >Agendar consulta</v-btn
          > -->
        </v-row>
      </div>
    </div>
    <v-data-table
      :headers="headers"
      :items="petList"
      :items-per-page="5"
      class="elevation-1"
    >
      <template v-slot:item.actions="{ item }">
        <!-- <tr> -->
        <!-- <td>{{ row.item.id_pet }}</td>
          <td>{{ row.item.nome_pet }}</td>
          <td>{{ row.item.nome_raca }}</td>
          <td>{{ row.item.sexo }}</td>
          <td> -->
        <!-- <v-icon title="Agendar" small class="mr-2" @click="agendamento()">
          mdi-calendar
        </v-icon> -->
        <v-icon
          title="Arquivos"
          small
          class="mr-2"
          @click="uploadArquivos(item)"
        >
          mdi-folder-arrow-up-outline
        </v-icon>
        <v-icon title="Exames" small class="mr-2" @click="verArquivos(item)">
          <!-- mdi-file-upload-outline -->
          mdi-medical-bag
        </v-icon>

        <v-icon
          title="Visualizar"
          small
          class="mr-2"
          @click="editItem(item, true)"
        >
          mdi mdi-eye
        </v-icon>
        <v-icon
          title="Editar"
          small
          class="mr-2"
          @click="editItem(item, false)"
        >
          mdi-pencil
        </v-icon>
        <!-- </td> -->
        <!-- </tr> -->
        <v-dialog
          v-model="dialogArquivo"
          v-if="dialogArquivo"
          fullscreen
          hide-overlay
          max-width="500px"
        >
          <UploadExames :paciente="petArquivo" @close="dialogArquivo = false" />
        </v-dialog>
        <v-dialog
          v-model="dialogUploadArquivo"
          v-if="dialogUploadArquivo"
          fullscreen
          hide-overlay
          max-width="500px"
        >
          <upload-arquivos-cliente
            :paciente="petArquivo"
            @close="dialogUploadArquivo = false"
          />
        </v-dialog>
        <v-dialog
          fullscreen
          hide-overlay
          max-width="500px"
          v-if="dialogEdicao"
          v-model="dialogEdicao"
        >
          <modal-edicao
            v-if="dialogEdicao"
            :petId="editedPetId"
            :visualizar="visualizar"
            @close="closeDialogEdited"
            @response="
              dialogEdicao = false;
              setPets();
            "
          />
        </v-dialog>
      </template>
    </v-data-table>
  </div>
</template>
<script>
//import pets from ''
import Mixin from "@/mixins/vuex_snackbar_mixin.js";
import VuexUsuarioMixin from "@/mixins/vuex_usuario_mixin.js";
import PetsService from "../services/pets_service.js";
//import Historico from "@/components/historico/historico.vue";
import modalCadastro from "@/components/pets/modal_cadastro";
//import Arquivo from "@/components/prontuarios/arquivos.vue";
import UploadExames from "@/components/prontuarios/uploadExamesCliente.vue";
import uploadArquivosCliente from "@/components/prontuarios/uploadArquivosCliente.vue";
import modalEdicao from "@/components/pets/modal_edicao_cliente";

export default {
  mixins: [Mixin, VuexUsuarioMixin],
  components: {
    // Historico,
    modalCadastro,
    modalEdicao,
    UploadExames,
    uploadArquivosCliente,
    //Arquivo
  },
  data: () => ({
    pets: [],
    dialogArquivo: false,
    dialogUploadArquivo: false,
    dialogEdicao: false,
    petArquivo: {},
    dialog: false,
    editedPetId: {},
    visualizar: false,
    dialogCadastrar: false,
    petList: [],
    petService: PetsService.build(),
    headers: [
      {
        text: "ID",
        align: "start",
        sortable: false,
        value: "id_pet",
      },
      { text: "Nome", value: "nome_pet" },
      { text: "Raça", value: "nome_raca" },
      { text: "Sexo", value: "sexo" },
      { text: "Ações", align: "end", value: "actions" },
    ],
  }),
  mounted() {
    this.pets = this.$global.pets;
    this.setPets();
  },
  methods: {
    setPets() {
      this.petService.getPetsByCliente().then(async (response) => {
        if (response.status !== 200) return;
        const { data } = await response.json();
        const { pets } = data;
        this.petList = pets;
      });
    },
    editItem(item, bool) {
      this.visualizar = bool;
      this.editedPetId = parseInt(item.id_pet) || 0;
      this.dialogEdicao = true;
    },
    closeDialogEdited() {
      this.dialogEdicao = false;
    },

    uploadArquivos(item) {
      this.petArquivo = { id_paciente: item.id_paciente, id_pet: item.id_pet };
      this.dialogUploadArquivo = true;
    },
    verArquivos(item) {
      this.petArquivo = { id_paciente: item.id_paciente, id_pet: item.id_pet };
      this.dialogArquivo = true;
    },
    closeDialogCadastrar() {
      this.dialogCadastrar = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    agendamento() {
      this.$router.push("agendamento-cliente");
    },
    listHistorico() {
      this.dialog = true;
    },
    redirectTo(path) {
      this.$router.push(path);
    },
    close() {
      this.dialog = false;
    },
    atualizarPetPosCadastro() {
      this.setPets();
      this.closeDialogCadastrar();
    },
  },
};
</script>
<style scoped>
.v-application .text-start {
  text-align: center !important;
}

.v-btn {
  color: white !important;
}
</style>
