<template>
  <div>
    <v-card min-height="600px">
      <v-toolbar style="background-color: #1daf80; color: #fff">
        <v-btn icon dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          <span class="headline">Solicitar Exames</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <div v-if="!loading">
            <div class="d-flex">
              <v-autocomplete
                :loading="loadingExames"
                :items="exames"
                v-model="exameSelecinado"
                label="Selecione um exame"
                class="col-12"
                item-text="nome"
                return-object
              >
              </v-autocomplete>
            </div>
          </div>
          <div class="d-flex flex-wrap px-4">
            <span
              v-for="(exame, id) in selecionados"
              :key="id"
              class="border px-2 py-1 mr-1 mt-1"
              >{{ exame }}
              <button @click="excluirExame(exame)">
                <v-icon color="error" small>mdi-close-thick</v-icon>
              </button></span
            >
          </div>
          <div class="d-flex flex-column">
            <v-text-field
              v-model="obs"
              label="Observação 1"
              class="col-12"
            ></v-text-field>
            <v-textarea
              label="Observação 2"
              class="col-12"
              v-model="obs2"
              rows="2"
            ></v-textarea>
          </div>
          <!-- <div>
            <v-select
              v-model="convenioSelecionado"
              :items="convenios"
              label="Convênio"
              class="mb-2 ml-1 col-7"
            >
            </v-select>
          </div> -->
        </v-container>
        <div class="row">
          <div
            class="col-md-10 text-right d-flex justify-lg-space-between m-auto my-2"
          >
            <button
              class="btn btn-cancelar btn-padding font-weight-bold text-gray"
              @click="$emit('close')"
            >
              Cancelar
            </button>
            <button class="btn-style-green " @click="gerarPdf()">
              <span> Gerar PDF </span>
            </button>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ExameService from "@/services/exame_service.js";

export default {
  beforeMount() {
    console.log("loading1");
    this.listaExames();
  },
  data: () => ({
    selecionados: [],
    obs: "avaliação pré-operatório",
    obs2: "",
    exames: [],
    loading: false,
    exameSelecinado: "",
    convenios: ["Público", "Particular"],
    convenioSelecionado: "",
  }),
  watch: {
    exameSelecinado() {
      this.selecionados.push(this.exameSelecinado.nome);
    },
  },
  methods: {
    listaExames() {
      this.loadingExames = true;
      const exameService = new ExameService();
      this.exames = [];
      const onSucess = (status) => (body) => {
        if (status === 200) {
          console.log("200 data");
          console.log(body.data);
          this.exames = body.data.exames;
        } else {
          console.log(body);
        }
      };
      const onError = (error) => {
        console.log(error);
      };
      const onEnd = () => {
        console.log("aqui");
        console.log(this.exames);
        this.loadingExames = false;
      };
      exameService.getList({ onSucess, onError, onEnd });
    },
    gerarPdf() {
      if (this.selecionados.length < 1) {
        3;
        console.log("Faltou os exames");
        return;
      }
      this.$emit("gerarPDF", {
        doc: "exames",
        exames: this.selecionados,
        obs: this.obs,
        obs2: this.obs2,
        convenio: this.convenioSelecionado
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase(),
      });
      this.selecionados = [];
      this.obs = "avaliação pré-operatório";
      this.obs2 = "";
    },
    excluirExame(exame) {
      this.selecionados.splice(this.selecionados.indexOf(exame), 1);
    },
  },
};
</script>
<style scoped>
.btn-style-green {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #1daf80 !important;
  height: 30px !important;
  font-size: 12px;
  padding: 10px !important;
  border-radius: 50px;
  margin: 20px 10px 20px 0px;
}
</style>
