<template>
  <div>
    <v-data-table
      :headers="dessertHeaders"
      :items="comandas"
      item-key="id"
      @item-expanded="comandaItens($event)"
      show-expand
      class="elevation-1"
      :loading="loading"
      loading-text="Carregando..."
    >
      <template v-slot:item.nome_pet="{ item }">
        <p style="max-width: 9rem;">
          {{ item.nome_pet }}
        </p>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">Tipo</th>
                <th scope="col">Procedimento</th>
                <th scope="col">Convênio</th>
                <th scope="col">Data - Hora de Requisição</th>
                <th scope="col">Data - Hora de Execução</th>
                <th scope="col">Data - Hora do Pagamento</th>
                <th scope="col">Status</th>
                <th scope="col">Forma de pagamento</th>
                <th scope="col">Valor</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="itemComanda in item.itensComanda"
                :key="itemComanda.comanda_item_id"
              >
                <td>{{ itemComanda.tipo_procedimento_descricao }}</td>
                <td>{{ itemComanda.procedimento_descricao }}</td>
                <td>{{ itemComanda.tipo_convenio_descricao }}</td>
                <td>
                  {{
                    `
                  ${itemComanda.data_requisicao
                    .split("-")
                    .reverse()
                    .join("/")} - 
                  ${itemComanda.hora_requisicao}
                `
                  }}
                </td>
                <td>
                  {{
                    itemComanda.data_execucao == "1900-01-01"
                      ? "-"
                      : `
                  ${itemComanda.data_execucao
                    .split("-")
                    .reverse()
                    .join("/")} - 
                  ${itemComanda.hora_execucao}
                `
                  }}
                </td>
                <td>
                  {{
                    itemComanda.data_pagamento == "1900-01-01"
                      ? "-"
                      : `
                  ${itemComanda.data_pagamento
                    .split("-")
                    .reverse()
                    .join("/")} - 
                  ${itemComanda.hora_pagamento}
                `
                  }}
                </td>
                <td>{{ itemComanda.status_descricao }}</td>
                <td>{{ itemComanda.formaPagamento }}</td>
                <td>
                  {{
                    Intl.NumberFormat("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    }).format(itemComanda.valor)
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="ml-n5">
          <v-btn
            class="btn"
            small
            @click="fecharComanda(item)"
            v-if="(item.data_fechada == 'comanda aberta') & (perfil != 6)"
            >Fechar comanda</v-btn
          >
          <v-icon class="ml-8" @click="showDetalhesComandas(item)">
            mdi mdi-magnify
          </v-icon>
          <v-btn
            color="#3e682a"
            text
            class="ml-2 mr-n5 font-weight-bold"
            v-if="(item.procedimentos_publicos != 0) & (perfil != 6)"
            @click="showAuditoriaComandas(item)"
          >
            A
          </v-btn>
        </div>
      </template>
    </v-data-table>
    <template v-if="dialogDetalhesComandas">
      <v-dialog
        v-model="dialogDetalhesComandas"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar dark class="bg-verde">
            <v-btn icon dark @click="dialogDetalhesComandas = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Comanda Detalhes </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items> </v-toolbar-items>
          </v-toolbar>
          <modal-comanda-detalhes
            :comanda="comandaDetalhes"
            :tutor="tutor"
            @close="() => (dialogDetalhesComandas = false)"
          />
        </v-card>
      </v-dialog>
    </template>

    <template v-if="dialogAuditoriaComandas">
      <v-dialog
        v-model="dialogAuditoriaComandas"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar dark class="bg-verde">
            <v-btn icon dark @click="dialogAuditoriaComandas = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Auditoria Comanda</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items> </v-toolbar-items>
          </v-toolbar>
          <modal-auditoria-comanda
            :comanda="comandaDetalhes"
            :tutor="tutor"
            @close="() => (dialogAuditoriaComandas = false)"
          />
        </v-card>
      </v-dialog>
    </template>
  </div>
</template>
<script>
import Mixin from "@/mixins/vuex_mixin.js";
import ComandaService from "@/services/comanda_service.js";
import PacienteService from "../../services/paciente_service";
import ModalComandaDetalhes from "@/components/pacientes/modal_comanda_detalhes_teste.vue";
import ModalAuditoriaComanda from "@/components/relatorios/modal_auditoria_comanda.vue";

export default {
  mixins: [Mixin],
  emits: ["recarregarComandas"],
  props: {
    comandas: Array,
    loading: Boolean,
  },
  components: {
    ModalComandaDetalhes,
    ModalAuditoriaComanda,
  },
  data() {
    return {
      dessertHeaders: [
        { text: "Id", align: "start", value: "id" },
        { text: "Tutor", align: "start", value: "nome_cliente" },
        { text: "Pet", value: "nome_pet" },
        { text: "Abertura", value: "data_criada" },
        { text: "Fechamento", value: "data_fechada" },
        { text: "Valor Total", value: "valor_total" },
        { text: "Valor Pago", value: "valor_pago" },
        { text: "", value: "actions" },
        { text: "", value: "data-table-expand" },
      ],
      tutor: {},
      dialogDetalhesComandas: false,
      dialogAuditoriaComandas: false,
      comandaDetalhes: {},
      comandaService: ComandaService.build(),
      pacienteService: new PacienteService(),
      perfil: "",
    };
  },
  beforeMount() {
    this.perfil = this.$_GETTER_perfil.id;
  },
  methods: {
    async comandaItens(event) {
      if (event.value) {
        this.loading = true;
        const item = event.item;
        item.itensComanda = await this.getComandaItems(item.id);
        item.itensComanda = item.itensComanda.map((item) => {
          return { ...item, formaPagamento: "" };
        });

        item.itensComanda.map((item) => {
          item.formaPagamento = item.id_forma_pagamento
            ? this.formasPagamento.filter(
                (formPag) => formPag.id == item.id_forma_pagamento
              )[0].descricao
            : "-";
        });
        this.loading = false;
      }
    },
    async getComandaItems(comandaId) {
      const response = await this.comandaService.getComandaItensByComandaId(
        comandaId
      );

      return response;
    },
    async fecharComanda(comanda) {
      const response = await this.comandaService.fecharComanda(comanda.id ?? 0);
      if (response.status == 200) {
        this.$_ACTIONS_showSnackbarMessage({
          message: "A comanda foi fechada com sucesso.",
          color: "sucess",
        });
        this.$emit("recarregarComandas");
      } else {
        const error = await response.json();
        this.$_ACTIONS_showSnackbarMessage({
          message: error.message,
          color: "error",
        });
      }
    },
    async showDetalhesComandas(comanda) {
      const resp = await this.pacienteService.getPacienteByClienteId(
        comanda.id_cliente
      );
      this.tutor = resp.data;

      this.comandaDetalhes = {
        ...comanda,
        comanda_id: comanda.id,
        cliente_id: comanda.id_cliente,
        valor: parseFloat(comanda.valor_total.slice(3)),
        comandaEstaAberta: comanda.data_fechada === "comanda aberta",
      };
      this.dialogDetalhesComandas = true;
    },
    async showAuditoriaComandas(comanda) {
      console.log(comanda);
      const resp = await this.pacienteService.getPacienteByClienteId(
        comanda.id_cliente
      );
      this.tutor = resp.data;

      this.comandaDetalhes = {
        ...comanda,
        comanda_id: comanda.id,
        cliente_id: comanda.id_cliente,
        valor: parseFloat(comanda.valor_total.slice(3)),
        comandaEstaAberta: comanda.data_fechada === "comanda aberta",
      };
      this.dialogAuditoriaComandas = true;
    },
  },
};
</script>
