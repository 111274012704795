<template>
  <div class="background-login">
    <div>
      <login />
    </div>
  </div>
</template>

<script>
import login from "@/components/login/login.vue";
export default {
  components: { login },
};
</script>
<style scoped>
.background-login {
  position: fixed;
  width: 100%;
  height: 100%;
  background-image: url("../assets/images/BG_Pettree.png");
  background-size: cover;
}
</style>
