<template>
  <div>
    <v-card class="pb-2">
      <v-toolbar style="background-color: #790202; color: #fff">
        <v-toolbar-title>
          <span class="headline">Excluir Atendimento</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="m-0">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12">
              <h3 class="mt-0">
                Tem certeza que deseja excluir o atendimento?
              </h3>
              <p class="text-left mt-0">
                <strong>Tutor: </strong>{{ compromissoExclusao.nomePaciente
                }}<br />
                <strong>Pet: </strong>{{ compromissoExclusao.nomePet }}<br />
                <strong>Procedimento: </strong
                >{{ compromissoExclusao.procedimento }}<br />
                <strong>Status Atendimento: </strong
                >{{
                  compromissoExclusao.idConsulta ? "Atendido" : "Agendado"
                }}
                | {{ getData() }}
              </p>
            </div>
          </div>
        </div>
      </v-card-text>
      <v-card-actions class="justify-content-between mx-3">
        <v-btn text tile elevation="2" color="blue" @click="$emit('close')">
          Cancelar
        </v-btn>
        <v-btn
          elevation="2"
          color="red"
          :dark="!loading"
          :loading="loading"
          :disabled="loading"
          @click="deletar()"
        >
          <strong>Deletar</strong>
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import Mixin from "@/mixins/vuex_mixin.js";
import vuex_snackbar_mixin from "@/mixins/vuex_snackbar_mixin.js";
import VuexUsuarioMixin from "@/mixins/vuex_usuario_mixin.js";
import DadosCadastraisMixin from "@/mixins/dados_cadastrais_mixin.js";
import AgendamentoService from "@/services/agendamento_service.js";

export default {
  mixins: [Mixin, VuexUsuarioMixin, DadosCadastraisMixin, vuex_snackbar_mixin],
  components: {},
  props: {
    compromissoExclusao: Object,
  },
  data: () => ({
    loading: false,
    agendamentoService: AgendamentoService.build(),
  }),
  async mounted() {},
  watch: {},
  methods: {
    async deletar() {
      this.loading = true;

      await this.agendamentoService
        .deletarAtendimento(this.createModel())
        .then((response) => {
          if (response.status !== 200) {
            this.$_ACTIONS_showSnackbarMessage({
              message: "Erro ao deletar",
              color: "error",
            });
            return;
          } else {
            this.$_ACTIONS_showSnackbarMessage({
              message: "Atendimento Excluido com sucesso",
              color: "sucess",
            });
            this.$emit("atualizar");
          }
          this.loading = false;
        });
      this.loading = false;
    },
    createModel() {
      return {
        id_agendamento: this.compromissoExclusao.idAgendamento,
        id_consulta: this.compromissoExclusao.idConsulta ?? 0,
        id_requisicao: this.compromissoExclusao.id_requisicao,
      };
    },
    getData() {
      const arrDate = this.compromissoExclusao.data.split("-");
      return `${arrDate[2]}/${arrDate[1]}/${arrDate[0]} - ${this.compromissoExclusao.horarioInicio}`;
    },
  },
};
</script>

<style scoped></style>
