<template>
  <div>
    <div v-if="!modalShowCadstroPet" class="p-5">
      <form class="p-lg-3 row">
        <div class="col-lg-12">
          <div class="mx-auto my-4">
            <h3>Adicionar foto de perfil</h3>
            <picture-input
              buttonClass="btn btn-app-primary btn-round font-weight-bold"
              removeButtonClass="btn btn-danger btn-round font-weight-bold"
              ref="pictureInput"
              :prefill="image"
              @change="trocarImagem"
              width="400"
              height="400"
              margin="16"
              accept="image/jpeg,image/png,image/bmp"
              :prefillOptions="{
                fileType: 'png',
              }"
              size="10"
              :removable="true"
              :customStrings="{
                upload:
                  '<p> Seu dispositivo não oferece suporte para upload de arquivos. </p> ',
                drag:
                  '<h3>Arraste uma imagem ou <br> clique aqui para selecionar um arquivo.</h3>',
                tap:
                  'Toque aqui para selecionar uma imagem <br> de sua galeria.',
                change: 'Trocar imagem',
                remove: 'Remover imagem',
                select: 'Selecione uma imagem',
                selected: '<p>Foto selecionada com sucesso!</p>',
                fileSize: 'O tamanho do arquivo excede o limite',
                fileType: 'Este tipo de arquivo não é compatível.',
              }"
            >
            </picture-input>
          </div>
        </div>
        <div class="col-lg-6 form-group clearfix mb-3">
          <label for="nome">Nome Completo</label>
          <v-text-field
            :error-messages="error.nome"
            v-model="nome"
            :rules="[rules.required]"
            :type="'text'"
            placeholder="Seu nome completo"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>

        <div class="col-lg-6 form-group clearfix mb-3">
          <label for="emailCadastroUsuario">Email</label>
          <v-text-field
            :error-messages="error.email"
            v-model="email"
            :type="'email'"
            placeholder="Escreva seu email"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>

        <!-- <div class="col-lg-3 form-group clearfix mb-3">
          <label for="Senha">Senha</label>
          <v-text-field
            :error-messages="error.senha"
            v-model="senha"
            
            :type="'password'"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div> -->

        <div class="col-lg-3 form-group clearfix mb-3">
          <label for="Telefone01">Telefone Celular:</label>
          <v-text-field
            v-mask="'(##)#####-####'"
            :error-messages="error.telefone_1"
            v-model="telefone_1"
            :type="'text'"
            placeholder="(99) 99999-9999"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>

        <div class="col-lg-3 form-group clearfix mb-3">
          <label for="Telefone01">Telefone Fixo:</label>
          <v-text-field
            v-mask="'(##)####-####'"
            :error-messages="error.telefone_2"
            v-model="telefone_2"
            :type="'text'"
            placeholder="(99) 3210-1234"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>

        <div class="col-lg-3 form-group clearfix mb-3">
          <label for="Telefone01">Telefone Alternativo:</label>
          <v-text-field
            v-mask="'(##)#####-####'"
            :error-messages="error.telefone_2"
            v-model="telefone_3"
            :type="'text'"
            placeholder="(99) 99999-9999"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>

        <div class="col-lg-3 form-group clearfix mb-3">
          <label for="CPF">CPF</label>
          <v-text-field
            v-mask="'###.###.###-##'"
            :error-messages="error.cpf"
            v-model="cpf"
            :type="'text'"
            placeholder="000.000.000-00"
            style="padding-top: 0 !important"
          ></v-text-field>
          <span class="text-muted">ex: "123.456.7890-12"</span>
        </div>

        <div class="col-lg-3 form-group clearfix mb-3">
          <label for="RG">RG</label>

          <v-text-field
            :error-messages="error.rg"
            v-model="rg"
            :type="'text'"
            placeholder="00.000.000-0"
            style="padding-top: 0 !important"
          ></v-text-field>

          <span class="text-muted">ex: "xx.xxx.xxx-x"</span>
        </div>

        <div class="col-lg-3 form-group clearfix mb-3">
          <label>Data de nascimento</label>

          <v-text-field
            :error-messages="error.data_nascimento"
            v-model="dataNascimento"
            clear-icon="clear"
            style="margin: 0 5px; padding-top: 5px !important"
            type="date"
            :rules="[rules.data]"
            :outlined="false"
          />

          <span class="text-muted">ex: "Dia/Mês/Ano"</span>
        </div>

        <div class="col-lg-3 form-group clearfix mb-3">
          <label class="">Gênero</label>
          <v-radio-group :error-messages="error.genero" v-model="genero">
            <v-radio label="Masculino" value="M" />
            <v-radio label="Feminino" value="F" />
            <v-radio label="Prefiro não informar" value="O" />
          </v-radio-group>
        </div>

        <div class="col-lg-3 form-group clearfix mb-3">
          <label for="Estado-civil">Indicado por</label>

          <v-text-field
            :error-messages="error.indicado_por"
            v-model="indicado_por"
            clear-icon="clear"
            style="margin: 0 5px; padding-top: 5px !important"
            :type="'text'"
            :outlined="false"
          />
        </div>

        <div class="col-lg-3 form-group clearfix mb-3">
          <label for="CEP">CEP</label>

          <v-text-field
            v-mask="'#####-###'"
            :error-messages="error.cep"
            v-model="cep"
            @blur="searchCep"
            :type="'text'"
            placeholder="00000-000"
            style="padding-top: 0 !important"
          ></v-text-field>

          <span class="text-muted">ex: "xxxxx-xxx"</span>
        </div>

        <div class="col-lg-3 form-group clearfix mb-3">
          <label for="Rua">Logradouro</label>

          <v-text-field
            :error-messages="error.logradouro"
            v-model="logradouro"
            :type="'text'"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>

        <div class="col-lg-3 form-group clearfix mb-3">
          <label for="Bairro">Bairro</label>

          <v-text-field
            :error-messages="error.bairro"
            v-model="bairro"
            :type="'text'"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>

        <div class="col-lg-3 form-group clearfix mb-3">
          <label for="Numero">Numero</label>

          <v-text-field
            :error-messages="error.numero"
            v-model="numero"
            :type="'text'"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>

        <div class="col-lg-3 form-group clearfix mb-3">
          <label for="Complemento">Complemento</label>

          <v-text-field
            :error-messages="error.complemento"
            v-model="complemento"
            :type="'text'"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>

        <div class="col-lg-3 form-group clearfix mb-3">
          <label for="Referencia">Referência</label>

          <v-text-field
            :error-messages="error.referencias"
            v-model="referencias"
            :type="'text'"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>

        <div class="col-lg-3 form-group clearfix mb-3">
          <label for="Cidade">Cidade</label>

          <v-text-field
            :error-messages="error.cidade"
            v-model="cidade"
            :type="'text'"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>

        <div class="col-lg-3 form-group clearfix mb-3">
          <label for="Estado">Estado</label>
          <v-text-field
            :error-messages="error.estado"
            v-model="estado"
            :type="'text'"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>

        <div class="col-lg-6 form-group clearfix mb-3" v-show="false">
          <label for="Convenio">Convênio</label>
          <v-text-field
            v-model="convenio"
            :type="'text'"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>

        <div class="col-md-12 d-flex justify-space-between align-items-center">
          <div class="col-lg-6 form-group clearfix p-0">
            <label for="nome">Senha</label>
            <v-text-field
              v-model="senha"
              disabled
              :type="'text'"
              style="padding-top: 0 !important"
            ></v-text-field>
          </div>
        </div>
        <div class="col-md-12 d-flex justify-space-between align-items-center">
          <div class="col-lg-6 form-group clearfix p-0">
            <label for="nome">Observações</label>
            <v-textarea
              class="p-0"
              maxlength="200"
              v-model="observacao"
              placeholder="Observações"
              outlined
              style="padding-top: 0 !important"
            ></v-textarea>
          </div>
          <v-btn
            class="mr-5"
            rounded="pill"
            color="#3E682A"
            dark
            @click.prevent="cadastrarPaciente"
          >
            <span v-if="!loading"> Cadastrar </span>
            <v-progress-circular
              v-else
              indeterminate
              color="white"
            ></v-progress-circular>
          </v-btn>
        </div>
      </form>
    </div>
    <div v-else>
      <v-card>
        <h1>
          <span class="headline">Quer cadastrar um pet para esse tutor?</span>
        </h1>

        <div class="p-5">
          <v-row>
            <v-col>
              <v-btn @click="close()" color="#3E682A" dark class="mb-2">
                Não
              </v-btn>
            </v-col>
            <v-col>
              <v-dialog
                persistent
                fullscreen
                v-model="dialogCadastrarPet"
                max-width="500px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="#3E682A"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Novo Pet
                  </v-btn>
                </template>
                <modal-cadastro
                  :idClienteCadastrado="idCliente"
                  :nomeClienteCadastrado="nomeClienteCadastrado"
                  @close="closeDialogCadastrar"
                  @response="responseCadastroPet"
                />
              </v-dialog>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import CEPMixin from "@/mixins/cep_mixin.js";
import Mixin from "@/mixins/vuex_mixin.js";
import PacienteService from "@/services/paciente_service.js";
import PictureInput from "vue-picture-input";
import modalCadastro from "@/components/pets/modal_cadastro";

export default {
  components: {
    PictureInput,
    modalCadastro,
  },
  mixins: [CEPMixin, Mixin],
  data() {
    return {
      idPaciente: 0,
      image: "",
      nomeClienteCadastrado: "",
      avatarPaciente: "",
      //Validação de Senha
      loading: false,
      idCliente: 0,
      dialogCadastrarPet: false,
      // Formulário
      modalShowCadstroPet: false,
      observacao: "",
      formCadastro: "",
      nome: "",
      email: "",
      telefone_1: "",
      telefone_2: "",
      telefone_3: "",
      cpf: "",
      rg: "",
      dataNascimento: "",
      genero: "",
      estadoCivil: "",
      profissao: "",
      indicado_por: "",
      cep: "",
      altura: "",
      peso: "",
      logradouro: "",
      bairro: "",
      numero: "",
      complemento: "",
      referencias: "",
      cidade: "",
      estado: "",
      convenio: "",
      senha: "Anclivepa",
      error: {
        nome: "",
        email: "",
        telefone_1: "",
        telefone_2: "",
        cpf: "",
        rg: "",
        data_nascimento: "",
        genero: "",
        estadoCivil: "",
        profissao: "",
        indicado_por: "",
        cep: "",
        logradouro: "",
        bairro: "",
        numero: "",
        complemento: "",
        referencias: "",
        cidade: "",
        estado: "",
        senha: "",
        statusResposta: null,
      },
      rules: {
        required: (value) => !!value || "Campo obrigatório.",
        min: (v) => v.length >= 8 || "Minimo de 8 caracteres",
        emailMatch: () => `O e-mail e a senha inseridos não correspondem`,
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Email inválido.";
        },
        data: (value) => {
          let data = value
            .split("-")
            .reverse()
            .join("-");
          //eslint-disable-next-line
          const patternValidaData = /^(((0[1-9]|[12][0-9]|3[01])([-.\/])(0[13578]|10|12)([-.\/])(\d{4}))|(([0][1-9]|[12][0-9]|30)([-.\/])(0[469]|11)([-.\/])(\d{4}))|((0[1-9]|1[0-9]|2[0-8])([-.\/])(02)([-.\/])(\d{4}))|((29)(\.|-|\/)(02)([-.\/])([02468][048]00))|((29)([-.\/])(02)([-.\/])([13579][26]00))|((29)([-.\/])(02)([-.\/])([0-9][0-9][0][48]))|((29)([-.\/])(02)([-.\/])([0-9][0-9][2468][048]))|((29)([-.\/])(02)([-.\/])([0-9][0-9][13579][26])))$/;

          if (!patternValidaData.test(data)) return "Data Inválida";
        },
      },
    };
  },
  mounted() {
    this.formCadastro = document.forms[0];
  },
  computed: {
    imc() {
      const v =
        parseFloat(this.peso) / parseFloat(this.altura.replace(",", ".")) ** 2;
      if (isNaN(v)) return "";
      return v.toFixed(2);
    },
  },
  methods: {
    closeDialogCadastrar() {
      this.dialogCadastrarPet = false;
    },
    searchCep() {
      this.$buscarCep(this, this.cep);
    },
    zerarFormulario() {
      Array.from(this.formCadastro.elements).forEach((element) => {
        element.value = "";
      });
    },
    zerarErros() {
      Object.keys(this.error).forEach((error) => {
        this.error[error] = "";
      });
    },
    cadastrarPaciente() {
      // var cep = this.CEP_MIXIN_cep;
      // var logradouro = this.CEP_MIXIN_logradouro;
      // var bairro = this.CEP_MIXIN_bairro;

      if (!this.formCadastro.checkValidity()) {
        return;
      }
      const telefone_3 = this.telefone_3.replace(/[^0-9]/g, "");
      var formdata = new FormData();
      formdata.append("nome", this.nome);
      formdata.append("email", this.email);
      formdata.append("telefone_1", this.telefone_1);
      formdata.append("telefone_2", this.telefone_2);
      formdata.append("telefone_3", telefone_3);
      formdata.append("cpf", this.cpf);
      formdata.append("rg", this.rg);
      formdata.append("genero", this.genero);
      formdata.append("data_nascimento", this.dataNascimento);
      formdata.append("indicado_por", this.indicado_por);
      formdata.append("cep", this.cep);
      formdata.append("logradouro", this.logradouro);
      formdata.append("bairro", this.bairro);
      formdata.append("numero", this.numero);
      formdata.append("complemento", this.complemento);
      formdata.append("referencias", this.referencias);
      formdata.append("cidade", this.cidade);
      formdata.append("estado", this.estado);
      formdata.append("observacao", this.observacao);
      formdata.append("adicionado_por", this.$_GETTERS_usuario.id);
      formdata.append("senha", this.senha);

      this.loading = true;
      const responseFunctions = {
        onSucess: this.mostrarFeedback(),
        // onError: this.mostrarFeedback(),
        onError: () => {
          this.$_ACTIONS_showSnackbarMessage({
            message: "Erro no processamento de sua solicitação.",
            color: "error",
          });
          this.loading = false;
        },
        onEnd: () => {
          this.loading = false;
        },
      };
      const pacienteService = new PacienteService();
      pacienteService.register(responseFunctions, formdata);
    },
    mostrarFeedback() {
      return (status) => (body) => {
        if (body.message) {
          if (status === 200 || status === 201) {
            this.zerarErros();
            this.$_ACTIONS_showSnackbarMessage({
              message:
                body.message || this.$global.messages.internalServerError,
              color: "sucess",
            });
            console.log(body);
            this.idPaciente = body.data.id_paciente;
            this.setIdCliente();
            this.nomeClienteCadastrado = this.nome;
            this.modalShowCadstroPet = true;
            this.zerarFormulario();
          }
          if (status === 400 && body.errors) {
            const erro =
              body.errors.code == 1062
                ? `Cadastro já existente com esse ${
                    body.errors.message.split(" ").slice(-1)[0]
                  }`
                : body.message;
            this.$_ACTIONS_showSnackbarMessage({
              message: erro || this.$global.messages.internalServerError,
              color: "error",
            });
            const errors = body.errors;
            Object.keys(body.errors).forEach((error) => {
              if (errors[error]) {
                this.error[error] = errors[error];
              }
            });
          }
          if (status === 500) {
            this.$_ACTIONS_showSnackbarMessage({
              message: this.$global.messages.internalServerError,
              color: "error",
            });
          }
        } else {
          this.$_ACTIONS_showSnackbarMessage({
            message: "Erro no processamento de sua solicitação.",
            color: "error",
          });
          this.zerarFormulario();
          this.$emit("response");
          this.$emit("close");
        }
      };
    },
    responseCadastroPet() {
      this.dialogCadastrarPet = false;
    },
    //Upload de fotos de perfil
    trocarImagem() {
      if (this.$refs.pictureInput) {
        this.avatarPaciente = this.$refs.pictureInput.file;
        this.image = this.$refs.pictureInput.image;
      } else {
        console.log("API File Reader não suportada: use o <form>");
      }
    },
    close() {
      this.$emit("response");
      this.$emit("close");
    },
    setIdCliente() {
      const pacienteService = new PacienteService();
      pacienteService.getPacienteById(this.idPaciente).then((response) => {
        console.log(response);
        this.idCliente = response.data.id_cliente;
      });
    },
  },
};
</script>

<style scoped>
.form-group label {
  text-align: left !important;
  display: block;
}

.form-group .label-genero {
  margin-right: 1rem;
}
</style>
