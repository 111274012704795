<template>
  <div>
    <v-card class="pb-2">
      <v-toolbar style="background-color:#1d8daf ; color: #fff">
        <v-toolbar-title>
          <span class="headline">Alterar Data Anamnese</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <div v-if="!loadingComanda">
        <v-card-text class="m-0">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-12 text-left mb-0">
                <h4 class="mb-1">Informações</h4>
                <p class="mb-0">
                  <strong>Comanda: </strong> Data:
                  {{ infoComanda.dataFormatada }} - Hora:
                  {{ infoComanda.horaFormatada }}<br />
                  <strong>Anamnese: </strong> Data: {{ info.dataFormatada }}
                  - Hora:
                  {{ info.horaFormatada }}
                </p>
                {{ auditoria }}
              </div>
              <div class="col-12 my-0">
                <!-- <p>
                  Data:{{ info.data }} Hora: {{ info.hora_inicio }} Id
                  Requisição: {{ info.id_requisicao }}
                </p> -->
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      label="Data"
                      type="date"
                      outlined
                      v-model="dataSelected"
                      hide-details
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      cols="4"
                      label="Hora"
                      type="time"
                      outlined
                      v-model="horaSelected"
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
                <!-- <p class="text-left mt-0">
                <strong>Tutor: </strong>{{ compromissoExclusao.nomeTutor
                }}<br />
                <strong>Pet: </strong>{{ compromissoExclusao.nomePet }}<br />
                <strong>Procedimento: </strong
                >{{ compromissoExclusao.procedimento }}<br />
                <strong>Data e Hora: </strong>{{ getData() }}
              </p> -->
              </div>
              <div v-if="checkAviso()" class="col-12 my-0">
                <!-- <h5 style="color: red">
                  A data e hora que você está selecionando é antes da data e
                  hora de criação da comanda, com isso a data não poderá ser
                  altera.
                </h5> -->
                <h5 style="color: red">
                  A data e hora que você está selecionando é antes da data e
                  hora de criação da comanda, caso você opte alteara será criada
                  um na comanda com a data e hora informada e a anamnese será
                  alocada nela.
                </h5>
              </div>
            </div>
          </div>
        </v-card-text>
        <v-card-actions class="justify-content-between mx-3">
          <v-btn text tile elevation="2" color="blue" @click="$emit('close')">
            Cancelar
          </v-btn>
          <v-btn
            elevation="2"
            color="#1d8daf"
            :dark="!loadingBtn && !disabledBtn()"
            :loading="loadingBtn"
            :disabled="loadingBtn || disabledBtn()"
            @click="novaComanda()"
          >
            <strong>Editar</strong>
          </v-btn>
        </v-card-actions>
      </div>
      <v-progress-circular
        v-else
        class="my-10"
        indeterminate
        color="#1d8daf"
      ></v-progress-circular>
    </v-card>
  </div>
</template>

<script>
import Mixin from "@/mixins/vuex_mixin.js";
import vuex_snackbar_mixin from "@/mixins/vuex_snackbar_mixin.js";
import VuexUsuarioMixin from "@/mixins/vuex_usuario_mixin.js";
import DadosCadastraisMixin from "@/mixins/dados_cadastrais_mixin.js";
import AgendamentoService from "@/services/agendamento_service.js";
import ComandaService from "@/services/comanda_service.js";
import RequisicaoService from "@/services/requisicao_exame_service.js";

export default {
  mixins: [Mixin, VuexUsuarioMixin, DadosCadastraisMixin, vuex_snackbar_mixin],
  components: {},
  props: {
    info: Object,
    auditoria: {
      type: Boolean,
    },
  },
  data: () => ({
    loadingComanda: false,
    loadingBtn: false,
    services: {
      agendamentoService: AgendamentoService.build(),
      comandaService: ComandaService.build(),
      requisicaoService: RequisicaoService.build(),
    },
    dataSelected: "",
    horaSelected: "",
    infoComanda: {},
    perfilId: "",
  }),
  async mounted() {
    this.getInfoComanda();
    this.perfilId = await JSON.parse(sessionStorage.vuex).perfil.id;
    const arrData = this.info.data.split("-");
    const arrHora = this.info.hora_inicio.split(":");
    this.info.dataFormatada = `${arrData[2]}/${arrData[1]}/${arrData[0]}`;
    this.info.horaFormatada = `${arrHora[0]}:${arrHora[1]}`;
  },
  watch: {},
  methods: {
    async getInfoComanda() {
      await this.services.comandaService
        .getDadosComandasByIdRequisicao(this.info.id_requisicao)
        .then((response) => {
          this.infoComanda = response;
          const arrData = response.data_criada.split("-");
          const arrHora = response.hora_criada.split(":");

          this.infoComanda.dataFormatada = `${arrData[2]}/${arrData[1]}/${arrData[0]}`;
          this.infoComanda.horaFormatada = `${arrHora[0]}:${arrHora[1]}`;
          const data = new Date(
            `${response.data_criada} ${arrHora[0]}:${arrHora[1]}`
          );
          this.infoComanda.time = data.getTime();

          this.loadingComanda = false;
        })
        .catch((err) => {
          console.error(err);
          this.loadingComanda = false;
        })
        .finally(() => {
          this.loadingComanda = false;
        });
      this.loadingComanda = false;
    },
    async novaComanda() {
      this.model = this.getModel();
      if (this.checkAviso()) {
        this.loadingBtn = true;
        await this.services.requisicaoService.postMoveNewComanda(
          {
            onSucess: (status) => (body) => {
              if (status === 200) {
                console.log(body);
                this.alterarData();
              }
            },
            onError: (error) => {
              this.$_ACTIONS_showSnackbarMessage({
                message: error.message,
                color: "error",
              });
              this.loading = false;
            },
            onEnd: () => {},
          },
          this.model
        );
      } else {
        this.alterarData();
      }
    },
    async alterarData() {
      this.loadingBtn = true;
      const responseFunctions = {
        onSucess: (status) => (response) => {
          console.log("response", response);
          if (status !== 200) {
            this.$_ACTIONS_showSnackbarMessage({
              message: response.message,
              color: "error",
            });
            this.loadingBtn = false;
            return;
          } else {
            this.$_ACTIONS_showSnackbarMessage({
              message: "Data e Hora Alterada com sucesso",
              color: "sucess",
            });
            this.$emit("atualizar");
          }
        },
        onError: () => {
          this.$_ACTIONS_showSnackbarMessage({
            message: this.$global.messages.internalServerError,
            color: "error",
          });
          this.loadingBtn = false;
        },
        onEnd: () => {
          this.loadingBtn = false;
        },
      };
      if (this.auditoria == true) {
        await this.services.requisicaoService.putDataAnamneseAdmin(
          responseFunctions,
          this.model
        );
      } else {
        await this.services.requisicaoService.putDataAnamnese(
          responseFunctions,
          this.model
        );
      }
      // .then(async (response) => {
      //   console.log("rest");
      //   console.log("rest", response);
      //   // const resp = await response.json();
      //   // if (response.status !== 200) {
      //   //   this.$_ACTIONS_showSnackbarMessage({
      //   //     message: resp.message,
      //   //     color: "error",
      //   //   });
      //   //   return;
      //   // } else {
      //   //   this.$_ACTIONS_showSnackbarMessage({
      //   //     message: resp.message,
      //   //     color: "sucess",
      //   //   });
      //   //   this.$emit("atualizar");
      //   // }
      //   // this.loadingBtn = false;
      // })
      // .catch((e) => {
      //   console.error(e);
      // })
      // .finally(() => {
      //   this.loadingBtn = false;
      // });
    },
    getModel() {
      return {
        id_requisicao: parseInt(this.info.id_requisicao),
        data_alterar: this.dataSelected,
        hora_alterar: this.horaSelected,
      };
    },
    createModel() {
      let id_assistente = parseInt(this.compromissoExclusao.id_assistente);
      if (
        this.compromissoExclusao.id_tipo_procedimento == 2 ||
        this.compromissoExclusao.id_tipo_procedimento == 3
      ) {
        id_assistente = 0;
      }
      return {
        id_requisicao: parseInt(this.compromissoExclusao.id_requisicao),
        id_agendamento: parseInt(this.compromissoExclusao.id_agendamento),
        id_medico: parseInt(this.compromissoExclusao.id_medico),
        id_assistente: id_assistente,
      };
    },
    getData() {
      const arrDate = this.compromissoExclusao.data.split("-");
      const hora = this.compromissoExclusao.hora_inicio.split(":");
      return `${arrDate[2]}/${arrDate[1]}/${arrDate[0]} - ${hora[0]}:${hora[1]}`;
    },
    disabledBtn() {
      // if (!this.checkAviso()) {
      if (this.dataSelected && this.horaSelected) {
        return false;
      } else {
        return true;
      }
    },
    checkAviso() {
      // if (this.dataSelected && this.horaSelected) {
      //   const time = new Date(`${this.dataSelected} ${this.horaSelected}`);
      //   if (this.infoComanda.time > time.getTime()) {
      //     return true;
      //   } else {
      //     return false;
      //   }
      // } else {
      //   return false;
      // }

      if (!this.disabledBtn()) {
        const time = new Date(`${this.dataSelected} ${this.horaSelected}`);
        if (this.infoComanda.time > time.getTime()) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped></style>
