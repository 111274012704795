<template>
  <div>
    <v-card min-height="100vh" class="py-1">
      <v-toolbar style="background-color: #1daf80; color: #fff">
        <v-btn icon dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          <span class="headline">Arquivos</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <div class="container mt-3" v-if="!usuarioPrefeitura">
          <div class="row flex justify-content-center">
            <v-file-input
              v-model="campoArquivo"
              class="col-3"
              :rules="rules"
              prepend-icon="mdi-paperclip"
              label="Adicionar documento"
            ></v-file-input>

            <v-text-field
              label="Descrição do arquivo"
              class="col-4"
              v-model="obs"
              :rules="[requiredRule]"
              id="descricao"
            ></v-text-field>
            <v-checkbox
              class="col-2"
              v-show="!tutor_view_checkbox"
              v-model="tutor_view"
              label="Exibir para tutor"
            ></v-checkbox>
            <!-- <v-btn
              class="col-2 white--text"
              style="margin-top: 13px;"
              color="#1daf80"
              :loading="uploading"
              @click="addDocumento"
              :disabled="validarBtn()"
              :dark="!validarBtn()"
            >
              Salvar arquivo
              <v-icon right dark>
                fas fa-upload
              </v-icon>
            </v-btn> -->
            <v-btn
              class="col-2 white--text"
              style="margin-top: 13px;"
              color="#1daf80"
              @click="addDocumento"
              :disabled="validarBtn()"
              :dark="!validarBtn()"
            >
              <span v-if="!uploading">
                Salvar arquivo
                <v-icon right dark>
                  fas fa-upload
                </v-icon>
              </span>
              <v-progress-circular
                v-else
                indeterminate
                color="white"
                style="width: 28px; heigth: 28px;"
              ></v-progress-circular>
            </v-btn>
          </div>
        </div>
        <v-container>
          <v-data-table
            v-on:update:sort-by="options.ordenacao = true"
            :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }"
            :search="search"
            :headers="headers"
            :items="listArquivos"
            :options.sync="options"
            :server-items-length="totalList"
            :loading="loading"
            sort-by="descricao"
            class="elevation-1"
          >
            <template v-slot:top class="my-4">
              <v-toolbar flat>
                <!-- <div class="row mt-2">
                  <div class="row mt-2">
                    <v-file-input
                      class="col-4"
                      accept=".pdf,.doc,.docx,.jpj,.png,.jpeg"
                      label="Add documento"
                      @change="selectFile"
                      v-if="!usuarioPrefeitura"
                    ></v-file-input>
                    <v-text-field
                      label="Descrição do arquivo"
                      class="col-3"
                      v-model="obs"
                      v-if="!usuarioPrefeitura"
                    ></v-text-field>
                    <v-checkbox
                      class="col-3"
                      v-show="!tutor_view_checkbox"
                      v-model="tutor_view"
                      label="Exibir para tutor"
                      v-if="!usuarioPrefeitura"
                    ></v-checkbox>
                    <v-btn
                      fab
                      class="mx-2 w-5 h-5"
                      dark
                      small
                      color="#3e682a"
                      :loading="uploading"
                      @click="addDocumento"
                      title="Salvar arquivo"
                      v-if="!usuarioPrefeitura"
                    >
                      <v-icon dark> fas fa-upload </v-icon>
                    </v-btn>
                  </div>
                </div> -->
                <div class="container">
                  <div class="row flex justify-content-end">
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Pesquisar"
                      single-line
                      hide-details
                      class="col-4"
                    />
                  </div>
                </div>
                <!-- DIALOG DE EXCLUSÃO-->
                <v-dialog v-model="dialogDelete" max-width="500px">
                  <v-card>
                    <v-card-title class="headline" style="word-break: normal"
                      >Tem certeza de que deseja excluir este documento?
                    </v-card-title>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="#3E682A" text @click="closeDelete"
                        >Cancelar</v-btn
                      >
                      <v-btn
                        class="btn-danger"
                        text
                        style="color: #fff !important"
                        @click="deleteItemConfirm"
                        >Deletar</v-btn
                      >
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.data_criacao="{ item }">
              {{
                item.data_criacao
                  ? item.data_criacao
                      .slice(0, 10)
                      .split("-")
                      .reverse()
                      .join("/") +
                    " - " +
                    item.data_criacao.slice(10)
                  : ""
              }}
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon
                class="mr-2"
                small
                @click="donwloadItem(item)"
                title="Baixar arquivo"
              >
                fas fa-download
              </v-icon>
              <v-icon
                class="mr-2"
                small
                @click="deleteItem(item)"
                title="Deletar arquivo"
                v-if="!usuarioPrefeitura"
              >
                mdi-delete
              </v-icon>
            </template>
            <template v-slot:no-data>
              <p class="text-center">Nenhum registro encontrado</p>
              <v-btn class="btn-style-green" @click="atualizarArquivos">
                Atualizar
              </v-btn>
            </template>
          </v-data-table>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import DocumentoService from "@/services/documentos_service.js";
import Mixin from "@/mixins/vuex_mixin.js";
import vuex_snackbar_mixin from "@/mixins/vuex_snackbar_mixin.js";

export default {
  mixins: [Mixin, vuex_snackbar_mixin],

  props: {
    arquivos: Array,
    paciente: Object,
  },
  mounted() {
    if (this.$_GETTER_perfil.id == 6) {
      this.usuarioPrefeitura = true;
    }
    this.atualizarArquivos();
  },
  data: () => ({
    usuarioPrefeitura: false,
    uploading: false,
    obs: "",
    tutor_view: true,
    file: "",
    campoArquivo: "",
    dialogDelete: false,
    listArquivos: [],
    editedfile: "",
    search: "",
    totalList: 10,
    options: {
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
      ordenacao: false,
      desc: false,
    },
    tutor_view_checkbox: JSON.parse(sessionStorage.vuex).perfil.id === 4,
    headers: [
      {
        text: "Nome",
        align: "start",
        sortable: true,
        value: "descricao",
      },
      {
        text: "Data - Hora de Envio",
        align: "start",
        sortable: false,
        value: "data_criacao",
      },
      { text: "Ações", align: "end", value: "actions", sortable: false },
    ],
    rules: [
      (value) =>
        !value || value.size < 18874370 || "O arquivo deve ter até 18MB!",
      // {
      //   required: (value) => !!value || "Campo obrigatório",
      // },
      // {
      //   size: (value) =>
      //     !value || value.size < 18874368.01 || "arquivo pode conter até 18mb!",
      // },
    ],
    loading: false,
  }),

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
    options: {
      handler() {
        this.options.desc = this.options.sortDesc[0] ?? false;
        this.atualizarArquivos();
      },
      deep: true,
    },
    search() {
      this.atualizarArquivos();
    },
    paciente() {
      this.atualizarArquivos();
    },
  },

  methods: {
    selectFile() {
      this.file = document.querySelector("input[type=file]").files[0];
    },
    atualizarArquivos() {
      const documentoService = new DocumentoService();
      this.loading = true;
      this.listArquivos = [];
      documentoService.buscar_arquivos(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.totalList = body.data.pagination.num_rows;
              this.listArquivos = body.data.arquivo;
            } else {
              console.log(body);
            }
          },
          onError: (error) => {
            console.log(error);
          },
          onEnd: () => {
            this.loading = false;
          },
        },
        this.paciente.id_paciente,
        this.paciente.id_pet,
        this.options.page,
        this.options.itemsPerPage,
        this.search,
        this.options.ordenacao,
        this.options.desc
      );
    },

    addDocumento() {
      if (this.campoArquivo.length < 1) {
        this.$_ACTIONS_showSnackbarMessage({
          message: "Escolha um arquivo para fazer o upload.",
          color: "error",
        });
        return;
      }

      if (!this.obs) {
        this.$_ACTIONS_showSnackbarMessage({
          message: "O campo descrição é obrigatório.",
          color: "error",
        });
        const descricao = document.getElementById("descricao");
        descricao.focus();
        setTimeout(() => {
          descricao.blur();
        }, 1);
        return;
      }

      const documentoService = new DocumentoService();
      this.uploading = true;
      // this.dialogLoading = true;
      var formdata = new FormData();
      const descricao = this.obs;

      // formdata.append("arquivo", this.file);
      formdata.append("arquivo", this.campoArquivo);
      formdata.append("id_paciente", this.paciente.id_paciente);
      formdata.append("id_pet", this.paciente.id_pet);
      formdata.append("id_clinica", JSON.parse(sessionStorage.vuex).unidade.id);
      formdata.append("descricao", descricao);
      formdata.append(
        "id_medico",
        JSON.parse(sessionStorage.vuex).usuario.dados.medicoId
      );
      formdata.append("tutor_view", this.tutor_view == true ? 1 : 0);

      documentoService.register(
        {
          onSucess: (status) => (body) => {
            // if (status === 200) {
            //   this.atualizarArquivos();
            //   this.$_ACTIONS_showSnackbarMessage({
            //     message: body.message,
            //     color: "sucess",
            //   });
            //               this.file = null;
            // this.campoArquivo = "";
            // this.obs = "";
            // }
            if (status === 200) {
              this.atualizarArquivos();
              this.$_ACTIONS_showSnackbarMessage({
                message: body.message,
                color: "sucess",
              });
              this.campoArquivo = "";
              this.file = null;
              this.obs = "";
            } else if (status === 428) {
              this.$_ACTIONS_showSnackbarMessage({
                message: body.message,
                color: "error",
              });
            } else {
              this.$_ACTIONS_showSnackbarMessage({
                message: body.message,
                color: "error",
              });
            }
          },
          onError: (error) => {
            console.log(error);
          },
          onEnd: () => {
            this.uploading = false;
          },
        },
        formdata
      );
    },
    deleteItem(item) {
      this.editedfile = item.id;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      const documentoService = new DocumentoService();
      this.loading = true;
      documentoService.deletar_arquivo(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.$_ACTIONS_showSnackbarMessage({
                message: body.message,
                color: "sucess",
              });
              this.atualizarArquivos();
            } else {
              console.log(body);
            }
          },
          onError: (error) => {
            console.log(error);
          },
          onEnd: () => {
            this.dialogDelete = false;
            this.loading = false;
          },
        },
        this.editedfile
      );
    },

    closeDelete() {
      this.dialogDelete = false;
    },

    donwloadItem(item) {
      const documentoService = new DocumentoService();
      this.loading = true;
      documentoService.ver_arquivo(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              var file = document.createElement("a"); //Create <a>
              const listFilesApplication = ["pdf", "doc", "docx"];
              const ext = listFilesApplication.includes(body.data.ext)
                ? "application/"
                : "image/";
              file.href =
                "data:" + ext + body.data.ext + ";base64," + body.data.arquivo;
              file.download = body.data.descricao + "." + body.data.ext;
              file.click();
            } else {
              console.log(body);
            }
          },
          onError: (error) => {
            console.log(error);
          },
          onEnd: () => {
            this.loading = false;
          },
        },
        item.id
      );
    },
    validarBtn() {
      if (
        !this.campoArquivo ||
        this.campoArquivo.size > 18874368 ||
        this.obs.trim().length < 3 ||
        this.uploading
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style scoped>
.btn-style-green {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #1daf80 !important;
  height: 30px !important;
  font-size: 15 px;
  padding: 10px !important;
  border-radius: 50px;
  margin: 20px auto;
}
</style>
