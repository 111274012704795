<template>
  <div>
    <div class="row mt-8 justify-center">
      <div class="col-10">
        <v-card>
          <div class="headerStyle py-2 px-5 mb-4">
            <p class="text-center fontStyle">Pet</p>
          </div>
          <div class="d-flex justify-content-between px-5 pb-3">
            <div><strong>Nome:</strong> {{ petFile.nome }}</div>
            <div>
              <strong>Nascimento:</strong> {{ petFile.datadenascimento }}
            </div>
            <div><strong>Cadastro:</strong> {{ petFile.datadecadastro }}</div>
          </div>
        </v-card>
      </div>
    </div>
    <div class="row mt-8 justify-center">
      <div class="col-10">
        <v-data-table
          :headers="headers"
          :items="listArquivos"
          :items-per-page="5"
          class="elevation-1"
          v-if="listArquivos.length > 0"
        >
          <template v-slot:item.actions="{ item }">
            <v-icon
              class="mr-2"
              small
              @click="donwloadItem(item)"
              title="Baixar arquivo"
            >
              fas fa-download
            </v-icon>
          </template>
        </v-data-table>
        <div v-else>
          <h4 class="text-center">Pet sem Arquivos</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DadosCadastraisMixin from "@/mixins/dados_cadastrais_mixin.js";
import LegacyVetusService from "../../services/legacy_vetus";
import { global } from "@/config/constants";

export default {
  mixins: [DadosCadastraisMixin],
  props: {
    petFile: Object,
    listArquivos: Object,
  },
  data() {
    return {
      services: {
        legacyVetusService: LegacyVetusService.build(),
      },
      loading: true,
      headers: [
        {
          text: "Nome",
          align: "start",
          sortable: true,
          value: "descricao",
        },
        { text: "Ações", align: "end", value: "actions", sortable: false },
      ],
      // Listas
      // listArquivos: [],
    };
  },
  mounted() {
    // this.getListFile();
  },
  computed: {},
  methods: {
    donwloadItem(item) {
      const url = global.api.baseURLVetus.replaceAll("/api", "/storage/");
      window.open(url + item.fullpath);
    },

    async getListFile() {
      this.loading = true;
      await this.services.legacyVetusService
        // .getFilebyPetID(474217)
        .getFilebyPetID(this.petFile.id)
        .then(async (response) => {
          const data = await response.json();
          this.listArquivos = data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.form-group label {
  text-align: left !important;
  display: block;
}

.form-group .label-genero {
  margin-right: 1rem;
}

.headerStyle {
  background-color: #e0e0e0;
}

.fontStyle {
  font-weight: bolder;
  margin-bottom: 0px;
  font-size: 1.1rem;
}
</style>
