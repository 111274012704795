<template>
  <div>
    <div class="p-lg-3">
      <div class="row justify-content-between">
        <h3 class="mt-0 mb-2 font-weight-bold text-left">
          Total a Pagar: R$ {{ sumComandas }}
        </h3>
      </div>
      <div class="column justify-content-center">
        <v-container>
          <v-row v-for="c in count" :key="c">
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="pagamento[c - 1].valor"
                  label="Valor"
                  prefix="R$"
                  @change="discount()"
                  hide-details
                  single-line
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="mt-4">
                <v-select
                  :disabled="pagamento[c - 1].valor <= 0"
                  @input="select($event, c)"
                  :items="formasdePagamento"
                  label="Forma de pagamento"
                  dense
                  v-model="pagamento[c - 1].forma_id"
                >
                </v-select>
              </v-col>
            </v-row>
          </v-row>
        </v-container>
        <v-row justify="center" class="mt-5">
          <v-btn
            class="mr-5"
            color="#3E682A"
            dark
            @click="addPayment"
            v-if="!(sumComandas <= 0)"
            >Adicionar Nova Forma de pagamento</v-btn
          >
          <v-btn color="#3E682A" @click="finishPayment" dark v-else
            >Finalizar Pagamento</v-btn
          >
          <v-btn color="#3E682A" class="ml-5" dark @click="removePayment"
            >Remover Forma de Pagamento</v-btn
          >
        </v-row>
      </div>
    </div>
    <v-dialog
      v-model="dialogCodigoAuth"
      v-if="dialogCodigoAuth"
      persistent
      :max-width="pagamento[pagamento.length - 1].forma_id === '9' ? 700 : 500"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2 mb-5">
          Pagamento
        </v-card-title>
        <div
          v-if="pagamento[pagamento.length - 1].forma_id === '9'"
          class="m-auto border"
          style="width: 80%"
        >
          <h3>Créditos do cliente</h3>
          <v-simple-table class="m-auto ">
            <template v-slot:default>
              <thead>
                <tr>
                  <th></th>
                  <th>
                    Id
                  </th>
                  <th>
                    Valor
                  </th>
                  <th>
                    Pagar
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="credito in creditosCliente.creditos"
                  :key="credito.id"
                >
                  <td class="text-left">
                    <v-checkbox
                      @change="handleCheckCredito($event, credito)"
                      v-model="checkCredito[credito.id]"
                    ></v-checkbox>
                  </td>
                  <td class="text-left">{{ credito.id }}</td>
                  <td class="text-left">
                    <b>
                      {{
                        new Intl.NumberFormat("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        }).format(creditos[credito.id].valor)
                      }}
                    </b>
                  </td>
                  <td class="text-left col-3">
                    <v-text-field
                      type="number"
                      @change="handleCreditoValor($event, credito)"
                      prefix="R$"
                      :rules="[
                        rules.maxValueCreditoCliente(credito.valor),
                        rules.minValueCreditoCliente,
                      ]"
                      :value="credito.valor"
                      @blur="handleCreditoValor($event.target.value, credito)"
                      :disabled="checkCredito[credito.id] ? false : true"
                    ></v-text-field>
                  </td>
                  <td class="text-left">
                    <v-checkbox
                      label="Gerar novo crédito"
                      v-if="checkCredito[credito.id]"
                      v-model="checkCreditoCliente[credito.id].gerar_credito"
                    ></v-checkbox>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
        <v-text-field
          class="mx-5 pt-5"
          label="Bandeira do cartão"
          required
          v-model="bandeira"
          v-if="isCard"
        >
        </v-text-field>
        <v-text-field
          v-model="pagamento[pagamento.length - 1].dinheiro.valor"
          v-if="pagamento[pagamento.length - 1].forma_id === '7'"
          label="Valor"
          prefix="R$"
          @input="charge()"
          hide-details
          single-line
          type="number"
          class="mx-auto pt-5"
          style="width: 80%"
        >
        </v-text-field>
        <!--v-text-field v-model="pagamento[pagamento.length - 1].dinheiro.troco"
                    v-if="pagamento[pagamento.length - 1].forma_id === '7'" label="Troco" prefix="R$"
                    hide-details single-line type="number" class="mx-5 pt-5 mb-5" readonly>
                </v-text-field-->
        <v-text-field
          v-model="pagamento[pagamento.length - 1].pix.chave"
          v-if="pagamento[pagamento.length - 1].forma_id === '3'"
          label="Chave"
          @change="discount()"
          hide-details
          single-line
          class="mx-5 pt-5"
        >
        </v-text-field>

        <v-text-field
          class="mx-5"
          label="Titular"
          required
          v-model="pagamento[pagamento.length - 1].card.titular"
          v-if="
            pagamento[pagamento.length - 1].forma_id === '1' ||
              pagamento[pagamento.length - 1].forma_id === '2'
          "
        >
        </v-text-field>
        <v-text-field
          class="mx-5"
          label="Número do cartão"
          required
          v-model="pagamento[pagamento.length - 1].card.number"
          v-if="
            pagamento[pagamento.length - 1].forma_id === '1' ||
              pagamento[pagamento.length - 1].forma_id === '2'
          "
          @change="verificaBandeira"
        >
        </v-text-field>
        <v-row class="mx-1">
          <v-text-field
            class="mx-5"
            label="Mês de vencimento"
            required
            v-model="pagamento[pagamento.length - 1].card.mes_val"
            v-if="
              pagamento[pagamento.length - 1].forma_id === '1' ||
                pagamento[pagamento.length - 1].forma_id === '2'
            "
          >
          </v-text-field>
          <v-text-field
            class="mx-5"
            label="Ano de vencimento"
            required
            v-model="pagamento[pagamento.length - 1].card.ano_val"
            v-if="
              pagamento[pagamento.length - 1].forma_id === '1' ||
                pagamento[pagamento.length - 1].forma_id === '2'
            "
          >
          </v-text-field>
        </v-row>
        <v-row class="mx-1">
          <v-text-field
            class="mx-5"
            label="Código de autorização"
            required
            v-model="pagamento[pagamento.length - 1].card.codauth"
            v-if="
              pagamento[pagamento.length - 1].forma_id === '1' ||
                pagamento[pagamento.length - 1].forma_id === '2'
            "
          >
          </v-text-field>
          <v-text-field
            class="mx-5"
            label="CVV"
            required
            v-model="pagamento[pagamento.length - 1].card.cvv"
            v-if="
              pagamento[pagamento.length - 1].forma_id === '1' ||
                pagamento[pagamento.length - 1].forma_id === '2'
            "
          >
          </v-text-field>
        </v-row>
        <v-text-field
          class="mx-5"
          label="Maquineta"
          required
          v-model="pagamento[pagamento.length - 1].card.maquineta"
          v-if="
            pagamento[pagamento.length - 1].forma_id === '1' ||
              pagamento[pagamento.length - 1].forma_id === '2'
          "
        >
        </v-text-field>
        <div class="my-5">
          <textarea
            rows="4"
            class="mt-5 p-2 border"
            style="width: 80%"
            placeholder="Observações"
            maxlength="400"
            v-model="pagamento[pagamento.length - 1].observacao"
          ></textarea>
        </div>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            class="mb-2"
            type="number"
            @click="dialogCodigoAuth = false"
          >
            Fechar
          </v-btn>
          <v-btn
            color="success"
            class="mb-2"
            type="number"
            @click="marcarPago(codAuth, bandeira)"
            v-if="isCard"
            :disabled="!codAuth"
          >
            Marcar Pago
          </v-btn>
          <v-btn
            color="success"
            class="mb-2"
            type="number"
            @click="marcarPago()"
            v-else
          >
            Marcar Pago
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<style scoped>
.v-text-field__details {
  display: none !important;
}
</style>
<script>
import Mixin from "@/mixins/vuex_mixin.js";
// import ComandaService from "@/services/comanda_service.js";
//import FormaPagamentoService from "../../services/forma_pagamento_service";
import VuexSnackbarMixin from "@/mixins/vuex_snackbar_mixin.js";
import FormaPagamentoService from "../../services/forma_pagamento_service";
import CreditoClienteService from "../../services/credito_cliente_service";

export default {
  mixins: [Mixin, VuexSnackbarMixin],
  data: () => ({
    sumComandas: 0,
    isCard: false,
    isCreditCard: false,
    dialogCodigoAuth: false,
    sumComandasInitial: 0,
    count: 1,
    valor: 0,
    pagamento: [
      {
        valor: 0,
        forma_id: 0,
        observacao: "",
        card: {},
        dinheiro: {},
        pix: {},
      },
    ],
    formasdePagamento: [],
    comandasItens: [],
    formaPagamentoService: FormaPagamentoService.build(),
    formaPagamentoSelecionada: {},
    paymentInfo: {
      card: {},
      dinheiro: {},
      pix: {},
    },
    creditosCliente: [],
    checkCreditoCliente: [],
    rules: {
      maxValueCreditoCliente(maxValue) {
        return (value) =>
          value <= parseFloat(maxValue) || "Valor acima do crédito";
      },
      minValueCreditoCliente(value) {
        return value > 0 || "Valor inválido";
      },
    },
    creditos: [],
    creditoInvalido: [],
    checkCredito: [],
    checkGerarNovoCredito: [],
  }),
  props: {
    comandas: {
      type: Array,
      required: true,
    },
  },
  watch: {
    checkCreditoCliente() {
      console.log(this.checkCreditoCliente);
    },
  },
  updated() {
    this.pagamento[this.pagamento.length - 1].dinheiro.troco =
      this.pagamento[this.pagamento.length - 1].forma_id == 7
        ? this.pagamento[this.pagamento.length - 1].dinheiro.valor -
          this.pagamento[this.pagamento.length - 1].valor
        : 0;
  },
  async mounted() {
    for (let i = 0; i < this.comandas.length; i++) {
      this.sumComandas = this.sumComandas + parseFloat(this.comandas[i].valor);
    }
    this.sumComandasInitial = this.sumComandas;
    this.getFormasPagamento();
    this.comandasItens.map((item) => {
      if (item.status == 2) {
        this.formaPagamentoSelecionada = Object.assign(
          this.formaPagamentoSelecionada,
          { [item.comanda_item_id]: item.id_forma_pagamento }
        );
      }
    });
    this.paymentInfo.comanda_id = this.comandas[0].comanda_id;
    this.paymentInfo.comanda_itens = this.comandas.map((c) => {
      return { comanda_item_id: c.comanda_item_id };
    });
    this.getCreditoCliente();
  },
  computed: {},
  methods: {
    handleCheckCredito(event, credito) {
      if (event) {
        this.checkCreditoCliente[credito.id] = {
          valorUsado: parseFloat(credito.valor),
          valorCredito: parseFloat(credito.valor),
          id: credito.id,
          check: event,
          gerar_credito: true,
        };
      } else {
        this.checkCreditoCliente.splice(credito.id, 1);
      }
    },
    handleCreditoValor(value, credito) {
      const valorCredito = parseFloat(credito.valor);
      const valor = value ? parseFloat(value) : valorCredito;
      if (this.checkCreditoCliente[credito.id]) {
        this.checkCreditoCliente[credito.id].valorUsado = valor;
      }
    },
    async getCreditoCliente() {
      const creditoClienteService = new CreditoClienteService();
      const unidadeId = JSON.parse(sessionStorage.getItem("vuex")).unidade.id;
      const clienteId = this.comandas[0].cliente_id;

      const credito = await creditoClienteService.getCreditosCliente(
        unidadeId,
        clienteId
      );
      this.creditosCliente = credito.data;

      this.creditosCliente.creditos.forEach((item) => {
        this.creditos[item.id] = item;
      });

      console.log(this.creditos);

      console.log(this.creditosCliente);
    },
    charge() {
      console.log(this.pagamento[this.pagamento.length - 1].dinheiro.troco);
      this.pagamento[this.pagamento.length - 1].dinheiro.troco =
        this.pagamento[this.pagamento.length - 1].dinheiro.valor -
        this.pagamento[this.pagamento.length - 1].valor;
      console.log(this.pagamento[this.pagamento.length - 1].dinheiro.troco);
    },
    async finishPayment() {
      console.log(this.pagamento);
      for (let i = 0; i < this.pagamento.length; i++) {
        this.pagamento[i].valor = parseFloat(
          this.pagamento[i].valor
        ).toString();
        this.pagamento[i].forma_id = parseInt(this.pagamento[i].forma_id);

        if (this.pagamento[i].forma_id != 7) {
          this.pagamento[i].dinheiro.troco = 0;
        }
      }
      this.paymentInfo.valor_total = this.sumComandasInitial.toString();
      this.paymentInfo.formas_pagamento = this.pagamento;
      console.log(this.paymentInfo);
      const response = await this.formaPagamentoService.pagamentoLivre(
        this.paymentInfo
      );
      console.log(response.status);
      if (response.status === 200) {
        this.$_ACTIONS_showSnackbarMessage({
          message: "Item Pago",
          color: "sucess",
        });
        this.$emit("close");
      } else {
        this.$_ACTIONS_showSnackbarMessage({
          message: "Ocorreu um erro interno. Tente novamente mais tarde",
          color: "error",
        });
      }
    },
    verificaBandeira() {
      let cardnumber = this.pagamento[this.pagamento.length - 1].number.replace(
        /[^0-9]+/g,
        ""
      );

      let cards = {
        visa: /^4[0-9]{12}(?:[0-9]{3})/,
        mastercard: /^5[1-5][0-9]{14}/,
        diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
        amex: /^3[47][0-9]{13}/,
        discover: /^6(?:011|5[0-9]{2})[0-9]{12}/,
        hipercard: /^(606282\d{10}(\d{3})?)|(3841\d{15})/,
        elo: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/,
        jcb: /^(?:2131|1800|35\d{3})\d{11}/,
        aura: /^(5078\d{2})(\d{2})(\d{11})$/,
      };

      for (let flag in cards) {
        if (cards[flag].test(cardnumber)) {
          console.log(flag);
          this.pagamento[this.pagamento.length - 1].bandeira = flag;
        }
      }

      return false;
    },
    select(e, c) {
      console.log(e);
      this.pagamento[c - 1].dinheiro.valor = 0;
      this.pagamento[c - 1].dinheiro.troco = 0;
      this.pagamento[c - 1].pix.chave = "";
      this.pagamento[c - 1].card.number = "";
      this.pagamento[c - 1].card.bandeira = "";
      this.pagamento[c - 1].card.mes_val = "01";
      this.pagamento[c - 1].card.ano_val = "2023";
      this.pagamento[c - 1].card.titular = "";
      this.pagamento[c - 1].card.codauth = "";
      this.pagamento[c - 1].card.maquineta = "";
      this.pagamento[c - 1].card.cvv = "";
      this.pagamento[c - 1].card.debcred =
        this.pagamento[c - 1].forma_id === "1" ? "credito" : "debito";
      this.dialogCodigoAuth = true;
    },
    removePayment() {
      if (this.count > 1) {
        this.count -= 1;
        this.pagamento.pop();
        this.sumComandas = this.sumComandasInitial;
        for (let i = 0; i < this.pagamento.length; i++) {
          this.sumComandas -= parseFloat(this.pagamento[i].valor);
        }
      }
    },
    addPayment() {
      if (
        this.pagamento[this.pagamento.length - 1].valor !== 0 &&
        this.pagamento[this.pagamento.length - 1].valor !== undefined &&
        this.pagamento[this.pagamento.length - 1].forma_id !== 0
      ) {
        this.pagamento.push({
          valor: 0,
          forma_id: 0,
          observacao: "",
          card: {},
          dinheiro: {},
          pix: {},
        });
        this.count += 1;
      }
    },
    async setComandaItens() {
      this.loading = true;
      const response = await this.comandaService.getComandaItensByComandaId(
        this.comanda.comanda_id
      );
      this.comandasItens = response.map((item) => {
        return {
          ...item,
          procedimentoDescricao: item.procedimento_descricao,
          tipoProcedimentoDescricao: item.tipo_procedimento_descricao,
          valor: parseFloat(item.valor).toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          }),
          convenioDescricao: item.tipo_convenio_descricao,
          statusDescricao: item.status_descricao,
          isPago: item.status == 2,
          dataCriado: new Date(
            item.data_criada + " " + item.hora_criada
          ).toLocaleString("pt-BR"),
        };
      });
      this.loading = false;
    },
    newFields() {
      if (this.sumComandas <= 0) {
        return null;
      }
      this.count += 1;
    },
    selecionarFormaPagamento(event, id) {
      this.formaPagamentoSelecionada = Object.assign(
        this.formaPagamentoSelecionada,
        { [id]: event }
      );
    },
    discount() {
      this.sumComandas = this.sumComandasInitial;
      if (this.pagamento[this.pagamento.length - 1].valor === "") {
        this.pagamento[this.pagamento.length - 1].valor = 0;
      }
      for (let i = 0; i < this.pagamento.length; i++) {
        this.sumComandas -= parseFloat(this.pagamento[i].valor);
      }
      if (this.sumComandas < 0) {
        this.pagamento[this.pagamento.length - 1].valor =
          parseFloat(this.pagamento[this.pagamento.length - 1].valor) +
          this.sumComandas;
        this.sumComandas = this.sumComandasInitial;
        for (let i = 0; i < this.pagamento.length; i++) {
          this.sumComandas -= parseFloat(this.pagamento[i].valor);
        }
      }
    },
    async getFormasPagamento() {
      this.formasdePagamento = await this.formaPagamentoService
        .getFormaPagamentoPaginatedList()
        .then((resp) => resp.json())
        .then((resp) => {
          console.log(resp);
          return resp.data.forma_pagamento
            .filter((item) => item.desativado_em == null)
            .map((item) => {
              return {
                text: item.descricao,
                value: item.id,
                codauth: item.codauth,
              };
            });
        });
    },
    codigoAuth(item) {
      let payMethod = 0;

      for (let prop in this.formaPagamentoSelecionada) {
        if (prop === item.comanda_item_id) {
          payMethod = this.formaPagamentoSelecionada[prop];
        }
      }
      if (payMethod === "1") {
        this.parcelas = 1;
        this.isCreditCard = true;
      } else {
        this.parcelas = 0;
        this.isCreditCard = false;
      }
      localStorage.setItem("itemComanda", JSON.stringify(item));

      const auth = this.formasdePagamento.filter((formPag) => {
        return (
          formPag.id == this.formaPagamentoSelecionada[item.comanda_item_id]
        );
      })[0].codauth;

      if (auth == "S") {
        this.isCard = true;
        this.dialogCodigoAuth = true;
      } else {
        this.isCard = false;
        this.codAuth = 0;
        this.bandeira = 0;
        this.dialogCodigoAuth = true;
      }
    },
    async marcarPago() {
      if (this.pagamento[this.pagamento.length - 1].forma_id === "9") {
        this.creditoInvalido = this.checkCreditoCliente.filter((credito) => {
          return (
            credito.valorUsado > credito.valorCredito || credito.valorUsado <= 0
          );
        });

        const valorCreditoTotal = this.checkCreditoCliente.reduce(
          (acc, cur) => acc + cur.valorUsado,
          0
        );
        if (
          valorCreditoTotal !=
          parseFloat(this.pagamento[this.pagamento.length - 1].valor)
        ) {
          this.$_ACTIONS_showSnackbarMessage({
            message: "Valor diferente do valor definido para pagamento",
            color: "error",
          });
          return;
        }

        if (
          this.checkCreditoCliente.length == 0 ||
          this.creditoInvalido.length > 0
        ) {
          this.$_ACTIONS_showSnackbarMessage({
            message: "Valor de crédito inválido",
            color: "error",
          });
          return;
        }

        const pagamentoCreditoCliente = this.checkCreditoCliente
          .filter((item) => item.valorUsado > 0)
          .map((credito) => {
            return {
              credito_id: credito.id,
              valor_total: credito.valorCredito,
              valor_usado: credito.valorUsado,
              gerar_credito:
                credito.gerar_credito &
                (credito.valorCredito > credito.valorUsado)
                  ? "S"
                  : "N",
            };
          });

        // console.log(pagamentoCreditoCliente);
        this.pagamento[
          this.pagamento.length - 1
        ].creditocliente = pagamentoCreditoCliente;
        console.log(this.pagamento);
      }

      if (this.pagamento[this.pagamento.length - 1].forma_id === "7") {
        if (
          this.pagamento[this.pagamento.length - 1].dinheiro.valor === 0 ||
          parseFloat(this.pagamento[this.pagamento.length - 1].dinheiro.valor) <
            parseFloat(this.pagamento[this.pagamento.length - 1].valor) ||
          this.pagamento[this.pagamento.length - 1].dinheiro.valor === ""
        ) {
          this.$_ACTIONS_showSnackbarMessage({
            message: "Valores incompativeis",
            color: "error",
          });
          return null;
        }
        this.pagamento[this.pagamento.length - 1].dinheiro.troco =
          this.pagamento[this.pagamento.length - 1].dinheiro.valor -
          this.pagamento[this.pagamento.length - 1].valor;
        this.$_ACTIONS_showSnackbarMessage({
          message: `Troco a ser devolvido: R$ ${
            this.pagamento[this.pagamento.length - 1].dinheiro.troco
          }`,
          color: "sucess",
        });
      }
      if (this.pagamento[this.pagamento.length - 1].forma_id === "3") {
        if (this.pagamento[this.pagamento.length - 1].pix.chave === "") {
          return null;
        }
      }
      if (
        this.pagamento[this.pagamento.length - 1].forma_id === "1" ||
        this.pagamento[this.pagamento.length - 1].forma_id === "2"
      ) {
        if (
          this.pagamento[this.pagamento.length - 1].card.maquineta === "" ||
          this.pagamento[this.pagamento.length - 1].card.codAuth === ""
        ) {
          this.$_ACTIONS_showSnackbarMessage({
            message: "Maquineta e Coódigo de Autorização são obrigatórios",
            color: "error",
          });
          return null;
        }
      }
      this.dialogCodigoAuth = false;
    },
    excluirItemComanda(item) {
      this.loading = true;
      this.comandaService
        .excluirItemComanda({
          comandaId: item.comanda_id,
          comandaItemId: item.comanda_item_id,
        })
        .finally(() => {
          this.loading = false;
          this.setComandaItens();
        })
        .catch(() => {
          this.$_ACTIONS_showSnackbarMessage({
            message: this.$global.messages.internalServerError,
            color: "error",
          });
        });
    },
  },
};
</script>
