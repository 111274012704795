<template>
  <div>
    <div class="p-lg-3">
      <div class="row justify-content-between">
        <h3 class="mt-0 mb-2 font-weight-bold text-left">Internação</h3>
      </div>
    </div>
    <v-row class="mt-2 px-2" align="center">
      <v-select
        :items="unidades"
        label="Unidade"
        item-value="id"
        v-model="unidadeSelected"
        outlined
        class="col-3"
      ></v-select>
      <v-select
        :items="filtrosInternacao"
        label="Filtrar"
        item-value="id"
        v-model="filtroInternacao"
        outlined
        class="col-3 ml-2"
      ></v-select>
      <v-btn class="bg-verde mt-n6 ml-4" dark rounded>Buscar</v-btn>
    </v-row>
    <div>
      <v-data-table
        :headers="headersInternacao"
        :items="internacaoList"
        class="elevation-1 mt-5"
      >
        <template v-slot:item.data_criacao="{ item }">
          {{
            item.data_criacao
              .split("-")
              .reverse()
              .join("/")
          }}
        </template>
        <template v-slot:item.status="{ item }">
          {{ item.status == 1 ? "Internado" : "Alta Médica" }}
        </template>
        <template v-slot:item.actions="{ item }">
          <div class="d-flex items-center">
            <v-btn
              class="p-2 mr-2"
              text
              color="success"
              rounded
              x-small
              @click="dialogRequisicao = true"
            >
              Requisições
            </v-btn>

            <v-btn
              x-small
              rounded
              color="warning"
              class="p-2"
              @click="showDialogConfirmarAlta()"
              v-if="item.status == 1"
            >
              Gerar Alta
            </v-btn>
          </div>

          <v-dialog
            v-model="dialogConfirmarAlta"
            v-if="dialogConfirmarAlta"
            width="400px"
          >
            <v-card class="p-1">
              <h3 class="p-3">Deseja gerar alta médica para este pet?</h3>
              <div class="pb-3 d-flex justify-space-around w-50 m-auto">
                <v-btn
                  color="error"
                  text
                  rounded
                  @click="dialogConfirmarAlta = false"
                  >Não</v-btn
                >
                <v-btn
                  class="bg-verde"
                  dark
                  rounded
                  @click="finalizarInternacao(item)"
                  >Sim</v-btn
                >
              </div>
            </v-card>
          </v-dialog>

          <v-dialog
            v-model="dialogRequisicao"
            v-if="dialogRequisicao"
            transition="dialog-bottom-transition"
            width="1200px"
          >
            <requisicao-modal
              v-if="dialogRequisicao"
              @close="dialogRequisicao = false"
              :petId="item.id_pet"
              :tutorId="item.id_cliente"
            >
            </requisicao-modal>
          </v-dialog>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import UnidadeService from "../services/unidade_service";
import RequisicaoModal from "@/components/recepcao_medico/requisicaoModal/index.vue";

export default {
  components: {
    RequisicaoModal,
  },
  data() {
    return {
      unidades: [],
      unidadeSelected: "",
      services: {
        unidadeService: UnidadeService.build(),
      },
      options: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 15,
        ordenacao: false,
        desc: false,
      },
      internacaoList: [
        {
          id: "256",
          nome_cliente: "Mestre dos Euleres",
          nome_pet: "Pet dos Euleres",
          id_cliente: "4700",
          id_pet: "50",
          data_criacao: "2023-05-01",
          nome_criador: "Dr. Zé",
          status: "1",
        },
        {
          id: "254",
          nome_cliente: "Ually Show",
          nome_pet: "Açaí",
          id_cliente: "4700",
          id_pet: "50",
          data_criacao: "2023-04-30",
          nome_criador: "Dra. Lux",
          status: "0",
        },
      ],
      headersInternacao: [
        {
          text: "ID",
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: "Cliente", value: "nome_cliente" },
        { text: "Pet", value: "nome_pet" },
        { text: "Data de entrada", value: "data_criacao" },
        { text: "Criado por", value: "nome_criador" },
        { text: "Status", value: "status" },
        { text: "", value: "actions" },
      ],
      filtrosInternacao: [
        { id: 0, text: "Internado" },
        { id: 1, text: "Alta Médica" },
      ],
      filtroInternacao: "",
      dialogConfirmarAlta: false,
      dialogRequisicao: false,
    };
  },
  mounted() {
    this.setUnidades();
  },
  methods: {
    setUnidades() {
      this.services.unidadeService
        .getUnidadeByUsuarioLogado()
        .then((response) => {
          return response.json();
        })
        .then((response) => {
          this.unidades = response.data.map((unidade) => {
            return { text: unidade.unidade, id: unidade.id_unidade };
          });
        })
        .then(() => {});
    },
    showDialogConfirmarAlta() {
      this.dialogConfirmarAlta = true;
    },
    finalizarInternacao(internacao) {
      console.log(internacao);
    },
  },
};
</script>

<style></style>
