<template>
  <div>
    <div class="p-5">
      <form class="p-lg-3 row">
        <div class="col-lg-4 form-group clearfix mb-3">
          <label>Nome Completo</label>
          <v-text-field
            v-model="nome"
            :rules="[rules.required]"
            :type="'text'"
            placeholder="Nome completo"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>

        <div class="col-lg-4 form-group clearfix mb-3">
          <label>Email</label>
          <v-text-field
            v-model="email"
            :rules="[rules.required, rules.email]"
            :type="'email'"
            placeholder="Escreva seu email"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>

        <div class="col-lg-4 form-group clearfix mb-3">
          <label>RG</label>
          <v-text-field
            v-model="rg"
            :rules="[rules.required]"
            placeholder="00.000.000-0"
            v-mask="'##.###.###-#'"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>

        <!-- <div class="col-lg-3 form-group clearfix mb-3">
          <label>Senha</label>
          <v-text-field
            v-model="senha"
            background-color="grey lighten-2"
            :type="'password'"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div> -->

        <div class="col-lg-3 form-group clearfix mb-3">
          <label>Telefone Celular:</label>
          <v-text-field
            v-mask="'(##)#####-####'"
            v-model="telefone_1"
            :type="'text'"
            placeholder="(99) 99999-9999"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>

        <div class="col-lg-3 form-group clearfix mb-3">
          <label>Telefone Fixo:</label>
          <v-text-field
            v-mask="'(##)####-####'"
            v-model="telefone_2"
            :type="'text'"
            placeholder="(99) 99999-9999"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>

        <div class="col-lg-3 form-group clearfix mb-3">
          <label>CPF</label>
          <v-text-field
            v-mask="'###.###.###-##'"
            v-model="cpf"
            :type="'text'"
            placeholder="000.000.000-00"
            style="padding-top: 0 !important"
          ></v-text-field>
          <span class="text-muted">ex: "123.456.7890-12"</span>
        </div>

        <div class="col-lg-3 form-group clearfix mb-3">
          <label>Data de nascimento</label>

          <v-text-field
            v-model="dataNascimento"
            clear-icon="clear"
            style="margin: 0 5px; padding-top: 5px !important"
            :type="'date'"
            outlined
          />

          <span class="text-muted">ex: "Dia/Mês/Ano"</span>
        </div>

        <div class="col-lg-3 form-group clearfix mb-3">
          <label class="">Gênero</label>
          <v-radio-group v-model="genero">
            <v-radio label="Masculino" value="M" />
            <v-radio label="Feminino" value="F" />
            <v-radio label="Outros" value="O" />
          </v-radio-group>
        </div>

        <div class="col-lg-3 form-group clearfix">
          <label>Estado civil</label>

          <v-select
            v-model="estadoCivil"
            style="padding-top: 2px !important"
            :items="estadoCivilList"
            outlined
          />
        </div>

        <div class="col-lg-3 form-group clearfix mb-3">
          <label>CEP</label>

          <v-text-field
            v-mask="'#####-###'"
            v-model="cep"
            @blur="searchCep"
            :type="'text'"
            placeholder="00000-000"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>

        <div class="col-lg-3 form-group clearfix mb-3">
          <label>Logradouro</label>

          <v-text-field
            v-model="logradouro"
            background-color="grey lighten-2"
            :type="'text'"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>

        <div class="col-lg-3 form-group clearfix mb-3">
          <label>Bairro</label>

          <v-text-field
            v-model="bairro"
            background-color="grey lighten-2"
            :type="'text'"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>

        <div class="col-lg-3 form-group clearfix mb-3">
          <label>Numero</label>

          <v-text-field
            v-model="numero"
            :type="'text'"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>

        <div class="col-lg-3 form-group clearfix mb-3">
          <label>Cidade</label>

          <v-text-field
            v-model="cidade"
            background-color="grey lighten-2"
            :type="'text'"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>

        <div class="col-lg-3 form-group clearfix mb-3">
          <label>Estado</label>
          <v-text-field
            v-model="estado"
            background-color="grey lighten-2"
            :type="'text'"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>

        <div class="col-lg-5 form-group clearfix mb-3">
          <label>Complemento</label>

          <v-text-field
            v-model="complemento"
            :type="'text'"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>

        <div class="col-lg-4 form-group mt-n5 clearfix">
          <label>Prefeitura</label>
          <v-autocomplete
            v-model="prefeituraSelecionada"
            :items="prefeituras"
            item-text="nome_fantasia"
            return-object
            :search-input.sync="prefeitura_search"
            label="Selecione a Prefeitura"
            no-data-text="Digite as três primeiras letras da prefeitura desejada"
            outlined
          ></v-autocomplete>
        </div>

        <div
          class="col-lg-2 form-group mt-n2 clearfix"
          style="padding-top: 0 !important"
        >
          <label>Status</label>
          <v-select
            outlined
            :items="statusList"
            item-text="descricao"
            item-value="id"
            v-model="status"
          />
        </div>

        <div class="col-md-12">
          <v-btn
            @click.prevent="editarUsuario"
            class="bg-verde"
            :dark="!loadingCadastro"
            :disabled="loadingCadastro"
            rounded
          >
            Salvar
          </v-btn>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import CEPMixin from "@/mixins/cep_mixin.js";
import Mixin from "@/mixins/vuex_mixin.js";
import PrefeituraService from "@/services/prefeitura_service";

export default {
  mixins: [CEPMixin, Mixin],
  data() {
    return {
      formCadastro: "",
      nome: "",
      email: "",
      telefone_1: "",
      telefone_2: "",
      cpf: "",
      rg: "",
      dataNascimento: "",
      genero: "",
      estadoCivil: "",
      profissao: "",
      indicado_por: "",
      cep: "",
      logradouro: "",
      bairro: "",
      numero: "",
      complemento: "",
      referencias: "",
      cidade: "",
      estado: "",
      senha: "",
      avatarAdministrativo: "",
      unidadeId: 0,
      statusList: [
        { descricao: "Ativo", id: "1" },
        { descricao: "Inativo", id: "2" },
      ],
      status: 1,
      id_perfil: "",
      usuario_id: "",
      estadoCivilList: ["Solteiro(a)", "Casado(a)", "Divorciado(a)"],
      prefeituras: [],
      prefeituraSelecionada: {},
      prefeitura_search: "",
      loadingCadastro: false,
      rules: {
        required: (value) => !!value || "Campo obrigatório.",
        min: (v) => v.length >= 8 || "Minimo de 8 caracteres",
        emailMatch: () => `O e-mail e a senha inseridos não correspondem`,
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Email inválido.";
        },
      },
      services: {
        prefeituraService: PrefeituraService.build(),
      },
    };
  },
  props: {
    usuarioPrefeitura: {
      require: true,
    },
  },
  watch: {
    prefeitura_search(nome) {
      if (nome) {
        this.setPrefeituras(nome);
      }
    },
    prefeituraSelecionada(value) {
      console.log(value);
    },
  },
  beforeMount() {
    this.formCadastro = document.forms[0];
    this.setUsuario();
  },
  methods: {
    searchCep() {
      this.$buscarCep(this, this.cep);
    },
    async setUsuario() {
      const resp = await this.services.prefeituraService
        .getUsuarioPrefeituraById(this.usuarioPrefeitura.usuario_id)
        .then((resp) => {
          if (resp.status == 200) {
            return resp.json();
          } else {
            return;
          }
        });

      if (resp) {
        const usuario = resp.data;
        this.bairro = usuario.bairro;
        this.cep = usuario.cep;
        this.cidade = usuario.cidade;
        this.cnpj = usuario.cnpj;
        this.complemento = usuario.complemento;
        this.referencias = usuario.referencias;
        this.cpf = usuario.cpf;
        this.dataNascimento = usuario.data_nascimento;
        this.descricao = usuario.descricao;
        this.email = usuario.email;
        this.estado = usuario.estado;
        this.estadoCivil = usuario.estado_civil;
        this.genero = usuario.genero;
        this.logradouro = usuario.logradouro;
        this.nome = usuario.nome;
        this.numero = usuario.numero;
        this.prefeitura = usuario.prefeitura;
        this.rg = usuario.rg;
        this.telefone_1 = usuario.telefone_1;
        this.telefone_2 = usuario.telefone_2;
        this.status = usuario.ativo;
        this.id_perfil = usuario.id_perfil;
        this.usuario_id = usuario.id;

        this.setPrefeituras(usuario.prefeitura, true);
      } else {
        this.$_ACTIONS_showSnackbarMessage({
          message: this.$global.messages.internalServerError,
          color: "error",
        });
      }
    },
    async setPrefeituras(nome, setPrefeitura = false) {
      if (nome.length > 3) {
        const responseFunctions = {
          onSucess: (status) => (body) => {
            if (status == 400) {
              console.log(body);
            }
            if (status == 200) {
              this.prefeituras = body.data.prefeituras;
              if (setPrefeitura) {
                this.prefeituraSelecionada = this.prefeituras[0];
              }
            }
          },
          onError: () => {
            this.$_ACTIONS_showSnackbarMessage({
              message: this.$global.messages.internalServerError,
              color: "error",
            });
          },
          onEnd: () => {},
        };

        const formData = new FormData();
        formData.append("nome", nome);

        await this.services.prefeituraService.listarPrefeiturasPorNome(
          responseFunctions,
          formData
        );
      }
    },
    async editarUsuario() {
      this.loadingCadastro = true;

      const formData = new FormData();

      formData.append("nome", this.nome);
      formData.append("email", this.email);
      formData.append("rg", this.rg);
      formData.append("genero", this.genero);
      formData.append("data_nascimento", this.dataNascimento);
      formData.append("estado_civil", this.estadoCivil);
      formData.append("cep", this.cep);
      formData.append("numero", this.numero);
      formData.append("complemento", this.complemento);
      formData.append("referencias", this.referencias);
      formData.append("cpf", this.cpf);
      formData.append("telefone_1", this.telefone_1);
      formData.append("telefone_2", this.telefone_2);
      formData.append("profissao", this.profissao);
      // formData.append("senha", this.senha);
      formData.append("ativo", this.status);
      formData.append("id_perfil", this.id_perfil);
      formData.append("cidade", this.cidade);
      formData.append("estado", this.estado);
      formData.append("bairro", this.bairro);
      formData.append("logradouro", this.logradouro);
      formData.append("id_prefeitura", this.prefeituraSelecionada.id);

      const responseFunctions = {
        onSucess: (status) => (body) => {
          if (status == 400) {
            const error = Object.values(body.errors)[0];
            this.$_ACTIONS_showSnackbarMessage({
              message: error,
              color: "error",
            });
          }
          if (status == 200) {
            this.$_ACTIONS_showSnackbarMessage({
              message: "Usuário editado com sucesso!",
              color: "sucess",
            });
          }
        },
        onError: () => {
          this.$_ACTIONS_showSnackbarMessage({
            message: this.$global.messages.internalServerError,
            color: "error",
          });
        },
        onEnd: () => {
          this.loadingCadastro = false;
          this.$emit("finalizarCadastro");
        },
      };

      await this.services.prefeituraService.editarUsuarioPrefeitura(
        responseFunctions,
        formData,
        this.usuario_id
      );
    },
  },
};
</script>

<style scoped>
.form-group label {
  text-align: left !important;
  display: block;
}

.form-group .label-genero {
  margin-right: 1rem;
}
</style>
