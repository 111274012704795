<template>
  <div>
    <div class="p-lg-3">
      <div class="row justify-content-between">
        <h3 class="mt-0 mb-2 font-weight-bold text-left">
          Atendentes - Recepção
        </h3>
      </div>
    </div>

    <v-data-table
      v-on:update:sort-by="options.ordenacao = true"
      :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }"
      :search="search"
      :headers="headers"
      :items="atendentes"
      :options.sync="options"
      :server-items-length="totalList"
      class="elevation-1"
      :loading="loading"
      :loading-text="'Carregando...'"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <!-- DIALOG DE CADASTRO -->
          <v-dialog
            v-model="dialogCadastro"
            max-width="500px"
            persistent
            fullscreen
            transition="dialog-bottom-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="#3E682A"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
                v-if="allowCadastrarBtn"
              >
                Novo Atendente
              </v-btn>
            </template>
            <v-card>
              <v-toolbar style="background-color: #3e682a; color: #fff">
                <v-btn icon dark @click="dialogCadastro = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>
                  <span class="headline">Cadastrar atendente</span>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items> </v-toolbar-items>
              </v-toolbar>
              <cadastro-novo-atendente
                v-if="dialogCadastro"
                @close="dialogCadastro = false"
                @response="atualizarListaAtendentes"
              />
            </v-card>
          </v-dialog>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <div class="d-flex mt-5">
            <!-- <v-select
              v-model="filtroStatus"
              :items="listStatus"
              item-text="label"
              item-value="id"
              class="mr-5 col-4"
            /> -->
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pesquisar"
              single-line
              hide-details
            ></v-text-field>
          </div>
          <!-- DIALOG DE EDIÇÃO -->
          <v-dialog
            v-model="dialog"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
          >
            <cadastro-edicao-atendente
              v-if="atendente_detalhe"
              :atendente="atendente_detalhe"
              :visualizar="visualizar"
              :key="render"
              @close="save($event)"
            />
          </v-dialog>
          <v-dialog
            v-model="dialogNovaSenha"
            width="500"
            transition="dialog-bottom-transition"
          >
            <nova-senha
              v-if="dialogNovaSenha"
              :infosUsuario="infoUsuario"
              @close="closeNovaSenha($event)"
            />
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          v-if="perfilId == 1"
          title="Alterar Senha"
          small
          class="mr-2"
          @click="novaSenha(item.id_usuario, item.nome)"
        >
          mdi-form-textbox-password
        </v-icon>
        <v-icon
          v-if="allowVisualizarBtn"
          small
          class="mr-2"
          @click="editItem(item, true)"
        >
          mdi mdi-eye
        </v-icon>
        <v-icon
          v-if="allowEditarBtn"
          small
          class="mr-2"
          @click="editItem(item, false)"
        >
          fas fa-edit
        </v-icon>
      </template>
      <template v-slot:no-data>
        <p class="text center">Nenhum registro encontrado</p>
        <v-btn color="#3E682A" dark @click="atualizarListaAtendentes">
          Atualizar
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import CadastroEdicaoAtendente from "@/components/atendentes/cadastro_edicao_atendente.vue";
import CadastroNovoAtendente from "@/components/atendentes/cadastro_novo_atendente.vue";
import NovaSenha from "@/components/alterar_senha/alterar_senha.vue";
import AtendenteService from "@/services/atendente_recepcao_service.js";
import AuthService from "../services/auth_service";
import endpoints from "../router/endpoints";

export default {
  components: {
    CadastroEdicaoAtendente,
    CadastroNovoAtendente,
    NovaSenha,
  },

  data: () => ({
    atendente_detalhe: {},
    render: 0,
    dialog: false,
    loading: false,
    search: "",
    totalList: 10,
    options: {
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
      ordenacao: false,
      desc: false,
    },
    dialogCadastro: false,
    atendente: {},
    visualizar: false,
    atendentes: [],
    headers: [
      {
        text: "Nome",
        align: "start",
        sortable: true,
        value: "nome",
      },
      { text: "CPF", value: "cpf", sortable: false },
      { text: "Perfil", value: "perfil_descricao", sortable: false },
      { text: "Email", value: "email", sortable: false },
      { text: "Status", value: "ativo", sortable: false },
      { text: "Ações", align: "end", value: "actions", sortable: false },
    ],
    editedIndex: -1,
    editedItem: {
      nome: "",
      cpf: "",
      email: "",
    },
    defaultItem: {
      nome: "",
      cpf: "",
      email: "",
    },
    services: {
      authService: AuthService.build(),
    },
    listStatus: [
      { id: 100, label: "Todos" },
      { id: 1, label: "Ativos" },
      { id: 2, label: "Inativos" },
    ],
    filtroStatus: 100,
    perfilId: JSON.parse(sessionStorage.getItem("vuex")).perfil.id,
    infoUsuario: {},
    dialogNovaSenha: false,
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Novo Procedimento"
        : "Editar Procedimento";
    },
    allowCadastrarBtn() {
      return this.services.authService.temPermissao(
        endpoints["AssistenteController::POST->register"]
      );
    },
    allowEditarBtn() {
      return this.services.authService.temPermissao(
        endpoints["AssistenteController::POST->updateAssistenteData/$1"]
      );
    },
    allowVisualizarBtn() {
      return this.services.authService.temPermissao(
        endpoints["AssistenteController::GET->getAssistenteByID/$1"]
      );
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    options: {
      handler() {
        this.options.desc = this.options.sortDesc[0] ?? false;
        this.atualizarListaAtendentes();
      },
      deep: true,
    },
    search(value, oldValue) {
      if (value.length > 2) {
        this.options.page = 1;
        this.atualizarListaAtendentes();
      }
      if (value.length === 0 && oldValue.length > 0) {
        this.options.page = 1;
        this.atualizarListaAtendentes();
      }
    },
    filtroStatus() {
      this.atualizarListaAtendentes();
    },
  },

  mounted() {
    this.atualizarListaAtendentes();
  },

  methods: {
    forceRender() {
      this.render += 1;
    },
    atualizarListaAtendentes() {
      const atendenteService = new AtendenteService();
      this.loading = true;
      this.atendentes = [];
      const onSucess = (status) => (body) => {
        if (status === 200) {
          console.log(body.data);
          this.totalList = body.data.pagination.num_rows;
          this.atendentes = body.data.recepcionistas.map((atendente) => {
            return {
              ...atendente,
              ativo: atendente.ativo == 1 ? "Ativo" : "Inativo",
            };
          });
          console.log(this.atendentes);
        } else {
          console.log(body.data);
        }
      };
      const onError = (error) => {
        console.log(error);
      };
      const onEnd = () => {
        this.loading = false;
      };
      atendenteService.getAll(
        onSucess,
        onError,
        onEnd,
        this.options.page,
        this.options.itemsPerPage,
        this.search,
        this.options.ordenacao,
        this.options.desc
      );
    },

    loadingAssistente(id) {
      this.atendente_detalhe = {};
      const atendenteService = new AtendenteService();
      atendenteService.get(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.atendente_detalhe = body.data;
              console.log(this.atendente_detalhe);
              // this.atendente_detalhe.medicos = this.atendente_detalhe.medicos.map(
              //   ({ id_medico }) => ~~id_medico
              // );
              this.dialog = true;
            }
          },
          onError: (error) => {
            this.messageSnackbar = error;
            this.typeMessageSnackbar = "error";
            console.log(error);
          },
          onEnd: () => {},
        },
        id
      );
    },

    editItem(item, bool) {
      this.editedIndex = this.atendentes.indexOf(item);
      this.atendente = item;
      this.visualizar = bool;
      this.editedItem = Object.assign({}, item);
      this.loadingAssistente(this.atendente.id);
      // this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.dialogCadastro = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      this.dialogCadastro = false;
      // if (this.editedIndex > -1) {
      //   Object.assign(this. [this.editedIndex], this.editedItem);
      // } else {
      //   this.atendentes.push(this.editedItem);
      // }
      this.atendente_detalhe = false;
      this.options.ordenacao = false;
      this.options.desc = false;
      this.forceRender();
      this.atualizarListaAtendentes();
      this.close();
    },
    novaSenha(idUsuario, nomeUsuario) {
      this.infoUsuario = {
        idUsuario: idUsuario,
        nomeUsuario: nomeUsuario,
      };
      this.dialogNovaSenha = true;
    },
    closeNovaSenha() {
      this.atualizarListaAtendentes();
      this.dialogNovaSenha = false;
    },
  },
};
</script>
