<template>
  <div>
    <v-card class="pb-2">
      <v-toolbar style="background-color: #790202; color: #fff">
        <v-toolbar-title>
          <span class="headline">Excluir Item Comanda</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="m-0">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12">
              <div v-if="anamnese">
                <h5 class="alert-style">
                  Esse item contém anamnese atrelada a ela, caso continue esse
                  registro, terá todo seu processo excluído.
                </h5>
                <v-divider></v-divider>
              </div>
              <p class="text-left mt-0">
                <strong>Tipo Procedimento: </strong
                >{{ itemComanda.tipo_procedimento_descricao }}<br />
                <strong>Procedimento: </strong
                >{{ itemComanda.procedimento_descricao }}<br />
                <strong>Tutor: </strong>{{ comanda.nome_cliente }}<br />
                <strong>Pet: </strong>{{ comanda.nome_pet }}<br />
                <strong>Data e Hora da Requisição: </strong
                >{{
                  getData(
                    itemComanda.data_requisicao,
                    itemComanda.hora_requisicao
                  )
                }}
              </p>
            </div>
          </div>
        </div>
      </v-card-text>
      <v-card-actions class="justify-content-between mx-3">
        <v-btn text tile elevation="2" color="blue" @click="$emit('close')">
          Cancelar
        </v-btn>
        <v-btn
          elevation="2"
          color="red"
          :dark="!loading"
          :loading="loading"
          :disabled="loading"
          @click="deletar()"
        >
          <strong>Deletar</strong>
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import Mixin from "@/mixins/vuex_mixin.js";
import vuex_snackbar_mixin from "@/mixins/vuex_snackbar_mixin.js";
import VuexUsuarioMixin from "@/mixins/vuex_usuario_mixin.js";
import DadosCadastraisMixin from "@/mixins/dados_cadastrais_mixin.js";
import AuditoriaService from "@/services/auditoria_service.js";

export default {
  mixins: [Mixin, VuexUsuarioMixin, DadosCadastraisMixin, vuex_snackbar_mixin],
  components: {},
  props: {
    itemComanda: Object,
    comanda: Object,
  },
  data: () => ({
    loading: false,
    services: {
      auditoriaService: AuditoriaService.build(),
    },
    anamnese: false,
  }),
  async mounted() {
    this.checkAnamnese();
  },
  watch: {},
  methods: {
    async checkAnamnese() {
      this.anamnese = false;
      await this.services.auditoriaService
        .getCheckComandaAnamnese(this.itemComanda.id_requisicao_procedimento)
        .then(async (response) => {
          console.log(response);
          if (response.status == 200) {
            this.anamnese = true;
          } else {
            this.anamnese = false;
          }
        })
        .catch((e) => console.error(e))
        .finally(() => {});
    },
    async deletar() {
      this.loading = true;
      // if (this.anamnese) {
      await this.services.auditoriaService
        .deleteAnamnesebyAudit(this.itemComanda.id_requisicao_procedimento)
        .then(async (response) => {
          const resp = await response.json();
          if (response.status !== 200) {
            this.$_ACTIONS_showSnackbarMessage({
              message: resp.message,
              color: "error",
            });
            return;
          } else {
            this.$_ACTIONS_showSnackbarMessage({
              message: resp.message,
              color: "sucess",
            });
            this.$emit("atualizar");
            this.$emit("close");
          }
          this.loading = false;
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          this.loading = false;
        });
      // } else {
      //   const responseFunction = {
      //     onSucess: (status) => (resp) => {
      //       if (status == 200) {
      //         this.$_ACTIONS_showSnackbarMessage({
      //           message: resp.message,
      //           color: "sucess",
      //         });
      //         this.$emit("atualizar");
      //       } else {
      //         this.$_ACTIONS_showSnackbarMessage({
      //           message: resp.message,
      //           color: "error",
      //         });
      //       }
      //     },
      //     onError: () => {
      //       this.$_ACTIONS_showSnackbarMessage({
      //         message: this.$global.messages.internalServerError,
      //         color: "error",
      //       });
      //     },
      //     onEnd: () => {
      //       this.loading = false;
      //       this.setComandaItens();
      //     },
      //   };
      //   if (["2", "3"].includes(this.itemComanda.tipo_procedimento_id)) {
      //     this.services.auditoriaService.auditoriaExcluirConsultaComanda(
      //       responseFunction,
      //       this.itemComanda.id_requisicao_procedimento
      //     );
      //   } else {
      //     this.services.auditoriaService.auditoriaExcluirItemComanda(
      //       responseFunction,
      //       this.itemComanda.id_requisicao_procedimento
      //     );
      //   }
      // }
    },
    createModel() {
      let id_assistente = parseInt(this.itemComanda.id_assistente);
      if (
        this.itemComanda.id_tipo_procedimento == 2 ||
        this.itemComanda.id_tipo_procedimento == 3
      ) {
        id_assistente = 0;
      }
      return {
        id_requisicao: parseInt(this.itemComanda.id_requisicao),
        id_agendamento: parseInt(this.itemComanda.id_agendamento),
        id_medico: parseInt(this.itemComanda.id_medico),
        id_assistente: id_assistente,
      };
    },
    getData(date, hora) {
      const arrDate = date.split("-");
      const arrHora = hora.split(":");
      return `${arrDate[2]}/${arrDate[1]}/${arrDate[0]} - ${arrHora[0]}:${arrHora[1]}`;
    },
  },
};
</script>

<style scoped>
.alert-style {
  text-align: left;
  color: #590505;
}
</style>
