<template>
  <div>
    <v-card min-height="100vh">
      <v-toolbar style="background-color: #1daf80; color: #fff">
        <v-btn icon dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          <span class="headline">Prontuários</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-tabs v-model="tab" background-color="transparent" color="#1daf80" grow>
        <v-tab key="medico">Médico</v-tab>
        <v-tab key="assistente">Assistente</v-tab>
      </v-tabs>
      <v-card-text>
        <div class="container mb-4">
          <div class="row justify-content-center">
            <div class="col-12">
              <v-card>
                <div class="row">
                  <div class="col-12">
                    <div class="d-flex justify-content-between px-8">
                      <span>
                        <strong>ID Tutor: </strong
                        >{{ pacienteInfo.id_paciente }}
                      </span>
                      <span>
                        <strong>Tutor: </strong>{{ pacienteInfo.nome }}
                      </span>
                      <span>
                        <strong>ID Pet: </strong>{{ pacienteInfo.id_pet }}
                      </span>
                      <span>
                        <strong>Pet: </strong>{{ pacienteInfo.nome_pet }}
                      </span>
                      <span>
                        <strong>Nascimento: </strong
                        >{{
                          getDataNascimento(
                            pacienteInfo.data_nascimento
                              ? pacienteInfo.data_nascimento
                              : pet.data_nascimento
                          )
                        }}
                      </span>
                    </div>
                  </div>
                </div>
              </v-card>
            </div>
          </div>
        </div>

        <v-tabs-items v-model="tab">
          <v-tab-item key="medico" class="pb-1">
            <div v-if="consultasFiltradasMedico.length > 0">
              <div class="container">
                <div class="row justify-content-center">
                  <div class="col-3">
                    <v-select
                      :items="dateFiltroMedico"
                      v-model="dateSelectedMedico"
                      item-text="label"
                      item-value="value"
                      label="Datas"
                      outlined
                    ></v-select>
                  </div>
                  <div class="col-3">
                    <v-select
                      :items="procedimentosFiltroMedico"
                      v-model="procedimentoSelectedMedico"
                      item-text="procedimento"
                      item-value="id"
                      label="Procedimento"
                      outlined
                    ></v-select>
                  </div>
                  <div class="col-3">
                    <v-select
                      :items="executorFiltroMedico"
                      v-model="executorSelectedMedico"
                      item-text="executor"
                      item-value="id"
                      label="Executor"
                      outlined
                    >
                      <template #item="{ item }">
                        <v-list-item-content>
                          <v-list-item-title>{{
                            item.executor
                          }}</v-list-item-title>
                          <v-list-item-subtitle
                            ><font
                              style="color: #ababab; font-weight: bold; font-size:0.8rem"
                              v-if="item.id != 0"
                            >
                              ID: {{ item.id }} | Tipo: {{ item.tipo }}</font
                            ></v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </template>
                    </v-select>
                  </div>
                </div>
              </div>
              <v-divider></v-divider>
              <div class="container-fluid">
                <div class="row">
                  <div class="col-12">
                    <div class="content-cards">
                      <div
                        class="card-prontuario-assistente col-10"
                        v-for="(consulta, index) in consultasFiltradasMedico"
                        :key="`cards-medico-${index}`"
                      >
                        <div class="card-head">
                          <div class="flex-1 align-self-center">
                            {{ getDate(consulta, "assistente") }}
                          </div>
                          <div class="d-flex">
                            <div
                              class="icon-card medico"
                              title="Médico"
                              v-if="consulta.id_perfil == 2"
                            >
                              <v-icon color="white">mdi-stethoscope</v-icon>
                            </div>
                            <div
                              class="icon-card assistente ml-1"
                              title="Assistente"
                              v-if="consulta.id_perfil == 3"
                            >
                              <v-icon color="white">mdi-clipboard-pulse</v-icon>
                            </div>
                            <!-- <div
                            class="icon-card adicional ml-1"
                            title="Anamnese Adicional"
                            v-if="consulta.req_st == 5"
                          >
                            <v-icon color="white">mdi-alpha-a</v-icon>
                          </div> -->
                          </div>
                        </div>
                        <div class="card-content">
                          <div class="d-flex align-items-center">
                            <div class="flex">
                              <strong>Executor: </strong
                              >{{ getExecutor(consulta) }}
                            </div>
                            <v-btn icon @click="toggleDetailsMedico(index)">
                              <v-icon>{{
                                expandedMedico === index
                                  ? "mdi-chevron-up"
                                  : "mdi-chevron-down"
                              }}</v-icon>
                            </v-btn>
                          </div>
                          <v-expand-transition>
                            <div v-show="expandedMedico === index">
                              <v-simple-table
                                fixed-header
                                :height="
                                  consulta.consultas.length > 5 ? '300px' : ''
                                "
                              >
                                <template v-slot:default>
                                  <thead>
                                    <tr>
                                      <th class="text-left">
                                        Procedimento
                                      </th>
                                      <th class="text-center" width="100px">
                                        Hora Início
                                      </th>
                                      <th class="text-center" width="100px">
                                        Ações
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="(item,
                                      index) in consulta.consultas"
                                      :key="`table-assistente-${index}`"
                                    >
                                      <td>{{ item.procedimento }}</td>
                                      <td class="text-center">
                                        {{ getHour(item.hora_inicio) }}
                                      </td>
                                      <td class="text-center">
                                        <v-btn
                                          class="mr-1"
                                          icon
                                          dark
                                          color="red"
                                          small
                                          title="Excluir Prontuário"
                                          v-if="checkDelete(item)"
                                          @click="excluir(item)"
                                        >
                                          <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                        <v-btn
                                          icon
                                          dark
                                          color="blue"
                                          x-small
                                          title="Detalhamento"
                                          @click="detail(item)"
                                        >
                                          <v-icon>mdi-magnify</v-icon>
                                        </v-btn>
                                      </td>
                                    </tr>
                                  </tbody>
                                </template>
                              </v-simple-table>
                            </div>
                          </v-expand-transition>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div class="container-fluid">
              <div class="row">
                <div class="col-12">
                  <div class="content-cards">
                    <div
                      class="card-prontuario"
                      v-for="(consulta, index) in consultasFiltradasMedico"
                      :key="`cards-medico-${consulta.id ? consulta.id : index}`"
                    >
                      <div class="card-head">
                        <div class="flex-1 align-self-center">
                          {{ getDate(consulta, "medico") }}
                        </div>
                        <div class="d-flex">
                          <div
                            class="icon-card medico"
                            title="Médico"
                            v-if="consulta.id_perfil == 2"
                          >
                            <v-icon color="white">mdi-stethoscope</v-icon>
                          </div>
                          <div
                            class="icon-card assistente ml-1"
                            title="Assistente"
                            v-if="consulta.id_perfil == 3"
                          >
                            <v-icon color="white">mdi-clipboard-pulse</v-icon>
                          </div>
                          <div
                            class="icon-card adicional ml-1"
                            title="Anamnese Adicional"
                            v-if="consulta.req_st == 5"
                          >
                            <v-icon color="white">mdi-alpha-a</v-icon>
                          </div>
                        </div>
                      </div>
                      <div class="card-content">
                        <div>
                          <strong>Procedimento:</strong><br />
                          {{ consulta.procedimento }}
                        </div>
                        <div>
                          <strong>Executor:</strong><br />
                          {{ getExecutor(consulta) }}
                        </div>
                      </div>
                      <div class="card-buttons">
                        <v-btn
                          class="mr-1"
                          fab
                          dark
                          color="red"
                          x-small
                          title="Excluir Prontuário"
                          elevation="1"
                          @click="excluir(consulta)"
                          v-if="checkDelete(consulta)"
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                        <v-btn
                          fab
                          dark
                          color="blue"
                          x-small
                          title="Detalhamento"
                          elevation="1"
                          @click="detail(consulta)"
                        >
                          <v-icon>mdi-magnify</v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
            </div>
            <div v-else>
              <h3 class="text-center mt-2">
                Sem prontuários executado por Médico.
              </h3>
            </div>
          </v-tab-item>
          <v-tab-item key="assistente" class="pb-1">
            <div v-if="consultasFiltradasAssist.length > 0">
              <div class="container">
                <div class="row justify-content-center">
                  <div class="col-3">
                    <v-select
                      :items="dateFiltroAssist"
                      v-model="dateSelectedAssist"
                      item-text="label"
                      item-value="value"
                      label="Datas"
                      outlined
                    ></v-select>
                  </div>
                  <div class="col-3">
                    <v-select
                      :items="procedimentosFiltroAssist"
                      v-model="procedimentoSelectedAssist"
                      item-text="procedimento"
                      item-value="id"
                      label="Procedimento"
                      outlined
                    ></v-select>
                  </div>
                  <div class="col-3">
                    <v-select
                      :items="executorFiltroAssist"
                      v-model="executorSelectedAssist"
                      item-text="executor"
                      item-value="id"
                      label="Executor"
                      outlined
                    >
                      <template #item="{ item }">
                        <v-list-item-content>
                          <v-list-item-title>{{
                            item.executor
                          }}</v-list-item-title>
                          <v-list-item-subtitle
                            ><font
                              style="color: #ababab; font-weight: bold; font-size:0.8rem"
                              v-if="item.id != 0"
                            >
                              ID: {{ item.id }} | Tipo: {{ item.tipo }}</font
                            ></v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </template>
                    </v-select>
                  </div>
                </div>
              </div>
              <v-divider></v-divider>
              <div class="container-fluid">
                <div class="row">
                  <div class="col-12">
                    <div class="content-cards">
                      <div
                        class="card-prontuario-assistente col-10"
                        v-for="(consulta, index) in consultasFiltradasAssist"
                        :key="`cards-assistente-${index}`"
                      >
                        <div class="card-head">
                          <div class="flex-1 align-self-center">
                            {{ getDate(consulta, "assistente") }}
                          </div>
                          <div class="d-flex">
                            <div
                              class="icon-card medico"
                              title="Médico"
                              v-if="consulta.id_perfil == 2"
                            >
                              <v-icon color="white">mdi-stethoscope</v-icon>
                            </div>
                            <div
                              class="icon-card assistente ml-1"
                              title="Assistente"
                              v-if="consulta.id_perfil == 3"
                            >
                              <v-icon color="white">mdi-clipboard-pulse</v-icon>
                            </div>
                            <!-- <div
                            class="icon-card adicional ml-1"
                            title="Anamnese Adicional"
                            v-if="consulta.req_st == 5"
                          >
                            <v-icon color="white">mdi-alpha-a</v-icon>
                          </div> -->
                          </div>
                        </div>
                        <div class="card-content">
                          <div class="d-flex align-items-center">
                            <div class="flex">
                              <strong>Executor: </strong
                              >{{ getExecutor(consulta) }}
                            </div>
                            <v-btn icon @click="toggleDetailsAssistente(index)">
                              <v-icon>{{
                                expandedAssistente === index
                                  ? "mdi-chevron-up"
                                  : "mdi-chevron-down"
                              }}</v-icon>
                            </v-btn>
                          </div>
                          <v-expand-transition>
                            <div v-show="expandedAssistente === index">
                              <v-simple-table
                                fixed-header
                                :height="
                                  consulta.consultas.length > 5 ? '300px' : ''
                                "
                              >
                                <template v-slot:default>
                                  <thead>
                                    <tr>
                                      <th class="text-left">
                                        Procedimento
                                      </th>
                                      <th class="text-center" width="100px">
                                        Hora Início
                                      </th>
                                      <th class="text-center" width="100px">
                                        Ações
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="(item,
                                      index) in consulta.consultas"
                                      :key="`table-assistente-${index}`"
                                    >
                                      <td>{{ item.procedimento }}</td>
                                      <td class="text-center">
                                        {{ getHour(item.hora_inicio) }}
                                      </td>
                                      <td class="text-center">
                                        <v-btn
                                          class="mr-1"
                                          icon
                                          dark
                                          color="red"
                                          small
                                          title="Excluir Prontuário"
                                          v-if="checkDelete(item)"
                                          @click="excluir(item)"
                                        >
                                          <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                        <v-btn
                                          icon
                                          dark
                                          color="blue"
                                          x-small
                                          title="Detalhamento"
                                          @click="detail(item)"
                                        >
                                          <v-icon>mdi-magnify</v-icon>
                                        </v-btn>
                                      </td>
                                    </tr>
                                  </tbody>
                                </template>
                              </v-simple-table>
                            </div>
                          </v-expand-transition>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <h3 class="text-center mt-2">
                Sem prontuários executado por Assistente.
              </h3>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="dialogDetalhe"
      fullscreen
      transition="dialog-bottom-transition"
    >
      <modal-detalhe-prontuario
        v-if="dialogDetalhe"
        @close="dialogDetalhe = false"
        @atualizar="atualizarCompromisso()"
        :info="this.detalheConsulta"
        :auditoria="this.auditoria"
      />
    </v-dialog>
    <v-dialog
      v-model="dialogExcluir"
      transition="dialog-bottom-transition"
      width="600"
    >
      <modal-exclusao-anamnese
        v-if="dialogExcluir"
        :compromissoExclusao="compromissoExclusao"
        :auditoria="this.auditoria"
        @close="dialogExcluir = false"
        @atualizar="atualizarCompromisso()"
        @atualizarAuditoria="atualizarItensComanda()"
      />
    </v-dialog>
  </div>
</template>

<script>
import Mixin from "@/mixins/vuex_mixin.js";
import vuex_snackbar_mixin from "@/mixins/vuex_snackbar_mixin.js";
import VuexUsuarioMixin from "@/mixins/vuex_usuario_mixin.js";
import DadosCadastraisMixin from "@/mixins/dados_cadastrais_mixin.js";
import PetsService from "@/services/pets_service.js";
import PacienteService from "../../services/paciente_service";
import ModalDetalheProntuario from "@/components/prontuarios/modal-detalhe-prontuario.vue";
import ModalExclusaoAnamnese from "@/components/prontuarios/modal_exclusao_anamnese.vue";
import AuditoriaService from "@/services/auditoria_service.js";

export default {
  mixins: [Mixin, VuexUsuarioMixin, DadosCadastraisMixin, vuex_snackbar_mixin],
  components: {
    ModalDetalheProntuario,
    ModalExclusaoAnamnese,
  },
  props: {
    consultas: Array,
    pacienteInfo: Object,
    comandaInfo: Object,
    auditoria: {
      type: Boolean,
    },
  },
  data: () => ({
    unidade: JSON.parse(sessionStorage.vuex).unidade,
    usuarioId: "",
    perfilId: "",
    paciente: "",
    tutor: {},
    pet: {},
    services: {
      petService: PetsService.build(),
      pacienteService: new PacienteService(),
      auditoriaService: AuditoriaService.build(),
    },
    consultasFiltradas: [],
    consultasFiltradasAssist: [],
    consultasFiltradasMedico: [],
    procedimentoSelected: 0,
    executorSelected: 0,
    dateSelected: 0,
    procedimentoSelectedMedico: 0,
    executorSelectedMedico: 0,
    dateSelectedMedico: 0,
    procedimentoSelectedAssist: 0,
    executorSelectedAssist: 0,
    dateSelectedAssist: 0,
    procedimentosFiltro: [],
    procedimentosFiltroAssist: [],
    procedimentosFiltroMedico: [],
    executorFiltro: [],
    executorFiltroAssist: [],
    executorFiltroMedico: [],
    dateFiltro: [],
    dateFiltroAssist: [],
    dateFiltroMedico: [],
    procedimentos: [],
    executores: [],
    dates: [],
    detalheConsulta: {},
    dialogDetalhe: false,
    compromissoExclusao: {},
    dialogExcluir: false,
    tab: "",
    expandedMedico: "",
    expandedAssistente: "",
    consultasMedico: [],
    consultasAssist: [],
  }),
  async mounted() {
    this.getTutor(this.pacienteInfo?.id_cliente);
    this.getPet(this.pacienteInfo?.id_pet);
    this.usuarioId = await JSON.parse(sessionStorage.vuex).usuario.id;
    this.perfilId = await JSON.parse(sessionStorage.vuex).perfil.id;
    this.paciente = {
      tutor_nome: this.pacienteInfo.nome,
      pet_nome: this.pacienteInfo.nome_pet,
      PetCodigo: this.pacienteInfo.id_pet,
      TutorCodigo: this.pacienteInfo.id_cliente,
    };
    this.tratarConsulta();
  },
  watch: {
    procedimentoSelectedMedico() {
      this.expandedMedico = "";
      this.executorSelectedMedico = 0;
      this.filtrar("Medico");
      this.tratarFiltrosPesquisa(false, "Medico");
    },
    executorSelectedMedico() {
      this.expandedMedico = "";
      this.filtrar("Medico");
    },
    dateSelectedMedico() {
      this.expandedMedico = "";
      this.procedimentoSelectedMedico = 0;
      this.executorSelectedMedico = 0;
      this.filtrar("Medico");
      this.tratarFiltrosPesquisa(true, "Medico");
    },
    procedimentoSelectedAssist() {
      this.expandedAssistente = "";
      this.executorSelectedAssist = 0;
      this.filtrar("Assistente");
      this.tratarFiltrosPesquisa(false, "Assistente");
    },
    executorSelectedAssist() {
      this.expandedAssistente = "";
      this.filtrar("Assistente");
    },
    dateSelectedAssist() {
      this.expandedAssistente = "";
      this.procedimentoSelectedAssist = 0;
      this.executorSelectedAssist = 0;
      this.filtrar("Assistente");
      this.tratarFiltrosPesquisa(true, "Assistente");
    },
  },
  methods: {
    atualizar() {},
    atualizarItensComanda() {
      this.dialogExcluir = false;
      this.$emit("atualizarItens");
      this.$emit("close");
    },
    mapearModeloAuditoria() {
      for (let index = 0; index < this.consultas.length; index++) {
        const element = this.consultas[index];
        this.consultas[index] = {
          ...this.consultas[index],
          id_requisicao: element.id_requisicao_procedimento,
          req_st: 2,
          tipo_anamnese: "Anamnse Normal",
          data: element.agenda_data,
          hora_inicio: element.agenda_hora_inicio,
          hora_fim: element.agenda_hora_inicio,
          status: "Atendido",
          unidade_descricao: element.nome_unidade,
          id_assistente: element.usuario_executor,
          nome_assistente: element.nome_usuario_executor,
          id_perfil: element.id_perfil_usuario_executor,
        };
      }
    },
    tratarConsulta() {
      if (this.auditoria == true) {
        this.mapearModeloAuditoria();
      }
      this.consultasAssist = [];
      this.consultasMedico = [];
      for (let index = 0; index < this.consultas.length; index++) {
        const element = this.consultas[index];
        this.consultas[index].dataFormatada = new Date(
          `${element.data} ${element.hora_inicio}`
        );
        const executor = this.checkExecutor(element);
        if (executor) {
          this.executorFiltro.push({
            id: executor.id,
            executor: executor.nome,
            tipo: executor.tipo,
          });
          if (executor.tipo == "Médico") {
            this.executorFiltroMedico.push({
              id: executor.id,
              executor: executor.nome,
              tipo: executor.tipo,
            });
            if (!this.checkDate(executor.tipo, element.data)) {
              this.dateFiltroMedico.push({
                value: element.data,
                label: this.getDataNascimento(element.data),
              });
            }
            if (
              !this.checkProcedimento(executor.tipo, element.id_procedimento)
            ) {
              this.procedimentosFiltroMedico.push({
                id: element.id_procedimento,
                procedimento: element.procedimento,
              });
            }
            this.consultasMedico.push(this.consultas[index]);
          } else {
            this.executorFiltroAssist.push({
              id: executor.id,
              executor: executor.nome,
              tipo: executor.tipo,
            });
            if (!this.checkDate(executor.tipo, element.data)) {
              this.dateFiltroAssist.push({
                value: element.data,
                label: this.getDataNascimento(element.data),
              });
            }
            if (
              !this.checkProcedimento(executor.tipo, element.id_procedimento)
            ) {
              this.procedimentosFiltroAssist.push({
                id: element.id_procedimento,
                procedimento: element.procedimento,
              });
            }
            this.consultasAssist.push(this.consultas[index]);
          }
        }
      }
      // this.consultas.sort(this.sortlist);
      // this.consultasFiltradas = this.consultas;
      // this.consultasMedico.sort(this.sortlist);
      console.log("Antes: ", this.consultasMedico);
      this.consultasMedico = this.tratarConsultasAssist(
        this.consultasMedico.sort(this.sortlist)
      );
      console.log("Depois: ", this.consultasMedico);
      this.consultasAssist = this.tratarConsultasAssist(
        this.consultasAssist.sort(this.sortlist)
      );
      this.consultasFiltradasMedico = this.consultasMedico;
      this.consultasFiltradasAssist = this.consultasAssist;
      this.tratarFiltros();
    },
    addTodos(tipo = "") {
      if (tipo == "Medico" || tipo == "") {
        this.executorFiltroMedico.unshift({ id: 0, executor: "Todos" });
        this.procedimentosFiltroMedico.unshift({
          id: 0,
          procedimento: "Todos",
        });
        this.dateFiltroMedico.unshift({ value: 0, label: "Todos" });
      }
      if (tipo == "Assistente" || tipo == "") {
        this.executorFiltroAssist.unshift({ id: 0, executor: "Todos" });
        this.procedimentosFiltroAssist.unshift({
          id: 0,
          procedimento: "Todos",
        });
        this.dateFiltroAssist.unshift({ value: 0, label: "Todos" });
      }
    },
    sortlist(a, b) {
      if (a.dataFormatada < b.dataFormatada) {
        return 1;
      }
      if (a.dataFormatada > b.dataFormatada) {
        return -1;
      }
      return 0;
    },
    sortExecutor(a, b) {
      if (a.executor > b.executor) {
        return 1;
      }
      if (a.executor < b.executor) {
        return -1;
      }
      return 0;
    },
    sortProcedimento(a, b) {
      if (a.procedimento > b.procedimento) {
        return 1;
      }
      if (a.procedimento < b.procedimento) {
        return -1;
      }
      return 0;
    },
    sortDate(a, b) {
      if (a.value < b.value) {
        return 1;
      }
      if (a.value > b.value) {
        return -1;
      }
      return 0;
    },
    tratarFiltros(tipo = "") {
      if (tipo == "Medico" || tipo == "") {
        this.executorFiltroMedico.sort(this.sortExecutor);
        this.procedimentosFiltroMedico.sort(this.sortProcedimento);
        this.dateFiltroMedico.sort(this.sortDate);
      }
      if (tipo == "Assistente" || tipo == "") {
        this.executorFiltroAssist.sort(this.sortExecutor);
        this.procedimentosFiltroAssist.sort(this.sortProcedimento);
        this.dateFiltroAssist.sort(this.sortDate);
      }
      this.addTodos(tipo);
      // this.dates = this.dateFiltro;
      // this.procedimentos = this.procedimentosFiltro;
      // this.executores = this.executorFiltro;
    },
    tratarFiltrosPesquisa(procSelect = false, tipo = "") {
      if (tipo == "Medico" || tipo == "") {
        let procedimentosMedico = [];
        let executoresMedico = [];
        for (
          let index = 0;
          index < this.consultasFiltradasMedico.length;
          index++
        ) {
          const element = this.consultasFiltradasMedico[index];
          for (
            let indexConsultas = 0;
            indexConsultas < element.consultas.length;
            indexConsultas++
          ) {
            const elementConsulta = element.consultas[indexConsultas];
            if (
              !this.checkProcedimento(
                elementConsulta.tipo,
                elementConsulta.id_procedimento
              )
            ) {
              procedimentosMedico.push({
                id: elementConsulta.id_procedimento,
                procedimento: elementConsulta.procedimento,
              });
            }
          }
          // if (!this.checkProcedimento(element.tipo, element.id_procedimento)) {
          //   procedimentosMedico.push({
          //     id: element.id_procedimento,
          //     procedimento: element.procedimento,
          //   });
          // }
          const executor = this.checkExecutor(element);
          if (executor) {
            executoresMedico.push({
              id: executor.id,
              executor: executor.nome,
              tipo: executor.tipo,
            });
          }
        }
        if (procSelect) {
          this.procedimentosFiltroMedico = procedimentosMedico;
          this.procedimentosFiltroMedico.unshift({
            id: 0,
            procedimento: "Todos",
          });
        }
        this.executorFiltroMedico = executoresMedico;
        this.executorFiltroMedico.unshift({ id: 0, executor: "Todos" });
      }
      if (tipo == "Assistente" || tipo == "") {
        let procedimentosAssist = [];
        let executoresAssist = [];
        for (
          let index = 0;
          index < this.consultasFiltradasAssist.length;
          index++
        ) {
          const element = this.consultasFiltradasAssist[index];
          for (
            let indexConsultas = 0;
            indexConsultas < element.consultas.length;
            indexConsultas++
          ) {
            const elementConsulta = element.consultas[indexConsultas];
            if (
              !this.checkProcedimento(
                elementConsulta.tipo,
                elementConsulta.id_procedimento
              )
            ) {
              procedimentosAssist.push({
                id: elementConsulta.id_procedimento,
                procedimento: elementConsulta.procedimento,
              });
            }
          }
          const executor = this.checkExecutor(element);
          if (executor) {
            executoresAssist.push({
              id: executor.id,
              executor: executor.nome,
              tipo: executor.tipo,
            });
          }
        }
        if (procSelect) {
          this.procedimentosFiltroAssist = procedimentosAssist;
          this.procedimentosFiltroAssist.unshift({
            id: 0,
            procedimento: "Todos",
          });
        }
        this.executorFiltroAssist = executoresAssist;
        this.executorFiltroAssist.unshift({ id: 0, executor: "Todos" });
      }
    },
    tratarConsultasAssist(consultas) {
      if (consultas.length > 0) {
        let newConsultas = [];
        for (let index = 0; index < consultas.length; index++) {
          const element = consultas[index];
          const indexConsulta = newConsultas.findIndex((ele) => {
            return (
              ele.data == element.data &&
              ele.id_assistente == element.id_assistente
            );
          });
          if (indexConsulta != -1) {
            newConsultas[indexConsulta].consultas.push(element);
            if (
              !newConsultas[indexConsulta].procedimentos_id.includes(
                element.id_procedimento
              )
            ) {
              newConsultas[indexConsulta].procedimentos_id.push(
                element.id_procedimento
              );
            }
          } else {
            newConsultas.push({
              data: element.data,
              id_assistente: element.id_assistente,
              nome_medico: element.nome_medico,
              nome_assistente: element.nome_assistente,
              id_perfil: element.id_perfil,
              consultas: [element],
              procedimentos_id: [element.id_procedimento],
            });
          }
        }
        return newConsultas;
      } else {
        return consultas;
      }
    },
    checkDate(tipo, date) {
      if (tipo == "Médico") {
        return this.dateFiltroMedico.includes((ele) => {
          return ele.date == date;
        });
      } else {
        return this.dateFiltroAssist.includes((ele) => {
          return ele.date == date;
        });
      }
    },
    checkProcedimento(tipo, id) {
      if (tipo == "Médico") {
        return this.procedimentosFiltroMedico.includes((ele) => {
          return ele.id == id;
        });
      } else {
        return this.procedimentosFiltroAssist.includes((ele) => {
          return ele.id == id;
        });
      }
    },
    checkExecutor(element) {
      if (
        !this.executorFiltro.includes((ele) => {
          return ele.id == element.id_assistente;
        }) &&
        (element.id_perfil == 2 || element.id_perfil == 3)
      ) {
        return {
          id: element.id_assistente,
          nome: element.nome_assistente,
          tipo: element.id_perfil == 2 ? "Médico" : "Assistente",
        };
      } else {
        return null;
      }
      // if (
      //   element.id_medico > 0 &&
      //   element.id_assistente == 0 &&
      //   !this.executorFiltro.includes((ele) => {
      //     return ele.id == element.id_medico;
      //   })
      // ) {
      //   return {
      //     id: element.id_medico,
      //     nome: element.nome_medico,
      //     tipo: "Médico",
      //   };
      // } else if (
      //   element.id_assistente > 0 &&
      //   !this.executorFiltro.includes((ele) => {
      //     return ele.id == element.id_assistente && ele.tipo == "Assistente";
      //   })
      // ) {
      //   return {
      //     id: element.id_assistente,
      //     nome: element.nome_assistente,
      //     tipo: "Assistente",
      //   };
      // } else {
      //   return null;
      // }
    },
    getTutor(cliente_id) {
      this.services.pacienteService
        .getPacienteByClienteId(cliente_id)
        .then((resp) => {
          this.tutor = resp.data;
        });
    },
    getPet(id) {
      this.services.petService.getPetById(id).then((resp) => {
        this.pet = {
          ...resp,
          sexo: resp.sexo == "M" ? "Macho" : "Fêmea",
          peso: resp.peso || "Não cadastrado",
        };
      });
    },
    getDate(item, tipo) {
      const dia = item.data.split("-");
      if (tipo == "medico") {
        const hora = item.hora_inicio.split(":");
        return `${dia[2]}/${dia[1]}/${dia[0]} | ${hora[0]}:${hora[1]}`;
      } else {
        return `${dia[2]}/${dia[1]}/${dia[0]}`;
      }
    },
    getHour(hora) {
      const newHora = hora.split(":");
      return `${newHora[0]}:${newHora[1]}`;
    },
    getExecutor(item) {
      if (item.id_medico > 0 && item.id_assistente == 0) {
        return item.nome_medico ?? "-";
      } else if (item.id_assistente > 0) {
        return item.nome_assistente ?? "-";
      } else {
        return "-";
      }
    },
    getDataNascimento(data) {
      if (data) {
        const dia = data.split("-");
        return `${dia[2]}/${dia[1]}/${dia[0]}`;
      } else {
        return `-`;
      }
    },
    filtrar(tipo = "") {
      // this.procedimentoSelected
      // this.executorSelected
      // this.dateSelected
      if (tipo == "Medico" || tipo == "") {
        let executorMedico = this.executorFiltroMedico.find((ele) => {
          return ele.id == this.executorSelectedMedico;
        });
        let consultasMedico = this.consultasMedico;
        if (this.dateSelectedMedico != 0) {
          consultasMedico = consultasMedico.filter((ele) => {
            return ele.data == this.dateSelectedMedico;
          });
        }
        if (executorMedico.id > 0) {
          consultasMedico = consultasMedico.filter((ele) => {
            return ele.id_assistente == executorMedico.id;
            // return (
            //   (executor.tipo == "Médico" ? ele.id_medico : ele.id_assistente) ==
            //   executor.id
            // );
          });
        }
        if (this.procedimentoSelectedMedico > 0) {
          consultasMedico = consultasMedico.filter((ele) => {
            return ele.id_procedimento.includes(
              this.procedimentoSelectedMedico
            );
            // return ele.id_procedimento == this.procedimentoSelectedMedico;
          });
        }
        // this.filtrarCampos();
        this.consultasFiltradasMedico = consultasMedico;
      }
      if (tipo == "Assistente" || tipo == "") {
        let executorAssist = this.executorFiltroAssist.find((ele) => {
          return ele.id == this.executorSelectedAssist;
        });
        let consultasAssist = this.consultasAssist;
        if (this.dateSelectedAssist != 0) {
          consultasAssist = consultasAssist.filter((ele) => {
            return ele.data == this.dateSelectedAssist;
          });
        }
        if (executorAssist.id > 0) {
          consultasAssist = consultasAssist.filter((ele) => {
            return ele.id_assistente == executorAssist.id;
            // return (
            //   (executor.tipo == "Médico" ? ele.id_medico : ele.id_assistente) ==
            //   executor.id
            // );
          });
        }
        if (this.procedimentoSelectedAssist > 0) {
          consultasAssist = consultasAssist.filter((ele) => {
            return ele.procedimentos_id.includes(
              this.procedimentoSelectedAssist
            );
            // return ele.id_procedimento == this.procedimentoSelectedAssist;
          });
        }
        // this.filtrarCampos();
        this.consultasFiltradasAssist = consultasAssist;
      }
    },
    atualizarCompromisso() {
      this.dialogExcluir = false;
      this.dialogDetalhe = false;
      this.listProntuarios();
    },
    detail(consulta) {
      this.detalheConsulta = { ...consulta, pacienteInfo: this.pacienteInfo };
      this.dialogDetalhe = true;
    },
    excluir(consulta) {
      this.compromissoExclusao = {
        ...consulta,
        idTutor: this.pacienteInfo.id_paciente,
        nomeTutor: this.pacienteInfo.nome,
        idPet: this.pacienteInfo.id_pet,
        nomePet: this.pacienteInfo.nome_pet,
      };
      this.dialogExcluir = true;
    },
    async listProntuarios() {
      if (this.auditoria == true) {
        await this.services.auditoriaService
          .getConsultasbyComandaId(this.comandaInfo.comanda_id)
          .then((resp) => resp.json())
          .then(({ data }) => {
            if (data.prontuarios) {
              this.consultas = data.prontuarios;
              this.dateSelected = 0;
              this.tratarConsulta();
            } else {
              this.$emit("close");
            }
            // this.prontuarios = data.prontuarios
          });
      } else {
        await this.services.petService
          .getConsultasbyUnidade(this.pacienteInfo.id_pet, this.unidade.id)
          .then(async (response) => {
            const data = await response.json();
            if (data.data) {
              this.consultas = data.data.consultas;
              this.dateSelected = 0;
              // if (this.auditoria == true) {
              //   this.mapearModeloAuditoria();
              // }
              this.tratarConsulta();
            } else {
              this.$emit("close");
            }
          })
          .finally(() => {});
      }
    },
    checkDelete(consulta) {
      if ([1, 56].includes(parseInt(this.perfilId)) && this.auditoria == true) {
        return true;
      } else if (consulta.id_assistente == this.usuarioId) {
        return true;
      } else {
        return false;
      }
    },
    toggleDetailsAssistente(index) {
      this.expandedAssistente =
        this.expandedAssistente === index ? null : index;
    },
    toggleDetailsMedico(index) {
      this.expandedMedico = this.expandedMedico === index ? null : index;
    },
  },
};
</script>

<style scoped>
.content-cards {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}
.card-prontuario {
  width: 340px;
  border-radius: 15px;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2);
}
.card-prontuario-assistente {
  /* width: 100%; */
  padding: 0px;
  border-radius: 15px;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2);
}
.card-head {
  padding: 6px 15px;
  background-color: #1daf80;
  color: white;
  font-weight: bold;
  font-size: 1rem;
  border-radius: 15px 15px 0px 0px;
  display: flex;
  justify-content: space-between;
  height: 60px;
}
.icon-card {
  border-radius: 100%;
  padding: 4px;
  width: 36px;
  height: 36px;
  font-size: 32px;
}
.icon-card.medico {
  background-color: #fe4d4d;
}
.icon-card.assistente {
  background-color: #7f1cf8;
}
.icon-card.adicional {
  background-color: #ff6000;
}
.card-content {
  text-align: start;
  padding: 10px 15px 0px 15px;
}
.card-prontuario-assistente .card-content {
  text-align: start;
  padding: 10px 15px 10px 15px;
}
.card-content div {
  margin-bottom: 5px;
  font-size: 0.9rem;
}
.card-content div strong {
  margin-bottom: 5px;
  font-size: 1rem;
}
.card-buttons {
  text-align: end;
  padding: 5px 15px 10px 15px;
}
</style>
