<template>
  <div>
    <div class="p-lg-3">
      <div class="row justify-content-between">
        <h3 class="mt-0 mb-2 font-weight-bold text-left">Prefeituras</h3>
      </div>
    </div>

    <v-data-table
      v-on:update:sort-by="options.ordenacao = true"
      :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }"
      :search="search"
      :headers="headers"
      :items="prefeituras"
      :options.sync="options"
      :server-items-length="totalList"
      class="elevation-1"
      :loading="loading"
      :loading-text="'Carregando...'"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <div class="d-flex justify-space-between w-100">
            <!-- DIALOG DE CADASTRO -->
            <v-dialog
              v-model="dialogCadastro"
              max-width="900px"
              scrollable
              transition="dialog-bottom-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  dark
                  class="bg-verde mb-2"
                  rounded
                  v-bind="attrs"
                  v-on="on"
                >
                  Nova Prefeitura
                </v-btn>
              </template>

              <v-card v-if="dialogCadastro">
                <v-toolbar class="bg-verde" dark>
                  <v-btn icon dark @click="dialogCadastro = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                  <v-toolbar-title>
                    <span class="headline">Nova Prefeitura</span>
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-toolbar-items> </v-toolbar-items>
                </v-toolbar>
                <v-card-text>
                  <CadastroPrefeitura @finalizarCadastro="finalizarCadastro" />
                </v-card-text>
              </v-card>
            </v-dialog>
            <div class="d-flex align-end">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar por nome"
                placeholder="Buscar por nome"
                single-line
                hide-details
              />
            </div>
          </div>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editarPrefeitura(item)">
          mdi-pencil
        </v-icon>
      </template>
    </v-data-table>
    <v-dialog
      v-model="dialogEdicao"
      max-width="900px"
      scrollable
      transition="dialog-bottom-transition"
    >
      <v-card v-if="dialogEdicao">
        <v-toolbar class="bg-verde" dark>
          <v-btn icon dark @click="dialogEdicao = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            <span class="headline">Editar Prefeitura</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items> </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <EdicaoPrefeitura
            :prefeitura="prefeituraEdit"
            @finalizarEdicao="finalizarEdicao"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CadastroPrefeitura from "../components/prefeituras/cadastro_prefeitura.vue";
import EdicaoPrefeitura from "../components/prefeituras/edicao_prefeitura.vue";
import Mixin from "@/mixins/vuex_mixin.js";
import PrefeituraService from "../services/prefeitura_service";

export default {
  mixins: [Mixin],
  components: {
    CadastroPrefeitura,
    EdicaoPrefeitura,
  },
  data() {
    return {
      loading: false,
      prefeituras: [],
      headers: [
        {
          text: "ID",
          align: "start",
          sortable: false,
          value: "id",
        },
        {
          text: "Nome",
          align: "start",
          sortable: false,
          value: "nome_fantasia",
        },
        { text: "Cidade", align: "start", sortable: false, value: "cidade" },
        { text: "Estado", align: "start", sortable: false, value: "estado" },
        // { text: "Telefone", sortable: false, value: "telefone" },
        { text: "Ações", align: "end", value: "actions", sortable: false },
      ],
      options: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 15,
        ordenacao: false,
        desc: false,
      },
      totalList: 10,
      search: "",
      dialogCadastro: false,
      dialogEdicao: false,
      services: {
        prefeituraService: PrefeituraService.build(),
      },
      prefeituraEdit: {},
    };
  },
  watch: {
    options: {
      handler() {
        this.options.desc = this.options.sortDesc[0] ?? false;
        this.listarPrefeituras();
      },
      deep: true,
    },
    search(value, oldValue) {
      if (value.length > 2) {
        this.options.page = 1;
        this.listarPrefeituras(value);
      }
      if (value.length === 0 && oldValue.length > 0) {
        this.options.page = 1;
        this.listarPrefeituras();
      }
    },
  },
  beforeMount() {
    this.listarPrefeituras();
  },
  methods: {
    async listarPrefeituras(search) {
      this.loading = true;

      const paginationParams = {
        page: this.options.page,
        per_page: this.options.itemsPerPage,
        sort: this.options.ordenacao,
        desc: this.options.desc,
      };

      let resp = {};

      if (search) {
        let formdata = new FormData();
        formdata.append("nome", search);

        await this.services.prefeituraService.listarPrefeiturasPorNome(
          {
            onSucess: (status) => (body) => {
              if (status == 200) {
                if (body) {
                  this.prefeituras = body.data.prefeituras;
                }
              }

              if (status == 206) {
                this.prefeituras = [];
                this.$_ACTIONS_showSnackbarMessage({
                  message: "Nenhum registro encontrado",
                  color: "warning",
                });
              }

              if (status == 500) {
                this.prefeituras = [];
                this.$_ACTIONS_showSnackbarMessage({
                  message: this.$global.messages.internalServerError,
                  color: "error",
                });
              }
            },
            onError: (error) => {
              console.error(error);
            },
            onEnd: () => {
              this.loading = false;
            },
          },
          formdata
        );
        return;
      } else {
        resp = await this.services.prefeituraService
          .listarPrefeituras(paginationParams)
          .then((resp) => {
            return resp.json();
          });

        if (resp.data) {
          this.prefeituras = resp.data.prefeituras;
          this.totalList = resp.data.pagination.num_rows;
        } else {
          this.prefeituras = [];
          this.$_ACTIONS_showSnackbarMessage({
            message: resp.message || this.$global.messages.internalServerError,
            color: "error",
          });
        }

        this.loading = false;
      }
    },

    editarPrefeitura(prefeitura) {
      console.log(prefeitura);
      this.prefeituraEdit = prefeitura;
      this.dialogEdicao = true;
    },

    finalizarEdicao() {
      this.dialogEdicao = false;
      this.listarPrefeituras();
    },

    finalizarCadastro() {
      this.dialogCadastro = false;
      this.listarPrefeituras();
    },
  },
};
</script>
