<template>
  <div>
    <div class="p-lg-3">
      <div class="row justify-content-between">
        <h3 class="mt-0 mb-2 font-weight-bold text-left">Usuário Prefeitura</h3>
      </div>
    </div>

    <v-data-table
      v-on:update:sort-by="options.ordenacao = true"
      :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }"
      :headers="headers"
      :items="usuariosPrefeitura"
      :options.sync="options"
      :server-items-length="totalList"
      class="elevation-1"
      :loading="loading"
      :loading-text="'Carregando...'"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <!-- DIALOG DE CADASTRO -->
          <v-dialog
            v-model="dialogCadastro"
            max-width="500px"
            persistent
            fullscreen
            transition="dialog-bottom-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                class="bg-verde mb-2"
                rounded
                v-bind="attrs"
                v-on="on"
              >
                Novo Usuário
              </v-btn>
            </template>
            <v-card v-if="dialogCadastro">
              <v-toolbar class="bg-verde" dark>
                <v-btn icon dark @click="dialogCadastro = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>
                  <span class="headline">Novo Usuário</span>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items> </v-toolbar-items>
              </v-toolbar>
              <v-card-text>
                <NovoUsuarioPrefeitura @finalizarCadastro="finalizarCadastro" />
              </v-card-text>
            </v-card>
          </v-dialog>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <!-- <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Pesquisar"
            single-line
            hide-details
          ></v-text-field> -->
          <!-- DIALOG DE EDIÇÃO -->
          <!-- <v-dialog
            v-model="dialog"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
          >
            <cadastro-edicao-administrativo
              v-if="administrativo_detalhe"
              :administrativo="administrativo_detalhe"
              :visualizar="visualizar"
              :key="render"
              @close="save($event)"
            />
          </v-dialog> -->
        </v-toolbar>
      </template>
      <template v-slot:item.ativo="{ item }">
        {{ item.ativo == 1 ? "Ativo" : "Inativo" }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editarUsuarioPrefeitura(item)">
          mdi-pencil
        </v-icon>
      </template>
    </v-data-table>
    <v-dialog
      v-model="dialogEdicao"
      fullscreen
      scrollable
      transition="dialog-bottom-transition"
    >
      <v-card v-if="dialogEdicao">
        <v-toolbar class="bg-verde" dark>
          <v-btn icon dark @click="dialogEdicao = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            <span class="headline">Editar Usuario</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items> </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <EdicaoUsuarioPrefeitura
            :usuarioPrefeitura="usuarioPrefeitura"
            @finalizarCadastro="finalizarCadastro"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Mixin from "@/mixins/vuex_snackbar_mixin.js";
import PrefeituraService from "@/services/prefeitura_service";
import NovoUsuarioPrefeitura from "../components/usuario_prefeitura/novo_usuario_prefeitura.vue";
import EdicaoUsuarioPrefeitura from "../components/usuario_prefeitura/edicao_usuario_prefeitura.vue";

export default {
  mixins: [Mixin],
  components: {
    NovoUsuarioPrefeitura,
    EdicaoUsuarioPrefeitura,
  },
  data() {
    return {
      loading: false,
      headers: [
        { text: "Nome", align: "start", sortable: false, value: "nome" },
        { text: "CPF", value: "cpf", sortable: false },
        { text: "Email", value: "email", sortable: false },
        { text: "Status", value: "ativo", sortable: false },
        { text: "Ações", align: "end", value: "actions", sortable: false },
      ],
      usuariosPrefeitura: [],
      usuarioPrefeitura: {},
      totalList: 10,
      options: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 15,
        ordenacao: false,
        desc: false,
      },
      services: {
        prefeituraService: PrefeituraService.build(),
      },
      dialogCadastro: false,
      dialogEdicao: false,
    };
  },
  watch: {
    options: {
      handler() {
        this.options.desc = this.options.sortDesc[0] ?? false;
        this.listarUsuariosPrefeitura();
      },
      deep: true,
    },
  },
  beforeMount() {
    this.listarUsuariosPrefeitura();
  },
  methods: {
    finalizarCadastro() {
      this.dialogCadastro = false;
      this.dialogEdicao = false;
      this.listarUsuariosPrefeitura();
    },
    async listarUsuariosPrefeitura() {
      this.loading = true;

      const paginationParams = {
        page: this.options.page,
        per_page: this.options.itemsPerPage,
        sort: this.options.ordenacao,
        desc: this.options.desc,
      };

      const resp = await this.services.prefeituraService
        .listarUsuariosPrefeitura(paginationParams)
        .then((resp) => {
          return resp.json();
        });

      if (resp.data) {
        this.usuariosPrefeitura = resp.data.usuarios;
        this.totalList = resp.data.pagination.num_rows;
      } else {
        this.usuariosPrefeitura = [];
        this.$_ACTIONS_showSnackbarMessage({
          message: resp.message,
          color: "error",
        });
      }

      this.loading = false;
    },
    editarUsuarioPrefeitura(usuario) {
      this.usuarioPrefeitura = usuario;
      this.dialogEdicao = true;
    },
  },
};
</script>
