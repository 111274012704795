var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"p-lg-3"},[_c('div',{staticClass:"row d-flex flex-column"},[_c('h3',{staticClass:"mt-0 mb-0 text-left"},[_vm._v(" Bem vindo! ")]),_c('v-btn',{staticClass:"mx-2 ",attrs:{"fab":"","dark":"","small":"","color":"green","fixed":"","right":"15px","bottom":"15px"},on:{"click":function($event){return _vm.whatsapp()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-whatsapp")])],1),_c('h4',{staticClass:"capitalize text-left "},[_vm._v(" "+_vm._s(_vm.$_GETTERS_usuario.dados.nome.toLowerCase())+" ")])],1)]),_c('v-divider'),(!_vm.petCadastrado)?_c('div',[_c('v-dialog',{attrs:{"persistent":"","fullscreen":"","max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"#1daf80","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Cadastre seu primeiro Pet ")])]}}],null,false,2016391621),model:{value:(_vm.dialogCadastrar),callback:function ($$v) {_vm.dialogCadastrar=$$v},expression:"dialogCadastrar"}},[(_vm.dialogCadastrar)?_c('modal-cadastro',{on:{"close":function($event){_vm.dialogCadastrar = false},"response":_vm.verificarPet}}):_vm._e()],1)],1):_c('div',{staticClass:"row justify-content-between my-6 px-4"},[_c('v-dialog',{attrs:{"persistent":"","fullscreen":"","max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","outlined":"","color":"#1daf80"}},'v-btn',attrs,false),on),[_vm._v(" Nova consulta Televet ")])]}}]),model:{value:(_vm.dialogAgendarTelevet),callback:function ($$v) {_vm.dialogAgendarTelevet=$$v},expression:"dialogAgendarTelevet"}},[(_vm.dialogAgendarTelevet)?_c('v-card',[_c('v-toolbar',{staticStyle:{"background-color":"#1daf80","color":"#fff"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.dialogAgendarTelevet = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_c('span',{staticClass:"headline"},[_vm._v("Nova Consulta TeleVet")])]),_c('v-spacer'),_c('v-toolbar-items')],1),(_vm.dialogAgendarTelevet)?_c('agendar-televet',{on:{"close":function($event){_vm.dialogAgendarTelevet = false},"response":_vm.agendamentoCadastrado}}):_vm._e()],1):_vm._e()],1),_c('v-btn',{attrs:{"dark":"","color":"#1daf80"},on:{"click":function($event){_vm.visualizarTelevet = true;
        _vm.getFilaCliente();}}},[_vm._v(" "+_vm._s(_vm.visualizarTelevet ? "Atualizar" : "Visualizar Consultas Televet")+" ")])],1),(_vm.visualizarTelevet)?_c('div',[(_vm.listaTeleconsultas.length > 0)?_c('div',{staticClass:"cards-style"},_vm._l((_vm.listaTeleconsultas),function(teleconsulta){return _c('v-card',{key:teleconsulta.id_requisicao_procedimento,staticClass:"mx-auto",attrs:{"elevation":"2","width":"100%"}},[_c('div',{staticClass:"style-card-teleconsulta"},[_c('div',{staticClass:"text-left"},[_c('font',{staticClass:"font-weight-bold"},[_vm._v("Pet:")]),_vm._v(" "+_vm._s(teleconsulta.nome_pet)+" ")],1),_c('div',{staticClass:"text-left"},[_c('font',{staticClass:"font-weight-bold"},[_vm._v("Raça:")]),_vm._v(" "+_vm._s(teleconsulta.nome_raca)+" ")],1),_c('div',{staticClass:"text-left"},[_c('font',{staticClass:"font-weight-bold"},[_vm._v("Data:")]),_vm._v(" "+_vm._s(_vm.formatarData( teleconsulta.data_criacao, teleconsulta.hora_criacao ))+" ")],1),_c('div',{staticClass:"text-right"},[(teleconsulta.id_medico != null)?_c('v-btn',{attrs:{"dark":"","color":"#2C7091","small":"","width":"139px"},on:{"click":function($event){return _vm.getSalaConferencia(teleconsulta.id_requisicao_procedimento)}}},[_vm._v(" Entrar na Sala ")]):_c('v-btn',{attrs:{"disabled":"","color":"#f0a211","width":"139px","small":""}},[_vm._v(" Aguardando ")])],1)]),(teleconsulta.id_medico == null)?_c('div',{staticClass:"style-card-tempo"},[_vm._v(" Olá! Estamos agilizando a consulta online do seu pet. "+_vm._s(_vm.tempoEstimado( teleconsulta.data_criacao, teleconsulta.hora_criacao ))+" ")]):_vm._e()])}),1):_vm._e(),(_vm.listaTeleconsultas.length == 0)?_c('div',[_c('h3',[_vm._v(" Sem teleconsultas ")])]):_vm._e()]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }