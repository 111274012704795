import HTTPService from "@/services/http_service.js";

export default class PrefeituraService extends HTTPService {
  static build() {
    return new PrefeituraService();
  }

  estadosComUnidadesAtivas() {
    return this.get("unidade/uf/ativas");
  }

  cidadesPorEstadoAtivo(id_estado) {
    return this.get(`unidade/cidades/ativas/${id_estado}`);
  }

  unidadesPorEstado(uf_id) {
    return this.get(`unidade/fisica/uf/${uf_id}`);
  }

  unidadesPorCidade(cidade_id) {
    return this.get(`unidade/fisica/cidade/${cidade_id}`);
  }

  async cadastrarPrefeitura(responseFunctions, prefeitura) {
    this.fileRequest(responseFunctions, "POST", "prefeitura/new", prefeitura);
    // return this.post("prefeitura/new", prefeitura);
  }

  editarPrefeitura(
    idPrefeitura,
    idPessoaJuridica,
    responseFunctions,
    prefeitura
  ) {
    this.fileRequest(
      responseFunctions,
      "POST",
      `prefeitura/edit/${idPrefeitura}/${idPessoaJuridica}`,
      prefeitura
    );
  }

  listarPrefeituras(filtros) {
    return this.get("prefeitura/list", filtros);
  }

  listarPrefeiturasPorNome(responseFunctions, prefeitura) {
    this.fileRequest(
      responseFunctions,
      "POST",
      "prefeitura/list/name",
      prefeitura
    );
  }

  prefeituraById(id) {
    return this.get(`prefeitura/${id}`);
  }

  getUnidadesPrefeitura(prefeituraId) {
    return this.get(`prefeitura/clinicas/${prefeituraId}`);
  }

  async cadastrarUsuarioPrefeitura(responseFunctions, usuario) {
    this.fileRequest(
      responseFunctions,
      "POST",
      "prefeitura/usuario/new",
      usuario
    );
  }

  editarUsuarioPrefeitura(responseFunctions, usuario, usuario_id) {
    this.fileRequest(
      responseFunctions,
      "POST",
      `prefeitura/usuario/edit/${usuario_id}`,
      usuario
    );
  }

  listarUsuariosPrefeitura(filtros) {
    return this.get("prefeitura/usuario/list", filtros);
  }

  getUsuarioPrefeituraById(usuarioId) {
    return this.get(`prefeitura/usuario/${usuarioId}`);
  }
}
