<template>
  <div>
    <v-card>
      <v-toolbar style="background-color: #1daf80 !important; color: #fff">
        <v-btn icon dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          <span class="headline">Termos</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <div>
            <v-select
              @change="atualizarProcedimentos"
              v-model="tipo"
              :items="tipos_termos"
              item-value="cod"
              item-text="descricao"
              label="Tipo de termo"
            ></v-select>
            <!-- <v-select
              v-model="convenioSelecionado"
              :items="convenios"
              label="Convênio"
              class="mb-2"
            >
            </v-select> -->
          </div>
          <!-- <div v-if="tipo != 'termo_dois'">
            <v-select
              v-model="procedimento"
              :items="procedimentos"
              item-value="descricao"
              item-text="descricao"
              label="Procedimento"
              class="col-10"
              :loading="loading"
            ></v-select>
          </div> -->
        </v-container>
        <div>
          <div class="text-right mr-3">
            <button
              class="btn btn-cancelar btn-padding font-weight-bold text-gray"
              @click="$emit('close')"
            >
              Cancelar
            </button>
            <button class="btn-style-green" @click="gerarPdf()">
              <span> Gerar PDF </span>
            </button>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ProcedimentoService from "@/services/procedimento_service.js";

export default {
  beforeMount() {
    this.atualizarProcedimentos();
  },

  mounted() {
    this.removeTermos();
    this.atualizarProcedimentos();
  },

  data: () => ({
    tipo: "",
    tipos_termos: [
      { cod: "termo_um", descricao: "Recusa final" },
      { cod: "termo_dois", descricao: "Autorização geral" },
      { cod: "termo_tres", descricao: "Autorização eutanasia" },
      { cod: "termo_quatro", descricao: "Autorização de imagem e vídeo" },
      { cod: "termo_cinco", descricao: "Doação de insumos e produtos" },
      {
        cod: "termo_seis",
        descricao: "Destinação de corpo de animal em óbito",
      },
      { cod: "termo_sete", descricao: "Declaração de óbito" },
      {
        cod: "termo_oito",
        descricao: "Termo de Responsabilidade para Anestesia",
      },
      {
        cod: "termo_nove",
        descricao:
          "Termo de esclarecimento e responsabilidade para retirada de animal do serviço veterinário sem alta médica",
      },
    ],
    procedimentos: [{ descricao: "Cirurgia teste" }],
    procedimento: "",
    convenios: ["Público", "Particular"],
    convenioSelecionado: "",
  }),
  methods: {
    removeTermos() {
      if (JSON.parse(sessionStorage.vuex).unidade.id == 25) {
        const listRemove = ["termo_quatro", "termo_cinco", "termo_seis"];
        for (let index = 0; index < listRemove.length; index++) {
          console.log("this.tipos_termos", this.tipos_termos);
          const indexTermo = this.tipos_termos.findIndex((ele) => {
            return ele.cod == listRemove[index];
          });
          console.log(indexTermo);
          if (indexTermo >= 0) {
            this.tipos_termos.splice(indexTermo, 1);
          }
        }
      }
    },
    atualizarProcedimentos() {
      const procedimentoService = new ProcedimentoService();
      this.loading = true;
      this.procedimentos = [];
      procedimentoService.getList({
        onSucess: (status) => (body) => {
          if (status === 200) {
            this.procedimentos = body.data.procedimentos;
          } else {
            console.log(body);
          }
        },
        onError: (error) => {
          console.log(error);
        },
        onEnd: () => {
          this.loading = false;
        },
      });
    },
    gerarPdf() {
      if (this.tipo == "") {
        console.log("Escolher um  tipo de termo");
        return;
      }
      this.$emit("gerarPDF", {
        doc: "termo",
        termo_tipo: this.tipo,
        procedimento: this.procedimento,
        convenio: this.convenioSelecionado
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase(),
      });
      this.tipo = "";
      this.procedimento = "";
      this.procedimentos = [];
    },
  },
};
</script>
<style scoped>
.btn-style-green {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #1daf80 !important;
  height: 30px !important;
  font-size: 12px;
  padding: 10px !important;
  border-radius: 50px;
  margin: 20px 10px 20px 0px;
}
</style>
