import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/index.js";
import routes from "@/router/routes.js";
// import authorizations from '@/router/authorizations'

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const { usuario } = store.state;
  const usuarioEstaLogado = Object.keys(usuario).length > 0 && usuario.id;
  const rotasPermitidas = [
    "/login",
    "/cadastro",
    "/home",
    "/novo-cadastro",
    "/primeiro-pet",
    "/esqueceu-senha",
  ];
  if (
    rotasPermitidas.includes(to.path) ||
    (from.path === "/login" && to.path === "/principal")
  ) {
    next();
  } else {
    const userHasPermission =
      (usuarioEstaLogado && 1) || to.name === "dadoscadastrais";
    // const userHasPermission = usuarioEstaLogado && Object.keys(authorizations[perfil.id]).includes(to.name) || to.name === 'dadoscadastrais';
    if (userHasPermission) {
      next();
    } else {
      next(from.name);
    }
  }
});

export default router;
