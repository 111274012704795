import { FormatarData, FormatarDataHora, LogoHeader } from "./utils_docs";

export function Prontuario(usuario, info, perguntas, adendos) {
  let content = [];

  content.push({
    columns: [
      {
        stack: [
          {
            image: LogoHeader(info.unidade.id),
            width: 55,
          },
        ],
        alignment: "left",
        border: [false, false, false, false],
      },
      {
        type: "none",
        ol: [
          { text: "Unidade", style: "text-header", bold: true },
          { text: info.unidade.razao_social, style: "text-header" },
          { text: "Endereço", style: "text-header", bold: true },
          { text: getEndereco(info.unidade), style: "text-header" },
        ],
      },
    ],
  });
  content.push([
    {
      margin: [0, 20, 0, 0],
      table: {
        widths: ["auto", "*", "auto", "*"],
        body: [
          [
            { text: "Tipo", bold: true, style: "info-anamnese" },
            {
              text: info.req_st == 5 ? "Anamnse Adicional" : "Anamnse Normal",
              style: "info-anamnese",
            },
            { text: "Procedimento", bold: true, style: "info-anamnese" },
            { text: info.procedimento, style: "info-anamnese" },
          ],
          [
            { text: "Executor", bold: true, style: "info-anamnese" },
            {
              text: info.nome_medico
                ? info.nome_medico
                : info.nome_assistente ?? "",
              style: "info-anamnese",
            },
            { text: "Data | Hora", bold: true, style: "info-anamnese" },
            {
              text: FormatarDataHora(info.data, info.hora_inicio),
              style: "info-anamnese",
            },
          ],
          [
            { text: "Tutor", bold: true, style: "info-anamnese" },
            { text: info.pacienteInfo.nome, style: "info-anamnese" },
            { text: "CPF", bold: true, style: "info-anamnese" },
            { text: info.pacienteInfo.cpf, style: "info-anamnese" },
          ],
          [
            { text: "Pet", bold: true, style: "info-anamnese" },
            { text: info.pacienteInfo.nome_pet, style: "info-anamnese" },
            { text: "Nascimento", bold: true, style: "info-anamnese" },
            {
              text: FormatarData(info.pacienteInfo.data_nascimento),
              style: "info-anamnese",
            },
          ],
          [
            { text: "Raça", bold: true, style: "info-anamnese" },
            { text: info.pacienteInfo.nome_raca, style: "info-anamnese" },
            { text: "Espécie", bold: true, style: "info-anamnese" },
            { text: info.pacienteInfo.nome_especie, style: "info-anamnese" },
          ],
        ],
      },
      layout: {
        hLineColor: "lightgray",
        vLineColor: "lightgray",
      },
    },
  ]);

  // Perguntas
  content.push([
    { text: "Anamnese", style: "title" },
    {
      table: {
        widths: ["*"],
        body: [[{ text: "", border: [false, false, false, true] }]],
      },
      layout: {
        hLineColor: "lightgray",
      },
    },
  ]);
  if (perguntas && perguntas.length > 0) {
    let bodyPerguntas = [
      [
        { text: "Pergunta", style: "search-header" },
        { text: "Resposta", style: "search-header" },
      ],
    ];
    for (let index = 0; index < perguntas.length; index++) {
      const element = perguntas[index];
      if (element.id_tipo_pergunta == 1 || element.id_tipo_pergunta == 2) {
        bodyPerguntas.push([
          { text: element.texto_pergunta, style: "search-quest" },
          { text: element.resposta_pergunta_textual, style: "search-answer" },
        ]);
      } else if (
        element.id_tipo_pergunta == 3 ||
        element.id_tipo_pergunta == 4
      ) {
        const respostasArray = element.resposta_pergunta_opcao.map(
          (resposta) => resposta.descricao
        );
        bodyPerguntas.push([
          { text: element.texto_pergunta, style: "search-quest" },
          {
            text: respostasArray.join(", ") ?? "Sem resposta",
            style: "search-answer",
          },
        ]);
      }
    }
    content.push([
      {
        style: "adendos-style",
        table: {
          widths: ["auto", "*"],
          body: bodyPerguntas,
        },
        layout: {
          vLineColor: "#e1e1e1",
          hLineColor: "#e1e1e1",
        },
      },
    ]);
  } else {
    content.push({ text: "Sem Registros de anamnese", style: "sub-title" });
  }

  // Adendos
  content.push([
    { text: "Adendos", style: "title" },
    {
      table: {
        widths: ["*"],
        body: [[{ text: "", border: [false, false, false, true] }]],
      },
      layout: {
        hLineColor: "lightgray",
      },
    },
  ]);
  if (adendos && adendos.length > 0) {
    let bodyAdendos = [];
    for (let index = 0; index < adendos.length; index++) {
      const element = adendos[index];
      bodyAdendos.push(
        [
          {
            text: element.executor,
            border: [false, false, false, false],
            style: "adendo-header",
          },
          {
            text: "",
            border: [false, false, false, false],
            style: "adendo-header",
          },
          {
            text: FormatarData(element.data),
            border: [false, false, false, false],
            style: "adendo-header",
          },
        ],
        [
          {
            text: element.descricao,
            colSpan: 3,
            style: "adendo-content",
            border: [true, false, true, true],
          },
          { text: "" },
          { text: "" },
        ]
      );
    }
    content.push({
      style: "adendos-style",
      table: {
        widths: ["auto", "*", "auto"],
        body: bodyAdendos,
      },
      layout: {
        vLineColor: "#09684a",
        hLineColor: "#09684a",
      },
    });
  } else {
    content.push({ text: "Sem Registros de adendos", style: "sub-title" });
  }

  let modelo = {
    content: content,
    styles: {
      "text-header": {
        fontSize: 8,
      },
      title: {
        fontSize: 16,
        color: "#8a8a8a",
        bold: true,
        alignment: "center",
        margin: [0, 20, 0, 0],
      },
      "sub-title": {
        bold: true,
        alignment: "center",
        margin: [0, 10, 0, 0],
      },
      "title-quest": {
        fontSize: 9,
        bold: true,
        color: "#2a2a2a",
        margin: [0, 10, 0, 4],
      },
      "text-quest": {
        fontSize: 8,
        margin: [5, 0, 0, 0],
      },
      "adendos-style": {
        margin: [0, 10, 0, 0],
      },
      "adendo-header": {
        fillColor: "#09684a",
        color: "white",
        bold: true,
      },
      "adendo-content": {},
      "search-header": {
        fontSize: 9,
        bold: true,
        fillColor: "#474747",
        color: "white",
      },
      "search-quest": {
        fontSize: 8,
        bold: true,
      },
      "search-answer": { fontSize: 8 },
      "info-anamnese": { fontSize: 8 },
    },
    defaultStyle: {
      columnGap: 20,
      fontSize: 9,
    },
    footer: function(currentPage, pageCount) {
      return {
        columns: [
          { width: 20, text: "" },
          {
            width: "*",
            text: {
              text: `Anamnese gerada por ${usuario}`,
              fontSize: 6,
            },
          },
          {
            width: "*",
            text: {
              text: "Página " + currentPage.toString() + " de " + pageCount,
              alignment: "right",
              fontSize: 6,
            },
          },
          { width: 20, text: "" },
        ],
        margin: [0, 10, 0, 0],
      };
    },
  };
  return modelo;
}

function getEndereco(item) {
  return `${item.logradouro},${item.numero} | ${item.cidade} - ${item.cep}`;
}
