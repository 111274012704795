<template>
  <div>
    <div class="p-3">
      <form class="row">
        <div class="col-4">
          <label>Estado</label>

          <v-autocomplete
            v-model="estadoSelected"
            outlined
            placeholder="Selecione um estado"
            :items="estadosList"
            item-text="descricao"
            return-object
          />
        </div>

        <div class="col-4">
          <label>Cidade</label>

          <v-autocomplete
            v-model="cidadeSelected"
            outlined
            placeholder="Selecione uma cidade"
            :items="cidadesList"
            item-text="cidade"
            no-data-text="Nenhum estado selecionado"
            :loading="loadingCidades"
          />
        </div>

        <div class="col-4">
          <label>Unidade</label>

          <v-autocomplete
            v-model="unidadeSelected"
            outlined
            placeholder="Selecione uma unidade"
            :items="unidadesList"
            item-text="nome_unidade"
            return-object
            no-data-text="Nenhuma unidade selecionada"
            :loading="loadingUnidades"
            multiple
          />
        </div>

        <div class="col-4">
          <label>Nome de identificação</label>

          <v-text-field
            v-model="nome"
            outlined
            :rules="[rules.required]"
            type="text"
            placeholder="Nome"
          />
        </div>

        <div class="col-4">
          <label>Telefone</label>

          <v-text-field
            v-model="telefone"
            outlined
            v-mask="'(##)#####-####'"
            :type="'text'"
            placeholder="(99) 99999-9999"
            :rules="[rules.required]"
          />
        </div>

        <!-- <div class="col-4">
          <label>Email</label>

          <v-text-field
            v-model="email"
            outlined
            :rules="[rules.required, rules.email]"
            :type="'email'"
            placeholder="example@email.com"
          />
        </div> -->

        <div class="col-4">
          <label>CNPJ</label>

          <v-text-field
            v-model="cnpj"
            v-mask="'##.###.###/####-##'"
            outlined
            :rules="[rules.required]"
            type="text"
            placeholder="CNPJ"
          />
        </div>

        <div class="col-4">
          <label>CEP</label>

          <v-text-field
            v-mask="'#####-###'"
            v-model="cep"
            outlined
            :rules="[rules.required]"
            type="text"
            placeholder="CEP"
          />
        </div>

        <div class="col-4">
          <label>Número</label>

          <v-text-field
            v-model="numero"
            outlined
            :rules="[rules.required]"
            type="text"
            placeholder="Número"
          />
        </div>

        <div class="col-12">
          <v-btn
            @click.prevent="editarPrefeitura"
            class="bg-verde"
            :dark="!loadingEdicao"
            rounded
            :disabled="loadingEdicao"
          >
            Salvar
          </v-btn>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import Mixin from "@/mixins/vuex_mixin.js";
import PrefeituraService from "../../services/prefeitura_service.js";

export default {
  mixins: [Mixin],
  props: {
    prefeitura: {
      require: true,
    },
  },
  data() {
    return {
      loadingCidades: false,
      estadosList: [],
      estadoSelected: {},
      cidadesList: [],
      cidadeSelected: {},
      unidadesList: [],
      unidadeSelected: [],
      nome: "",
      telefone: "",
      email: "",
      cnpj: "",
      cep: "",
      logradouro: "",
      bairro: "",
      numero: "",
      complemento: "",
      referencias: "",
      cidade: "",
      estado: "",
      rules: {
        required: (value) => !!value || "Campo obrigatório.",
        min: (v) => v.length >= 8 || "Minimo de 8 caracteres",
        emailMatch: () => `O e-mail e a senha inseridos não correspondem`,
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Email inválido.";
        },
      },
      loadingCadastro: false,
      services: {
        prefeituraService: PrefeituraService.build(),
      },
      loadingUnidades: false,
      loadingEdicao: false,
    };
  },
  async beforeMount() {
    await this.getEstados();
    await this.carregarPrefeitura();
  },
  watch: {
    estadoSelected(value, oldValue) {
      if (value != oldValue && Object.keys(oldValue).length > 0) {
        this.getCidades(this.estadoSelected.id, false);
      } else {
        this.getCidades(this.estadoSelected.id, true);
      }
    },
  },
  methods: {
    getEstados() {
      this.services.prefeituraService
        .estadosComUnidadesAtivas()
        .then((resp) => resp.json())
        .then((resp) => {
          this.estadosList = resp.data.estados;
        });
    },
    getCidades(id_estado, estadoInicial) {
      this.services.prefeituraService
        .cidadesPorEstadoAtivo(id_estado)
        .then((resp) => resp.json())
        .then((resp) => {
          if (!estadoInicial) {
            console.log(estadoInicial);
            this.unidadeSelected = [];
          }
          this.cidadesList = resp.data.cidades;
        })
        .then(() => {
          this.cidadeSelected = this.cidadesList.find(
            (cidade) => cidade.cidade == this.prefeitura.cidade
          );
        })
        .then(() => {
          this.carregarUnidadesPorEstado();
        });
    },
    editarPrefeitura() {
      this.loadingEdicao = true;
      let unidades_prefeitura = "";
      if (this.unidadeSelected.length > 0) {
        unidades_prefeitura = this.unidadeSelected.map(
          (unidade) => unidade.id_unidade
        );

        unidades_prefeitura = unidades_prefeitura.join(",");
      }

      const formData = new FormData();
      formData.append("estado", this.estadoSelected.descricao);
      formData.append("cidade", this.cidadeSelected.cidade);
      formData.append("nome", this.nome);
      formData.append("telefone", this.telefone);
      formData.append("email", this.email);
      formData.append("cnpj", this.cnpj);
      formData.append("cep", this.cep);
      formData.append("numero", this.numero);
      formData.append("unidade", unidades_prefeitura);
      formData.append("ativo", 1);

      const responseFunctions = {
        onSucess: () => {
          this.$_ACTIONS_showSnackbarMessage({
            message: "Prefeitura editada com sucesso!",
            color: "sucess",
          });
          this.$emit("finalizarEdicao");
        },
        onError: (error) => {
          console.error(error);
          this.$_ACTIONS_showSnackbarMessage({
            message: this.$global.messages.internalServerError,
            color: "error",
          });
        },
        onEnd: () => {
          this.loadingEdicao = false;
        },
      };

      this.services.prefeituraService.editarPrefeitura(
        this.prefeitura.id,
        this.prefeitura.id_pessoa_juridica,
        responseFunctions,
        formData
      );
    },
    async carregarPrefeitura() {
      await this.services.prefeituraService
        .prefeituraById(this.prefeitura.id)
        .then((resp) => {
          return resp.json();
        })
        .then((resp) => {
          const unidades = resp.data.Clinicas;
          const prefeitura = resp.data.Prefeitura[0];

          this.nome = prefeitura.nome_fantasia;
          this.telefone = prefeitura.telefone_1;
          this.numero = prefeitura.numero;
          this.cnpj = prefeitura.cnpj;
          this.cep = prefeitura.cep;
          this.email = prefeitura.email;

          return { unidades, prefeitura };
        })
        .then(({ unidades, prefeitura }) => {
          this.estadoSelected = this.estadosList.find((estado) => {
            if (prefeitura.estado.length == 2) {
              return estado.sigla == prefeitura.estado;
            } else {
              return estado.descricao == prefeitura.estado;
            }
          });

          return { unidades, prefeitura };
        })
        .then(({ unidades }) => {
          this.carregarUnidadesPorEstado(unidades);
        });
    },
    carregarUnidadesPorEstado(unidades) {
      if (this.estadoSelected.id) {
        this.services.prefeituraService
          .unidadesPorEstado(this.estadoSelected.id)
          .then((resp) => resp.json())
          .then((resp) => {
            this.unidadesList = resp.data;
          })
          .then(() => {
            if (unidades) {
              unidades.forEach((unidade) => {
                const unidade_encontrada = this.unidadesList.find(
                  (un) => un.id_unidade == unidade.id
                );
                console.log(unidades);
                if (unidade_encontrada) {
                  this.unidadeSelected.push(unidade_encontrada);
                }
              });
            }
          });
      }
    },
  },
};
</script>
