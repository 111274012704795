<template>
  <v-card>
    <v-toolbar class="bg-verde mb-5">
      <h3 class="text-white">
        Incluir Item
      </h3>
      <v-spacer></v-spacer>
      <v-btn icon @click="$emit('close')" dark>
        <v-icon>mdi-close-thick</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="pt-5 ">
      <v-select
        :items="tiposProcedimento"
        v-model="tipoProcedimentoSelected"
        item-value="id"
        item-text="descricao"
        outlined
        label="Tipo de procedimento"
      ></v-select>
      <v-autocomplete
        outlined
        label="Procedimento"
        :items="procedimentos"
        :loading="loadingProcedimentos"
        :disabled="loadingProcedimentos || procedimentos.length == 0"
        v-model="procedimentoSelected"
        item-text="procedimento_descricao"
        item-value="procedimento_id"
        no-data-text="Escolha um tipo de procedimento"
      ></v-autocomplete>
      <v-data-table
        :headers="conveniosHeaders"
        :items="conveniosProcedimento"
        :hide-default-footer="true"
        item-key="convenio_procedimento_id"
        show-select
        single-select
        v-model="convenioSelected"
        class="mb-4"
      >
        <template v-slot:top>
          <v-toolbar flat color="#F5F5F5">
            <v-toolbar-title>Convênio</v-toolbar-title>
          </v-toolbar>
        </template>
      </v-data-table>
      <v-autocomplete
        outlined
        label="Executante"
        :items="executantes"
        v-model="executanteSelected"
        :loading="loadingExecutantes"
        :disabled="loadingExecutantes || executantes.length == 0"
        :item-text="getLabelExecutor"
        item-value="id"
      >
        <template v-slot:item="{ item }">
          <v-list-item-content>
            <v-list-item-title v-html="item.nome"></v-list-item-title>
            <v-list-item-subtitle class="mt-1"
              >ID: {{ item.id }} | {{ item.descricao }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-autocomplete>
      <v-row>
        <v-col cols="6">
          <v-text-field
            label="Data"
            type="date"
            outlined
            v-model="dataExecutar"
            :messages="'Data não pode ser antes de ' + comanda.data_criada"
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            cols="4"
            label="Hora"
            type="time"
            outlined
            v-model="horaExecutar"
            :messages="'Hora não pode ser antes de ' + comanda.hora_criada"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="pb-5">
      <div class="d-flex w-100 justify-end">
        <v-btn
          color="error"
          text
          @click="$emit('close')"
          :disabled="loadingInclusao"
          >Cancelar</v-btn
        >
        <v-btn
          class="bg-verde ml-2"
          dark
          @click="incluirItemComanda"
          :disabled="loadingInclusao"
          >Incluir</v-btn
        >
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import Mixin from "@/mixins/vuex_mixin.js";
import VuexSnackbarMixin from "@/mixins/vuex_snackbar_mixin.js";
import ProcedimentoService from "../../services/procedimento_service";
import TipoProcedimentoService from "../../services/tipo_procedimento_service";
import AuditoriaService from "@/services/auditoria_service.js";
import AssistenteService from "@/services/assistente_service.js";
import ConvenioService from "@/services/convenio_service.js";

export default {
  mixins: [Mixin, VuexSnackbarMixin],
  props: {
    comanda: {
      require: true,
    },
  },
  data() {
    return {
      tiposProcedimento: [
        "Consulta",
        "Exame",
        "Medicação",
        "Cirurgia",
        "Internação",
      ],
      services: {
        tipoProcedimentoService: TipoProcedimentoService.build(),
        procedimentoService: ProcedimentoService.build(),
        auditoriaService: AuditoriaService.build(),
        convenioService: ConvenioService.build(),
        assistenteService: new AssistenteService(),
      },
      tipoProcedimentoSelected: "",
      unidadeId: "",
      loadingProcedimentos: false,
      procedimentos: [],
      procedimentoSelected: "",
      executantes: [],
      executantesList: [],
      executanteSelected: "",
      loadingExecutantes: false,
      dataExecutar: "",
      horaExecutar: "",
      conveniosProcedimento: [],
      conveniosHeaders: [
        {
          text: "Descrição",
          align: "start",
          sortable: false,
          key: "name",
          value: "convenio_descricao",
        },
        { text: "Convênio", value: "tipo_convenio_descricao" },
        { text: "Valor", value: "valor" },
        { text: "", value: "actions", sortable: false },
      ],
      convenioSelected: [],
      loadingInclusao: false,
    };
  },
  watch: {
    tipoProcedimentoSelected() {
      this.getProcedimentos();
      this.executanteSelected = "";
      this.listExecutantes();
    },
    procedimentoSelected() {
      this.getConveniosProcedimento();
    },
    convenioSelected() {
      console.log(this.convenioSelected);
    },
    executanteSelected() {
      console.log(this.executanteSelected);
    },
  },
  mounted() {
    this.unidadeId = JSON.parse(sessionStorage.getItem("vuex")).unidade.id;
    this.getTiposProcedimentos();
    this.getExecutantes();
  },
  methods: {
    getTiposProcedimentos() {
      this.services.tipoProcedimentoService
        .getTipoProcedimentos()
        .then((resp) => resp.json())
        .then((resp) => {
          // this.tiposProcedimento = resp.data.tipo_procedimentos.filter((item) =>
          //   [4, 5].includes(item.id)
          // );
          this.tiposProcedimento = resp.data.tipo_procedimentos.filter((item) =>
            [2, 3, 4, 5].includes(item.id)
          );
        });
    },
    getProcedimentos() {
      this.loadingProcedimentos = true;
      this.services.procedimentoService
        .getProcedimentosPorUnidadeEprocedimento(
          this.unidadeId,
          this.tipoProcedimentoSelected
        )
        .then((resp) => {
          this.procedimentos = resp;
        })
        .finally(() => (this.loadingProcedimentos = false));
    },
    async getExecutantes() {
      this.loadingExecutantes = true;
      // const responseFunction = {
      //   onSucess: (status) => (resp) => {
      //     if (status == 200) {
      //       this.executantes = resp.data.map((ass) => {
      //         return {
      //           nome: ass.nome,
      //           id: ass.id_assistente,
      //         };
      //       });
      //     }
      //   },
      //   onError: () => {},
      //   onEnd: () => {
      //     this.loadingExecutantes = false;
      //   },
      // };

      // await this.services.assistenteService.getAssistenteByUnidadeId(
      //   responseFunction,
      //   this.unidadeId
      // );
      await this.services.auditoriaService
        .getExecutanteByUnidade(this.unidadeId)
        .then(async (response) => {
          console.log("res", response);
          if (response.status == 200) {
            const { data } = await response.json();
            this.executantesList = data;
            this.loadingExecutantes = false;
          } else {
            console.error(response);
            this.loadingExecutantes = false;
          }
        })
        .catch((e) => console.error(e))
        .finally(() => {
          this.loadingExecutantes = false;
        });
      //   if (["4", "5"].includes(this.tipoProcedimentoSelected.toString())) {
      //   } else {
      //     // Médico
      //   }
    },
    listExecutantes() {
      this.executantes = [];
      this.loadingExecutantes = true;
      for (let index = 0; index < this.executantesList.length; index++) {
        const executante = this.executantesList[index];
        if (executante.descricao == "Assistente") {
          if (
            this.tipoProcedimentoSelected == 4 ||
            this.tipoProcedimentoSelected == 5
          ) {
            this.executantes.push(executante);
          }
        } else {
          this.executantes.push(executante);
        }
      }
      this.executantes.sort((a, b) => {
        if (a.nome < b.nome) return -1;
        if (a.nome > b.nome) return 1;
        return 0;
      });
      this.loadingExecutantes = false;
    },
    getLabelExecutor(item) {
      return `${item.nome} - ${item.descricao}`;
    },
    getConveniosProcedimento() {
      this.services.convenioService
        .getConvenioPorUnidadeProcedimento(
          this.unidadeId,
          this.procedimentoSelected
        )
        .then((resp) => resp.json())
        .then(({ data }) => {
          this.conveniosProcedimento = data;
        });
    },
    incluirItemComanda() {
      if (
        this.convenioSelected.length < 1 ||
        !this.executanteSelected ||
        !this.dataExecutar ||
        !this.horaExecutar
      ) {
        this.$_ACTIONS_showSnackbarMessage({
          message: "Preencha os campos corretamente",
          color: "error",
        });
        return;
      }

      const dataExecucaoProcedimento = new Date(this.dataExecutar.split("-"));
      const dataCriacaoComanda = new Date(
        this.comanda.data_criada.split("/").reverse()
      );
      const dataInvalida = dataCriacaoComanda > dataExecucaoProcedimento;

      if (dataInvalida) {
        this.$_ACTIONS_showSnackbarMessage({
          message:
            "A data selecionada não pode ser anterior à data de criação da comanda",
          color: "error",
        });
        return;
      }

      this.loadingInclusao = true;

      const detalhesItemComanda = {
        id_cliente: this.comanda.id_cliente,
        id_pet: this.comanda.id_pet,
        id_unidade: this.unidadeId,
        id_comanda: this.comanda.comanda_id,
        id_convenio_procedimento: this.convenioSelected[0]
          .convenio_procedimento_id,
        id_usuario: this.executanteSelected,
        data_exec: this.dataExecutar,
        hora_exec: this.horaExecutar + ":00",
      };

      // const detalhesItemComanda = {
      //   id_cliente: this.comanda.id_cliente,
      //   id_pet: this.comanda.id_pet,
      //   id_unidade: this.unidadeId,
      //   id_comanda: this.comanda.comanda_id,
      //   id_convenio_procedimento: this.convenioSelected[0]
      //     .convenio_procedimento_id,
      //   assistente_id: this.executanteSelected,
      //   data_exec: this.dataExecutar,
      //   hora_exec: this.horaExecutar + ":00",
      // };

      // console.log(detalhesItemComanda);

      const responseFunction = {
        onSucess: (status) => (resp) => {
          this.loadingInclusao = false;
          if (status == 200) {
            this.$_ACTIONS_showSnackbarMessage({
              message: "Item adicionado a comanda com sucesso",
              color: "sucess",
            });
            this.$emit("close");
            this.$emit("carregarItensComanda");
          } else {
            console.log(resp);
            this.$_ACTIONS_showSnackbarMessage({
              message: resp.message,
              color: "error",
            });
          }
        },
        onError: () => {
          this.$_ACTIONS_showSnackbarMessage({
            message: this.$global.messages.internalServerError,
            color: "error",
          });
          this.loadingInclusao = false;
        },
        onEnd: () => {
          this.loadingInclusao = false;
        },
      };

      this.services.auditoriaService.auditoriaIncluirExameComandaAdmin(
        responseFunction,
        detalhesItemComanda
      );
      // this.services.auditoriaService.auditoriaIncluirExameComanda(
      //   responseFunction,
      //   detalhesItemComanda
      // );
    },
  },
};
</script>

<style></style>
