<template>
  <div>
    <div class="p-lg-3">
      <div class="row d-flex flex-column">
        <h3 class="mt-0 mb-0 text-left">
          Bem vindo!
        </h3>
        <v-btn
          class="mx-2 "
          fab
          dark
          small
          color="green"
          fixed
          right="15px"
          bottom="15px"
          @click="whatsapp()"
        >
          <v-icon dark>mdi-whatsapp</v-icon>
        </v-btn>
        <h4 class="capitalize text-left ">
          {{ $_GETTERS_usuario.dados.nome.toLowerCase() }}
        </h4>
      </div>
    </div>
    <v-divider></v-divider>
    <div v-if="!petCadastrado">
      <v-dialog
        persistent
        fullscreen
        v-model="dialogCadastrar"
        max-width="500px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="#1daf80" dark class="mb-2" v-bind="attrs" v-on="on">
            Cadastre seu primeiro Pet
          </v-btn>
        </template>
        <modal-cadastro
          v-if="dialogCadastrar"
          @close="dialogCadastrar = false"
          @response="verificarPet"
        />
      </v-dialog>
    </div>
    <div class="row justify-content-between my-6 px-4" v-else>
      <v-dialog
        persistent
        fullscreen
        v-model="dialogAgendarTelevet"
        max-width="500px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark outlined color="#1daf80" v-bind="attrs" v-on="on">
            Nova consulta Televet
          </v-btn>
        </template>
        <v-card v-if="dialogAgendarTelevet">
          <v-toolbar style="background-color: #1daf80; color: #fff">
            <v-btn icon dark @click="dialogAgendarTelevet = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>
              <span class="headline">Nova Consulta TeleVet</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items> </v-toolbar-items>
          </v-toolbar>
          <agendar-televet
            v-if="dialogAgendarTelevet"
            @close="dialogAgendarTelevet = false"
            @response="agendamentoCadastrado"
          />
        </v-card>
      </v-dialog>
      <v-btn
        dark
        color="#1daf80"
        @click="
          visualizarTelevet = true;
          getFilaCliente();
        "
      >
        {{ visualizarTelevet ? "Atualizar" : "Visualizar Consultas Televet" }}
      </v-btn>
    </div>
    <div v-if="visualizarTelevet">
      <div v-if="listaTeleconsultas.length > 0" class="cards-style">
        <v-card
          elevation="2"
          class="mx-auto"
          width="100%"
          v-for="teleconsulta in listaTeleconsultas"
          :key="teleconsulta.id_requisicao_procedimento"
        >
          <div class="style-card-teleconsulta">
            <div class="text-left">
              <font class="font-weight-bold">Pet:</font>
              {{ teleconsulta.nome_pet }}
            </div>
            <div class="text-left">
              <font class="font-weight-bold">Raça:</font>
              {{ teleconsulta.nome_raca }}
            </div>
            <div class="text-left">
              <font class="font-weight-bold">Data:</font>
              {{
                formatarData(
                  teleconsulta.data_criacao,
                  teleconsulta.hora_criacao
                )
              }}
            </div>
            <div class="text-right">
              <v-btn
                v-if="teleconsulta.id_medico != null"
                dark
                color="#2C7091"
                small
                width="139px"
                @click="
                  getSalaConferencia(teleconsulta.id_requisicao_procedimento)
                "
              >
                Entrar na Sala
              </v-btn>
              <v-btn v-else disabled color="#f0a211" width="139px" small>
                Aguardando
              </v-btn>
            </div>
          </div>
          <div class="style-card-tempo" v-if="teleconsulta.id_medico == null">
            Olá! Estamos agilizando a consulta online do seu pet.
            {{
              tempoEstimado(
                teleconsulta.data_criacao,
                teleconsulta.hora_criacao
              )
            }}
          </div>
        </v-card>
      </div>
      <div v-if="listaTeleconsultas.length == 0">
        <h3>
          Sem teleconsultas
        </h3>
      </div>
    </div>
  </div>
</template>

<script>
import VuexMixin from "@/mixins/vuex_mixin";
import FilaClienteService from "../services/fila_cliente_service.js";
import AgendamentoSalaService from "../services/agendamento_sala_service.js";
import PetsService from "../services/pets_service.js";
import AgendarTelevet from "@/components/televet/modal_cadastro_cliente.vue";
import modalCadastro from "@/components/pets/modal_cadastro";

export default {
  mixins: [VuexMixin],
  components: {
    AgendarTelevet,
    modalCadastro,
  },
  data: () => ({
    listaTeleconsultas: [],
    dialogCadastrar: false,
    petCadastrado: false,
    dialogAgendarTelevet: false,
    visualizarTelevet: false,
    service: {
      filaClienteService: FilaClienteService.build(),
      agendamentoSalaService: AgendamentoSalaService.build(),
      petService: PetsService.build(),
    },
  }),
  mounted() {
    this.verificarPet(true);
    this.loopFilaCliente();
    setTimeout(() => {
      this.getFilaCliente();
    }, 10 * 1000);
  },
  methods: {
    loopFilaCliente() {
      const perfilId = JSON.parse(sessionStorage.vuex).perfil.id;
      if (perfilId != undefined) {
        this.getFilaCliente();
        setTimeout(() => {
          if (perfilId == 4) {
            this.loopFilaCliente();
          }
        }, 180 * 1000);
      } else {
        this.loopFilaCliente();
      }
    },
    getClienteId() {
      let clienteId = JSON.parse(sessionStorage.vuex).usuario.dados.clienteId;
      if (clienteId != undefined) {
        return clienteId;
      } else {
        this.getClienteId();
      }
    },
    async verificarPet(primeiraVez = false) {
      await this.service.petService
        .getPetsByCliente()
        .then(async (response) => {
          if (response.status !== 200) return;
          const { data } = await response.json();
          const { pets } = data;
          this.petCadastrado = pets.length > 0 ? true : false;
        })
        .finally(() => {
          if (primeiraVez && this.petCadastrado == false) {
            this.dialogCadastrar = true;
          }
        });
    },
    async getFilaCliente() {
      this.listaConsultas = [];
      const clienteId = this.getClienteId();
      await this.service.filaClienteService
        .getListByCliente(clienteId, {
          page: "1",
          per_page: "50",
          sort: "",
          desc: "",
        })
        .then(async (response) => {
          if (response.status !== 200) return;
          const { data } = await response.json();
          this.listaTeleconsultas = data.filas;
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {});
    },
    async getSalaConferencia(idRequisicaoProcedimento) {
      await this.service.agendamentoSalaService
        .getSalaAgendamento(idRequisicaoProcedimento)
        .then(async (response) => {
          if (response.status !== 200) return;
          const { data } = await response.json();
          const url = data.urlmeeting;
          window.open(url, "_blank");
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {});
    },
    formatarData(data, hora) {
      let arrData = data.split("-");
      let arrHora = hora.split(":");
      return `${arrData[2]}/${arrData[1]}/${arrData[0]} ${arrHora[0]}:${arrHora[1]}`;
    },
    agendamentoCadastrado() {
      this.dialogAgendarTelevet = false;
      this.visualizarTelevet = true;
      this.getFilaCliente();
    },
    whatsapp() {
      window.open("https://www.google.com.br", "_blank");
    },
    tempoEstimado(dataConsulta, horaConsulta) {
      let dateNow = new Date();
      let dateConsulta = new Date(`${dataConsulta}T${horaConsulta}`);
      let diffTime = 15 - (dateNow.valueOf() - dateConsulta.valueOf()) / 60000;
      let mensagem = "Aguarde você será atendido em breve!";
      if (parseInt(diffTime) > 0) {
        mensagem = ` O tempo médio de espera é de até ${parseInt(
          diffTime
        )} minutos. Por favor,
            aguarde :)`;
      }
      return mensagem;
    },
  },
};
</script>
<style scoped>
.capitalize {
  text-transform: capitalize;
}
.cards-style {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.style-card-tempo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-weight: bold;
}
.style-card-teleconsulta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.style-card-teleconsulta div {
  width: 25%;
}
</style>
