<template>
  <!-- <div class="d-flex col-12 flex-column"> -->
  <div class="d-flex row align-items-center flex-column">
    <label class="col-10 mb-0 pb-0">{{ pergunta.texto_pergunta }}</label>
    <v-text-field
      class="col-10"
      v-if="pergunta.obrigatorio"
      v-model="resposta.resposta"
      :rules="regras"
      :disabled="!disabled"
      @change="watchChange"
      @blur="respondido"
    ></v-text-field>
    <v-text-field
      class="col-10"
      v-else
      :disabled="!disabled"
      v-model="resposta.resposta"
      @change="watchChange"
      @blur="respondido"
    ></v-text-field>
  </div>
</template>
<script>
import Mixin from "@/mixins/vuex_snackbar_mixin.js";

export default {
  mixins: [Mixin],
  props: {
    pergunta: Object,
    disabled: Boolean,
  },
  beforeMount() {
    this.resposta = {
      id_pergunta: this.pergunta.id,
      resposta: this.pergunta.resposta_pergunta_textual,
    };
  },
  methods: {
    respondido() {
      if (this.resposta.resposta != "") {
        this.$emit("resposta", this.resposta);
      }
    },

    watchChange() {
      this.$emit("rchange", this.resposta.resposta);
    },
  },
  data: () => ({
    resposta: {},
    regras: [(value) => !!value || "Obrigatório."],
  }),
};
</script>
<style scoped>
.v-progress-circular {
  margin: 1rem;
}
label {
  font-weight: 900;
  font-size: 1.2rem;
  margin-bottom: 32px !important;
}
</style>
