import HTTPService from "@/services/http_service.js";

export default class AuditoriaService extends HTTPService {
  static build() {
    return new AuditoriaService();
  }
  async getConsultasbyComandaId(comandaId) {
    return await this.get(`audit/comanda/prontuario/${comandaId}`);
  }

  async getAssistentesbyRequisicaoId(requisicaoId) {
    return await this.get(`audit/comanda/assistentes-disp/${requisicaoId}`);
  }

  async getExecutanteByUnidade(unidadeId) {
    return await this.get(
      `audit/comanda/acesso-requisicao-perfil/${unidadeId}`
    );
  }

  async auditoriaExecutarRequisicao(detalhesExecucao) {
    return this.post("audit/comanda/exec-exame", detalhesExecucao);
  }

  async auditoriaExecutarRequisicaoNovo(detalhesExecucao) {
    return this.post("audit/comanda/admin-exec-req", detalhesExecucao);
  }

  async auditoriaExcluirItemComanda(responseFunctions, requisicaoId) {
    // return this.post("audit/comanda/excluir-exame", {
    //   requisicao_id: requisicaoId,
    // });
    this.jsonRequest(responseFunctions, "POST", "audit/comanda/excluir-exame", {
      requisicao_id: requisicaoId,
    });
  }

  async auditoriaExcluirConsultaComanda(responseFunctions, requisicaoId) {
    this.jsonRequest(
      responseFunctions,
      "POST",
      "audit/comanda/excluir-consulta",
      {
        requisicao_id: requisicaoId,
      }
    );
  }

  auditoriaIncluirExameComanda(responseFunctions, itemComanda) {
    return this.request(
      responseFunctions,
      "POST",
      "audit/comanda/new-req-exame",
      itemComanda
    );
  }

  auditoriaIncluirExameComandaAdmin(responseFunctions, itemComanda) {
    return this.request(
      responseFunctions,
      "POST",
      "audit/comanda/novo-req-admin",
      itemComanda
    );
  }

  async deleteAnamnesebyAudit(idRequisicao) {
    return await this.deleteJson(
      `anamnese/requisicao/admin/excluir/${idRequisicao}`,
      {}
    );
  }

  //
  async getCheckComandaAnamnese(idRequisicao) {
    return await this.get(
      `audit/comanda/getRespostaFormulario/${idRequisicao}`
    );
  }
}
