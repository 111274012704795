<template>
  <div class="d-flex flex-wrap col-12">
    <div class="d-flex flex-colunm col-12">
      <label>{{ pergunta.texto_pergunta }}</label>
    </div>
    <div class="d-flex flex-wrap col-12">
      <div v-for="(opt_check, i) in pergunta.opcoes" :key="i">
        <div class="d-flex">
          <v-checkbox
            v-model="selecionados"
            :label="opt_check.descricao"
            :value="+opt_check.id"
            :disabled="!disabled"
            @change="watchChange"
          ></v-checkbox>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Mixin from "@/mixins/vuex_snackbar_mixin.js";

export default {
  mixins: [Mixin],
  props: {
    pergunta: Object,
    disabled: Boolean,
  },
  beforeMount() {
    this.respostaConsulta(this.pergunta.resposta_pergunta_opcao);
    this.resposta = {
      id_pergunta: this.pergunta.id,
      resposta: [],
    };
  },
  data: () => ({
    selecionados: [],
    resposta: {},
  }),
  methods: {
    respondido() {
      this.resposta.resposta = this.selecionados;
      this.$emit("resposta", this.resposta);
    },
    respostaConsulta(respostas) {
      for (let index = 0; index < respostas.length; index++) {
        this.selecionados.push(+respostas[index].id);
      }
    },
    watchChange() {
      this.resposta.resposta = this.selecionados;
      this.$emit(
        "rchange",
        this.resposta.resposta.map((r) => ({
          resposta_opcao: r,
        }))
      );
    },
  },
};
</script>
<style scoped>
.v-progress-circular {
  margin: 1rem;
}
label {
  font-weight: 900;
  font-size: 1.2rem;
}
</style>
