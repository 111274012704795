<template>
  <v-card class="pb-5">
    <v-flex>
      <v-toolbar class="bg-verde" dark>
        <v-toolbar-title>Iniciar Atendimento</v-toolbar-title>
        <v-spacer />
        <v-btn icon dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
    </v-flex>
    <v-tabs v-model="tab" background-color="transparent" color="#1daf80" grow>
      <v-tab key="formulario">Formulário</v-tab>
      <v-tab key="anamnese" :disabled="formularioSelected == ''"
        >Anamnese</v-tab
      >
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item key="formulario">
        <div class="p-2 row justify-content-center">
          <div class="col-lg-8">
            <div class="mx-auto">
              <div class="mt-8">
                <label>Formulário</label>
                <v-select
                  v-model="formularioSelected"
                  :items="formularioList"
                  item-text="titulo"
                  item-value="id"
                  outlined
                />
              </div>
            </div>
          </div>
        </div>
      </v-tab-item>
      <v-tab-item key="anamnese">
        <atendimento
          v-if="formularioSelected != ''"
          :paciente="infosPaciente"
          :info="{
            idAgendamento: infos.idAgendamento,
            idFormulario: formularioSelected,
          }"
          :idRequisicao="infos.idRequesicao"
          :idFormulario="formularioSelected"
          @finalizarConsulta="$emit('finalizarConsulta')"
        />
      </v-tab-item>
    </v-tabs-items>
    <v-dialog v-model="dialogLoading" width="500" persistent>
      <div class="card-style">
        <h3>Processando, aguarde.</h3>
        <v-progress-linear indeterminate color="#1daf80"></v-progress-linear>
      </div>
    </v-dialog>
  </v-card>
</template>

<script>
// import ComandaService from "@/services/comanda_service.js";
import FormularioService from "@/services/formulario_service.js";
import Atendimento from "@/components/dashboards/dashboard_medico/modal_atender_recepcao/atendimento.vue";

export default {
  components: { Atendimento },
  props: {
    infos: { type: Object, required: true },
    infosPaciente: { type: Object, required: true },
  },
  data() {
    return {
      services: {
        // comandaService: ComandaService.build(),
        formularioService: new FormularioService(),
        // agendamentoService: AgendamentoService.build(),
      },
      formularioList: [],
      formularioSelected: "",
      comandaInfo: {},
      dialogLoading: false,
      tab: "",
    };
  },
  computed: {},
  watch: {
    formularioSelected() {
      this.tab = 1;
    },
  },
  mounted() {
    // this.getComandaInfo();
    this.carregarTiposFormularios();
  },
  methods: {
    // async getComandaInfo() {
    //   await this.services.comandaService
    //     .getDadosComandasByIdRequisicao(this.infos.idRequesicao)
    //     .then(async (response) => {
    //       console.log("response", response);
    //       // const resp = await response.json();
    //       if (response.status == 200) {
    //         console.log("response", response);
    //         // this.comandaInfo = resp;
    //         // Modelo Resposta
    //         // {
    //         //   id:	"20727"
    //         //   data_criada:	"2024-05-17"
    //         //   hora_criada:	"15:37:06"
    //         //   id_usuario_criador:	"6810"
    //         //   criado_por:	"Erika Moreno"
    //         //   id_usuario_fechou:	null
    //         //   fechado_por:	null
    //         //   data_fechada:	"1900-01-01"
    //         //   hora_fechada:	"00:00:00"
    //         //   id_cliente:	"7119"
    //         //   nome_tutor:	"ROSANGELA FREDERICO"
    //         //   id_pet:	"1494"
    //         //   nome_pet:	"BAHI"
    //         //   id_unidade:	"25"
    //         //   unidade:	"Ferraz de Vasconcelos"
    //         //   data_atual:	"2024-05-24"
    //         //   hora_atual:	"16:17:43"
    //         // }
    //       }
    //     })
    //     .catch((e) => {
    //       console.error(e);
    //     })
    //     .finally(() => {});
    // },
    async carregarTiposFormularios() {
      await this.services.formularioService.getAll(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.formularioList = body.data.formularios;
            }
          },
          onError: (error) => {
            console.log(error);
          },
          onEnd: () => {},
        },
        {
          procedimento: parseInt(this.infos.idProcedimento),
          ativo: 1,
        },
        this.infos.medicoId
      );
    },
  },
};
</script>
<style scoped>
.card-style {
  padding: 10px 15px 20px;
  background-color: white;
}
</style>
