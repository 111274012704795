<template>
  <v-card class="pb-5">
    <v-flex>
      <v-toolbar class="bg-verde" dark>
        <v-toolbar-title>Iniciar Atendimento</v-toolbar-title>
        <v-spacer />
        <v-btn icon dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
    </v-flex>
    <v-tabs v-model="tab" background-color="transparent" color="#1daf80" grow>
      <v-tab key="novo-atendimento">Novo Atendimento</v-tab>
      <v-tab key="requisicao">Atender Requisições</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item key="novo-atendimento">
        <div class="p-2 row justify-content-center">
          <div class="col-lg-8">
            <div class="mx-auto">
              <h3>
                Escolha
                {{
                  convenioAtendimento == "publico"
                    ? "o procedimento:"
                    : "a requisição:"
                }}
              </h3>
              <div class="mt-8">
                <v-autocomplete
                  v-model="procedimento"
                  :items="procedimentos"
                  background-color="white"
                  label="Procedimentos"
                  item-text="procedimento"
                  return-object
                  color="black"
                >
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title v-html="item.procedimento" />
                      <v-list-item-subtitle
                        class="mt-1"
                        v-html="
                          item.tipo_procedimento +
                            ' - ' +
                            item.convenio_tipo +
                            ` ${item.data_requisicao &&
                              '( ' +
                                item.data_requisicao
                                  .split('-')
                                  .reverse()
                                  .join('-') +
                                ' )'}`
                        "
                      />
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
                <v-btn @click="iniciarAtendimento">Iniciar atendimento</v-btn>
              </div>
            </div>
          </div>
        </div>
      </v-tab-item>
      <v-tab-item key="requisicao">
        <div class="p-2 row justify-content-center">
          <div class="col-lg-12">
            <v-data-table
              :headers="headers"
              :items="resquisicoesList"
              :items-per-page="5"
              height="300"
              class="elevation-1"
            >
              <template v-slot:item.data_hora="{ item }">
                {{ formatarData(item) }}
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn
                  elevation="2"
                  fab
                  x-small
                  @click="atenderRequisicao(item)"
                  title="Atender requisição"
                  color="green"
                >
                  <v-icon color="white">
                    mdi-clipboard-pulse
                  </v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </div>
        </div>
      </v-tab-item>
    </v-tabs-items>
    <v-dialog v-model="dialogLoading" width="500" persistent>
      <div class="card-style">
        <h3>Processando, aguarde.</h3>
        <v-progress-linear indeterminate color="#1daf80"></v-progress-linear>
      </div>
    </v-dialog>
  </v-card>
</template>

<script>
import AgendamentoService from "@/services/agendamento_service";
import RequisicaoExameService from "@/services/requisicao_exame_service.js";
import Mixin from "@/mixins/vuex_mixin.js";

export default {
  mixins: [Mixin],
  components: {},
  props: {
    procedimentos: { type: Array, required: true },
    infos: { type: Object, required: true },
    convenioAtendimento: { type: String, required: true },
  },
  data() {
    return {
      requisicaoExameService: RequisicaoExameService.build(),
      agendamentoService: AgendamentoService.build(),
      procedimento: "",
      tab: "",
      resquisicoesList: [],
      headers: [
        {
          text: "Tipo",
          align: "start",
          sortable: false,
          value: "tipo_procedimento",
        },
        {
          text: "Procedimento",
          align: "start",
          sortable: false,
          value: "procedimento",
        },
        {
          text: "Requisitante",
          align: "start",
          sortable: false,
          value: "requisitante",
        },
        {
          text: "Perfil",
          align: "start",
          sortable: false,
          value: "perfil_requisitante",
        },
        {
          text: "Data",
          align: "start",
          sortable: false,
          value: "data_hora",
        },
        {
          text: "Ação",
          align: "start",
          sortable: false,
          value: "actions",
        },
      ],
      dialogLoading: false,
    };
  },
  computed: {},
  mounted() {
    this.getRequisicao();
  },
  methods: {
    teste() {
      this.$_ACTIONS_showSnackbarMessage({
        message: "teste",
        color: "error",
      });
    },
    formatarData(requisicao) {
      const arrData = requisicao.data_requisicao.split("-");
      const arrHora = requisicao.hora_requisicao.split(":");
      return `${arrData[2]}/${arrData[1]}/${arrData[0]} ${arrHora[0]}:${arrHora[1]}`;
    },
    getRequisicao() {
      this.requisicaoExameService
        .getListaRequisicaoSemAgendamento(
          this.infos.petId,
          this.infos.unidadeId
        )
        .then(async (response) => {
          const { data } = await response.json();
          this.resquisicoesList = data;
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {});
    },
    async atenderRequisicao(requisicao) {
      this.dialogLoading = true;
      this.agendamentoService
        .atenderRequisicao({
          id_medico: this.infos.medicoId,
          id_unidade: this.infos.unidadeId,
          id_requisicao: parseInt(requisicao.id),
        })
        .then(async (response) => {
          const resp = await response.json();
          if (response.status == 200) {
            this.dialogLoading = false;
            this.$emit("atender", {
              idRequesicao: parseInt(requisicao.id),
              idAgendamento: resp.data.id,
              idProcedimento: parseInt(requisicao.id_procedimento),
              medicoId: this.infos.medicoId,
            });
            // await this.$router.replace({ name: "dashboard" });
          }
          if (response.status == 400) {
            this.$_ACTIONS_showSnackbarMessage({
              message: resp.message,
              color: "error",
            });
          }
        })
        .catch((e) => {
          this.dialogLoading = false;
          console.error(e);
        })
        .finally(() => {
          this.dialogLoading = false;
        });
    },
    async iniciarAtendimento() {
      // const id_requisicao = JSON.parse(localStorage.getItem("infoPaciente"))
      //   .RequisicaoId;
      if (!this.infos.petId) {
        alert(
          "Esse paciente não possui pet associado. Verificar no chamefacil se o cadastro está correto."
        );
        return;
      }
      if (!this.procedimento?.procedimento_id) {
        this.$_ACTIONS_showSnackbarMessage({
          message: "Escolha um procedimento.",
          color: "error",
        });
        return;
      }
      if (
        !this.procedimento.id &
        (this.procedimento.convenio_tipo != "PARTICULAR")
      ) {
        if (this.procedimento.procedimento_id == "872") {
          await this.requisicaoGerarInternacao(this.procedimento);
          await this.$router.replace({ name: "internacao" });
          this.$_ACTIONS_setPetAtendido(this.infos.petId);
          return;
        }
        await this.requisicaoConsultaPublica(this.procedimento);
      }
      const body = {
        petId: this.infos.petId,
        unidadeId: this.infos.unidadeId,
        medicoId: this.infos.medicoId,
        procedimentoId: this.procedimento.procedimento_id,
        tipoProcedimentoId: this.procedimento.tipo_procedimento_id,
        requisicaoId: this.procedimento.id,
      };
      await this.agendamentoService
        .agendarConsultaPrimeiroSlotLivre(body)
        .then(async (response) => {
          const resp = await response.json();
          if (response.status == 200) {
            this.$emit("atender", {
              idRequesicao: parseInt(this.procedimento.id),
              idAgendamento: resp.data,
              idProcedimento: parseInt(this.procedimento.procedimento_id),
              medicoId: this.infos.medicoId,
            });
            // await this.$router.replace({ name: "dashboard" });
          }
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {});
      // await this.$router.replace({ name: "dashboard" });
      this.$_ACTIONS_setPetAtendido(this.infos.petId);
    },
    async requisicaoConsultaPublica(procedimento) {
      await this.requisicaoExameService
        .requisitarConsulta(
          this.infos.tutorId,
          this.infos.petId,
          this.infos.unidadeId,
          // this.infoPaciente.TutorCodigo,
          // this.infoPaciente.PetCodigo,
          // ~~JSON.parse(sessionStorage.vuex).unidade.id,
          { id_convenio_procedimento: procedimento.convenio_procedimento_id }
        )
        .then(async (response) => {
          if (response.status != 200) {
            return;
          }
          return response.json();
        })
        .then((resp) => {
          this.procedimento.id = resp.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>
<style scoped>
.card-style {
  padding: 10px 15px 20px;
  background-color: white;
}
</style>
