var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('v-data-table',{staticClass:"elevation-1",attrs:{"footer-props":{ 'items-per-page-options': [5, 10, 15, 20] },"headers":_vm.headers,"items":_vm.usuariosPrefeitura,"options":_vm.options,"server-items-length":_vm.totalList,"loading":_vm.loading,"loading-text":'Carregando...'},on:{"update:sort-by":function($event){_vm.options.ordenacao = true},"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-dialog',{attrs:{"max-width":"500px","persistent":"","fullscreen":"","transition":"dialog-bottom-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"bg-verde mb-2",attrs:{"dark":"","rounded":""}},'v-btn',attrs,false),on),[_vm._v(" Novo Usuário ")])]}}]),model:{value:(_vm.dialogCadastro),callback:function ($$v) {_vm.dialogCadastro=$$v},expression:"dialogCadastro"}},[(_vm.dialogCadastro)?_c('v-card',[_c('v-toolbar',{staticClass:"bg-verde",attrs:{"dark":""}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.dialogCadastro = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_c('span',{staticClass:"headline"},[_vm._v("Novo Usuário")])]),_c('v-spacer'),_c('v-toolbar-items')],1),_c('v-card-text',[_c('NovoUsuarioPrefeitura',{on:{"finalizarCadastro":_vm.finalizarCadastro}})],1)],1):_vm._e()],1),_c('v-spacer'),_c('v-spacer'),_c('v-spacer')],1)]},proxy:true},{key:"item.ativo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.ativo == 1 ? "Ativo" : "Inativo")+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editarUsuarioPrefeitura(item)}}},[_vm._v(" mdi-pencil ")])]}}])}),_c('v-dialog',{attrs:{"fullscreen":"","scrollable":"","transition":"dialog-bottom-transition"},model:{value:(_vm.dialogEdicao),callback:function ($$v) {_vm.dialogEdicao=$$v},expression:"dialogEdicao"}},[(_vm.dialogEdicao)?_c('v-card',[_c('v-toolbar',{staticClass:"bg-verde",attrs:{"dark":""}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.dialogEdicao = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_c('span',{staticClass:"headline"},[_vm._v("Editar Usuario")])]),_c('v-spacer'),_c('v-toolbar-items')],1),_c('v-card-text',[_c('EdicaoUsuarioPrefeitura',{attrs:{"usuarioPrefeitura":_vm.usuarioPrefeitura},on:{"finalizarCadastro":_vm.finalizarCadastro}})],1)],1):_vm._e()],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-lg-3"},[_c('div',{staticClass:"row justify-content-between"},[_c('h3',{staticClass:"mt-0 mb-2 font-weight-bold text-left"},[_vm._v("Usuário Prefeitura")])])])}]

export { render, staticRenderFns }