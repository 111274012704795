<template>
  <v-card>
    <v-toolbar dark color="rgb(29, 175, 128)">
      <v-toolbar-title>Encerrar Consulta Televet</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon dark @click="fecharModal">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-container>
      <div class="p-4">
        <div class="row">
          <div class="col-md-12 text-right d-flex justify-lg-space-between">
            <v-select
              :items="listMotivo"
              v-model="idMotivo"
              label="Selecione o motivo do encerramento"
            ></v-select>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 text-right d-flex justify-lg-space-between">
            <button
              class="btn btn-cancelar font-weight-bold"
              @click="fecharModal"
            >
              Cancelar
            </button>
            <button
              class="btn btn-confirmar-encerramento font-weight-bold"
              @click="encerrarConsulta"
              :disabled="idMotivo == ''"
            >
              <span v-if="!loading"> Encerrar Cosulta</span>
              <v-progress-circular
                v-else
                indeterminate
                color="white"
              ></v-progress-circular>
            </button>
          </div>
        </div>
      </div>
    </v-container>
  </v-card>
</template>

<script>
import Mixin from "@/mixins/vuex_mixin.js";

export default {
  components: {},
  mixins: [Mixin],
  props: {
    consultaTelevet: Object,
  },
  data: () => ({
    idMotivo: "",
    listMotivo: [
      { value: 1, text: "Não Compareceu" },
      { value: 2, text: "Desistência" },
      { value: 3, text: "Tutor perdeu conexão" },
      { value: 4, text: "Instabilidade da conexão" },
    ],
  }),
  beforeMount() {},
  watch: {},
  methods: {
    fecharModal() {
      this.$emit("close");
    },
    encerrarConsulta() {
      console.log("Id Motivo:");
      console.table(this.idMotivo);
      console.log("objeto:");
      console.dir(this.consultaTelevet);
      this.$emit("response");
    },
  },
};
</script>

<style scoped>
.btn:disabled,
.btn:disabled:hover {
  background-color: #8c8c8c;
  border: #8c8c8c 1px solid;
  color: white;
}
.btn-confirmar-encerramento {
  background-color: rgb(29, 175, 128);
  border: white 1px solid;
}
.btn-confirmar-encerramento:hover {
  background-color: white;
  color: rgb(29, 175, 128);
  border: rgb(29, 175, 128) 1px solid;
}
.btn-cancelar {
  border: rgb(175, 29, 29) 1px solid;
  color: rgb(175, 29, 29);
}
.btn-cancelar:hover {
  background-color: rgb(175, 29, 29);
  border: white 1px solid;
  color: white;
}
</style>
