<template>
  <div>
    <v-card class="pb-2">
      <v-toolbar style="background-color: #790202; color: #fff">
        <v-toolbar-title>
          <span class="headline">Excluir Anamnese</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="m-0">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12">
              <h3 class="mt-0 mb-1">
                Tem certeza que deseja excluir a anamnese?
              </h3>
              <h5 class="mt-0" v-if="auditoria">
                Caso continue esse registro, terá todo seu processo excluído.
              </h5>

              <p class="text-left mt-2">
                <strong>Tutor: </strong>{{ compromissoExclusao.nomeTutor
                }}<br />
                <strong>Pet: </strong>{{ compromissoExclusao.nomePet }}<br />
                <strong>Procedimento: </strong
                >{{ compromissoExclusao.procedimento }}<br />
                <strong>Data e Hora: </strong>{{ getData() }}
              </p>
            </div>
          </div>
        </div>
      </v-card-text>
      <v-card-actions class="justify-content-between mx-3">
        <v-btn text tile elevation="2" color="blue" @click="$emit('close')">
          Cancelar
        </v-btn>
        <v-btn
          elevation="2"
          color="red"
          :dark="!loading"
          :loading="loading"
          :disabled="loading"
          @click="deletar()"
        >
          <strong>Deletar</strong>
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import Mixin from "@/mixins/vuex_mixin.js";
import vuex_snackbar_mixin from "@/mixins/vuex_snackbar_mixin.js";
import VuexUsuarioMixin from "@/mixins/vuex_usuario_mixin.js";
import DadosCadastraisMixin from "@/mixins/dados_cadastrais_mixin.js";
import AgendamentoService from "@/services/agendamento_service.js";
import AuditoriaService from "@/services/auditoria_service.js";

export default {
  mixins: [Mixin, VuexUsuarioMixin, DadosCadastraisMixin, vuex_snackbar_mixin],
  components: {},
  props: {
    compromissoExclusao: Object,
    auditoria: {
      type: Boolean,
    },
  },
  data: () => ({
    loading: false,
    agendamentoService: AgendamentoService.build(),
    auditoriaService: AuditoriaService.build(),
    perfilId: "",
  }),
  async mounted() {
    this.perfilId = await JSON.parse(sessionStorage.vuex).perfil.id;
  },
  watch: {},
  methods: {
    async deletar() {
      this.loading = true;
      if (this.auditoria == true) {
        await this.auditoriaService
          .deleteAnamnesebyAudit(
            parseInt(this.compromissoExclusao.id_requisicao)
          )
          .then(async (response) => {
            const resp = await response.json();
            if (response.status !== 200) {
              this.$_ACTIONS_showSnackbarMessage({
                message: resp.message,
                color: "error",
              });
              return;
            } else {
              this.$_ACTIONS_showSnackbarMessage({
                message: resp.message,
                color: "sucess",
              });
              this.$emit("atualizarAuditoria");
            }
            this.loading = false;
          })
          .catch((e) => {
            console.error(e);
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        await this.agendamentoService
          .deletarAnamnese(this.createModel())
          .then(async (response) => {
            const resp = await response.json();
            if (response.status !== 200) {
              this.$_ACTIONS_showSnackbarMessage({
                message: resp.message,
                color: "error",
              });
              return;
            } else {
              this.$_ACTIONS_showSnackbarMessage({
                message: resp.message,
                color: "sucess",
              });
              this.$emit("atualizar");
            }
            this.loading = false;
          })
          .catch((e) => {
            console.error(e);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    createModel() {
      let id_assistente = parseInt(this.compromissoExclusao.id_assistente);
      if (
        this.compromissoExclusao.id_tipo_procedimento == 2 ||
        this.compromissoExclusao.id_tipo_procedimento == 3
      ) {
        id_assistente = 0;
      }
      return {
        id_requisicao: parseInt(this.compromissoExclusao.id_requisicao),
        id_agendamento: parseInt(this.compromissoExclusao.id_agendamento),
        id_medico: parseInt(this.compromissoExclusao.id_medico),
        id_assistente: id_assistente,
      };
    },
    getData() {
      const arrDate = this.compromissoExclusao.data.split("-");
      const hora = this.compromissoExclusao.hora_inicio.split(":");
      return `${arrDate[2]}/${arrDate[1]}/${arrDate[0]} - ${hora[0]}:${hora[1]}`;
    },
  },
};
</script>

<style scoped></style>
